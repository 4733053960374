/* @flow */
import * as R from "ramda";

const initialState = {
  ui: {
    isFetchingProgram: false,
    isFetchingCourseSummary: false,
    isFetchingContent: false,
    isFetchingList: false,
    isFetchingDetails: false,
    isFetchingCoursesSubscriptions: false,
    isFetchingSaveReason: false,
    isRemakingPracticetest: false,
    isStarting: false,
    isDroppingOut: false,
    isRetaking: false,
    isRemaking: false,
    isStartingPracticetest: false,
    listTimestamp: null,
    detailsTimestamps: {},
    area: "",
    areaShowFinished: false,
    programShowFinished: false,
    courseShowFinished: false,
    toggleTeacherResearch: false
  },
  data: {
    list: {},
    courses: {},
    details: {},
    existRegularCourses: {}
  }
};

const getExerciseLens = (action, field) => {
  return R.lensPath(["data", "courses", action.course, "contents", action.content, field, action.exercise]);
};

const updateExerciseReview = (review, slug, items) =>
  R.map(R.when(R.propEq("slug", slug), R.assoc("review", review)), items);

function coursesReducer(state = initialState, action) {
  switch (action.type) {
    case "HOME/COURSE_AD_EVENT_RESPONSE_SUCCESS": {
      const rememberingLens = R.lensPath(["data", "list", action.data.slug, "remembering"]);
      return R.set(rememberingLens, true, state);
    }

    case "COURSES/REVIEW_RESOLUTION_EXERCISE_REQUEST": {
      const lens = R.lensPath(["data", "courses", action.course, "contents", action.content, "targets"]);

      const newExerciseTargets = updateExerciseReview(action.review, action.slug, R.view(lens, state));

      return R.set(lens, newExerciseTargets, state);
    }
    case "COURSES/TOGGLE_FINISHED_CONTENT": {
      const lens = R.lensPath(["ui", "showFinished"]);

      return R.set(lens, action.show, state);
    }
    case "COURSES/TOGGLE_SHOULD_ALERT_EXPIRED": {
      const lens = R.lensPath(["data", "courses", action.course, "shouldAlertExpired"]);
      return R.compose(R.set(lens, !state.data.courses[action.course].shouldAlertExpired))(state);
    }
    case "COURSES/SELECT_AREA": {
      const areaLens = R.lensPath(["ui", "area"]);
      return R.set(areaLens, action.area, state);
    }
    case "COURSES/PRACTICETEST_REMAKE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isRemakingPracticetest"]);
      return R.set(fetchLens, true, state);
    }

    case "COURSES/PRACTICETEST_REMAKE_RESPONSE_SUCCESS": {
      const lens = R.lensPath(["data", "courses", action.course, "contents", action.content, "target"]);
      const fetchLens = R.lensPath(["ui", "isRemakingPracticetest"]);
      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }

    case "COURSES/PRACTICETEST_FINISH_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFinishingPracticetest"]);
      return R.set(fetchLens, true, state);
    }
    case "COURSES/PRACTICETEST_START_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isStartingPracticetest"]);
      return R.set(fetchLens, true, state);
    }
    case "COURSES/PRACTICETEST_START_RESPONSE_SUCCESS": {
      const lens = R.lensPath(["data", "courses", action.course, "contents", action.content, "target"]);
      const fetchLens = R.lensPath(["ui", "isStartingPracticetest"]);
      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }
    case "COURSES/PRACTICETEST_FINISH_RESPONSE_SUCCESS": {
      let lens = R.lensPath(["data", "courses", action.course, "contents", action.content, "target"]);
      const fetchLens = R.lensPath(["ui", "isFinishingPracticetest"]);

      const actualState = state.data.courses[action.course].contents[action.content];
      let newState = action.data;

      if (action.data.finished) {
        lens = R.lensPath(["data", "courses", action.course, "contents", action.content]);
        newState = {
          ...actualState,
          target: {
            ...actualState.target,
            practicetest: action.data.practicetest
          }
        };
      }
      return R.compose(R.set(fetchLens, false), R.set(lens, newState))(state);
    }

    case "COURSES/FINISH_CONTENT_REQUEST": {
      const finishingContentLens = R.lensPath(["ui", "isFinishingContent"]);
      return R.set(finishingContentLens, true, state);
    }

    case "COURSES/FINISH_CONTENT_RESPONSE_SUCCESS": {
      const index = state.data.courses[action.course].programs[action.program].content.findIndex(
        c => c.contentId === action.content
      );

      const lens = R.lensPath(["data", "courses", action.course, "programs", action.program, "content", index, "done"]);
      const finishingContentLens = R.lensPath(["ui", "isFinishingContent"]);
      const finishedLens = R.lensPath(["ui", "finished"]);

      const programLens = R.lensPath([
        "data",
        "courses",
        action.course,
        "finished",
        "programs",
        action.data.program.programId,
        "done"
      ]);
      const contentLens = R.lensPath([
        "data",
        "courses",
        action.course,
        "finished",
        "content",
        action.data.content.contentId,
        "done"
      ]);
      const courseLens = R.lensPath(["data", "courses", action.course, "finishedAt"]);

      return R.compose(
        R.set(lens, true),
        R.set(finishingContentLens, false),
        R.set(finishedLens, action.data),
        R.set(contentLens, !!action.data.content.done),
        R.set(programLens, !!action.data.program.done),
        R.set(courseLens, action.data.course)
      )(state);
    }

    case "COURSES/RETRY_EXERCISE": {
      const answerLens = getExerciseLens(action, "answers");
      const statusLens = getExerciseLens(action, "statuses");

      return R.compose(R.set(answerLens, []), R.set(statusLens, undefined))(state);
    }
    case "COURSES/ANSWER_EXERCISE": {
      const answerLens = getExerciseLens(action, "answers");
      return R.set(answerLens, action.answer, state);
    }
    case "COURSES/VERIFY_EXERCISE_ANSWER": {
      const statusLens = getExerciseLens(action, "statuses");
      return R.set(statusLens, action.status, state);
    }
    case "COURSES/CONTENT_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingContent"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "COURSES/CONTENT_RESPONSE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingContent"]);
      const contentLens = R.lensPath(["data", "courses", action.course, "contents", action.content]);
      return R.compose(R.set(fetchLens, false), R.set(contentLens, action.data))(state);
    }
    case "COURSES/SELECT_CONTENT": {
      //const contentsLens = R.lensPath(["data", "courses", action.course, "contents"])
      const selectedContentLens = R.lensPath(["data", "courses", action.course, "selectedContent"]);

      return R.set(selectedContentLens, action.content, state);
    }
    case "COURSES/COURSE_START_REQUEST": {
      const isStartingLens = R.lensPath(["ui", "isStarting"]);
      return R.set(isStartingLens, true, state);
    }
    case "COURSES/COURSE_START_RESPONSE_SUCCESS": {
      const isStartingLens = R.lensPath(["ui", "isStarting"]);
      const startedAtListLens = R.lensPath(["data", "list", action.course, "startedAt"]);
      const rememberLens = R.lensPath(["data", "list", action.course, "remembering"]);
      const startedAtDetailsLens = R.lensPath(["data", "details", action.course, "startedAt"]);

      return R.compose(
        R.set(isStartingLens, false),
        R.set(startedAtDetailsLens, action.data.startedAt),
        R.set(startedAtListLens, action.data.startedAt),
        R.set(rememberLens, true)
      )(state);
    }
    case "COURSES/COURSE_RETAKE_REQUEST": {
      const retakingLens = R.lensPath(["ui", "isRetaking"]);
      return R.set(retakingLens, true, state);
    }
    case "COURSES/COURSE_RETAKE_RESPONSE_SUCCESS": {
      const retakingLens = R.lensPath(["ui", "isRetaking"]);
      const droppedOutLens = R.lensPath(["data", "details", action.data.course, "droppedOut"]);
      const droppedOutListLens = R.lensPath(["data", "list", action.data.course, "droppedOut"]);
      return R.compose(
        R.set(retakingLens, false),
        R.set(droppedOutLens, undefined),
        R.set(droppedOutListLens, undefined)
      )(state);
    }
    case "COURSES/COURSE_REMAKE_REQUEST": {
      const retakingLens = R.lensPath(["ui", "isRemaking"]);
      return R.compose(R.set(retakingLens, true))(state);
    }
    case "COURSES/COURSE_REMAKE_RESPONSE_SUCCESS": {
      const retakingLens = R.lensPath(["ui", "isRemaking"]);
      return R.compose(R.set(retakingLens, false))(state);
    }
    case "COURSES/COURSE_DROP_RESPONSE_SUCCESS": {
      const droppingLens = R.lensPath(["ui", "isDroppingOut"]);
      const droppedOutLens = R.lensPath(["data", "details", action.data.course, "droppedOut"]);
      const droppedOutListLens = R.lensPath(["data", "list", action.data.course, "droppedOut"]);
      return R.compose(
        R.set(droppingLens, false),
        R.set(droppedOutLens, action.data.droppedOut),
        R.set(droppedOutListLens, action.data.droppedOut)
      )(state);
    }
    case "COURSES/COURSE_DROP_REQUEST": {
      const droppingLens = R.lensPath(["ui", "isDroppingOut"]);
      return R.set(droppingLens, true, state);
    }
    case "COURSES/COURSE_DETAILS_REQUEST": {
      const fetchingLens = R.lensPath(["ui", "isFetchingDetails"]);
      return R.set(fetchingLens, true, state);
    }
    case "COURSES/COURSE_DETAILS_RESPONSE_SUCCESS": {
      const summaryLens = R.lensPath(["data", "details", action.course]);
      const fetchingLens = R.lensPath(["ui", "isFetchingDetails"]);
      const timestampLens = R.lensPath(["ui", "detailsTimestamps", action.course]);

      return R.compose(
        R.set(summaryLens, action.data),
        R.set(fetchingLens, false),
        R.set(timestampLens, Date.now())
      )(state);
    }
    case "COURSES/COURSE_DETAILS_RESPONSE_FAILURE": {
      const fetchingLens = R.lensPath(["ui", "isFetchingDetails"]);

      return R.set(fetchingLens, false, state);
    }

    case "COURSES/COURSE_SUBSCRIPTIONS_REQUEST": {
      const fetchingLens = R.lensPath(["ui", "isFetchingCoursesSubscriptions"]);
      return R.set(fetchingLens, true, state);
    }
    case "COURSES/COURSE_SUBSCRIPTIONS_RESPONSE_SUCCESS": {
      const summaryLens = R.lensPath(["data", "existRegularCourses"]);
      const fetchingLens = R.lensPath(["ui", "isFetchingCoursesSubscriptions"]);

      return R.compose(R.set(summaryLens, action.data), R.set(fetchingLens, false))(state);
    }
    case "COURSES/COURSE_SUBSCRIPTIONS_RESPONSE_FAILURE": {
      const fetchingLens = R.lensPath(["ui", "isFetchingCoursesSubscriptions"]);

      return R.set(fetchingLens, false, state);
    }

    case "COURSES/COURSE_SAVE_REASON_REQUEST": {
      const fetchingLens = R.lensPath(["ui", "isFetchingSaveReason"]);
      return R.set(fetchingLens, true, state);
    }
    case "COURSES/COURSE_SAVE_REASON_RESPONSE_SUCCESS": {
      const fetchingLens = R.lensPath(["ui", "isFetchingSaveReason"]);

      return R.compose(R.set(fetchingLens, false))(state);
    }
    case "COURSES/COURSE_SAVE_REASON_RESPONSE_FAILURE": {
      const fetchingLens = R.lensPath(["ui", "isFetchingCoursesSubscriptions"]);

      return R.set(fetchingLens, false, state);
    }

    case "COURSES/LIST_COURSES_REQUEST": {
      const fetchingLens = R.lensPath(["ui", "isFetchingList"]);
      return R.set(fetchingLens, true, state);
    }

    case "COURSES/LIST_COURSES_RESPONSE_SUCCESS": {
      const listLens = R.lensPath(["data", "list"]);
      const fetchingLens = R.lensPath(["ui", "isFetchingList"]);
      const timestampLens = R.lensPath(["ui", "listTimestamp"]);

      const listAsMap = action.data.reduce(
        (p, c) => ({
          ...p,
          [c.slug]: c
        }),
        {}
      );

      return R.compose(R.set(listLens, listAsMap), R.set(fetchingLens, false), R.set(timestampLens, Date.now()))(state);
    }

    case "COURSES/SELECT_PROGRAM": {
      const selectedProgram = R.lensPath(["data", "courses", action.course, "selectedProgram"]);

      return R.set(selectedProgram, action.program, state);
    }
    case "COURSES/FINISH_PROGRAM_RESPONSE_SUCCESS": {
      const programLens = R.lensPath([
        "data",
        "courses",
        action.course,
        "finished",
        "programs",
        action.data.program.programId,
        "done"
      ]);
      const courseFinishedLens = R.lensPath(["data", "courses", action.course, "finishedAt"]);

      return R.compose(
        R.set(programLens, !!action.data.program.done),
        R.set(courseFinishedLens, action.data.course)
      )(state);
    }
    case "COURSES/PROGRAM_REQUEST": {
      const fetchingLens = R.lensPath(["ui", "isFetchingProgram"]);

      return R.set(fetchingLens, true, state);
    }
    case "COURSES/PROGRAM_RESPONSE_SUCCESS": {
      const programLens = R.lensPath(["data", "courses", action.course, "programs", action.program, "content"]);
      const titleLens = R.lensPath(["data", "courses", action.course, "selectedProgramObject", "title"]);
      const fetchingLens = R.lensPath(["ui", "isFetchingProgram"]);

      return R.compose(
        R.set(programLens, action.data.content),
        R.set(titleLens, action.data.title),
        R.set(fetchingLens, false)
      )(state);
    }
    case "COURSES/SUMMARY_REQUEST": {
      const fetchingLens = R.lensPath(["ui", "isFetchingCourseSummary"]);

      return R.set(fetchingLens, true, state);
    }
    case "COURSES/SUMMARY_RESPONSE_SUCCESS": {
      const summaryLens = R.lensPath(["data", "courses", action.course]);
      const fetchingLens = R.lensPath(["ui", "isFetchingCourseSummary"]);

      return R.compose(R.set(summaryLens, action.data), R.set(fetchingLens, false))(state);
    }
    case "COURSES/SUMMARY_RESPONSE_FAILURE": {
      const fetchingLens = R.lensPath(["ui", "isFetchingCourseSummary"]);
      const summaryLens = R.lensPath(["data", "courses", action.course]);
      return R.compose(
        R.set(summaryLens, { title: action.error.course, ...action.error }),
        R.set(fetchingLens, false)
      )(state);
    }

    case "COURSES/LOAD_REQUEST": {
      const fetchingLens = R.lensPath(["ui", "isFetchingCourseSummary"]);

      return R.set(fetchingLens, true, state);
    }
    case "COURSES/LOAD_RESPONSE_SUCCESS": {
      const summaryLens = R.lensPath(["data", "courses", action.course]);
      const fetchingLens = R.lensPath(["ui", "isFetchingCourseSummary"]);

      const programLens = R.lensPath([
        "data",
        "courses",
        action.course,
        "programs",
        action.data.selectedProgram,
        "content"
      ]);
      const contentLens = R.lensPath(["data", "courses", action.course, "contents", action.data.selectedContent]);

      return R.compose(
        R.set(contentLens, action.data.selectedContentObject),
        R.set(programLens, action.data.selectedProgramObject.content),
        R.set(summaryLens, action.data),
        R.set(fetchingLens, false)
      )(state);
    }
    case "COURSES/LOAD_RESPONSE_FAILURE": {
      const fetchingLens = R.lensPath(["ui", "isFetchingCourseSummary"]);
      const summaryLens = R.lensPath(["data", "courses", action.course]);
      return R.compose(
        R.set(summaryLens, { title: action.error.course, ...action.error }),
        R.set(fetchingLens, false)
      )(state);
    }

    case "COURSES/EXERCISE_FAVORITE_CHECK": {
      const { course, content, exerciseId, favorite } = action.data;
      const lens = R.lensPath(["data", "courses", course, "contents", content, "targets"]);
      const res = R.map(R.when(R.propEq("id", exerciseId), R.assoc("favorite", favorite)), R.view(lens, state));

      return R.set(lens, res, state);
    }
    case "COURSES/TOGGLE_TEACHER_RESEARCH": {
      const fetchLens = R.lensPath(["ui", "toggleTeacherResearch"]);
      return R.set(fetchLens, action.data, state);
    }
    case "APP/CLEAN_STATE": {
      return initialState;
    }

    default:
      return state;
  }
}

export default coursesReducer;
