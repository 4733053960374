import * as R from "ramda";

const initialState = {
  ui: {
    selectedFilter: "all",
    isToggleModal: false,
    isFetchingSuggest: false,
    isFetchingInitial: false,
    isFetchingQuestion: false,
    isFetchingSubareas: false,
    isFetchingSubjects: false,
    isFetchingQuestions: false,
    isToggleModalAnswer: false,
    isFetchingLikeAnswer: false,
    isFetchingSendAnswer: false,
    isFetchingEditAnswer: false,
    isFetchingHighlights: false,
    isToggleModalNotUser: false,
    isFetchingUserMetrics: false,
    isFetchingAnswerReport: false,
    isFetchingLikeQuestion: false,
    isFetchingDislikeQuestion: false,
    isFetchingDislikeAnswer: false,
    isFetchingEditQuestion: false,
    isFetchingQuestionReport: false,
    isFetchingDeleteQuestion: false,
    isFetchingCreateQuestion: false,
    isFetchingSubareasFilter: false,
    isToggleModalEditQuestion: false,
    isToggleModalReportAnswer: false,
    isFetchingPreviewNextPage: false,
    isFetchingPreviewQuestions: false,
    isToggleModalReportQuestion: false,
    isToggleModalCreateQuestion: false
  },
  data: {
    initial: {},
    subareas: [],
    subareasFilter: [],
    subjects: [],
    questions: {
      meta: {},
      data: []
    },
    previewQuestions: {
      meta: {},
      data: []
    },
    question: {},
    answer: {},
    report: {},
    suggest: [],
    metrics: {
      questions: {
        total: 0,
        byArea: {}
      },
      answers: {
        total: 0,
        approved: 0,
        byArea: {}
      }
    },
    highlights: {
      questions: {
        total: {
          user: {
            name: "",
            picture: ""
          },
          count: 0
        },
        byArea: {}
      },
      answers: {
        total: {
          user: {
            name: "",
            picture: ""
          },
          count: 0
        },
        approved: {
          user: {
            name: "",
            picture: ""
          },
          count: 0
        },
        byArea: {}
      }
    }
  }
};

function questionsReducer(state = initialState, action) {
  switch (action.type) {
    case "QUESTIONS/APPROVE_ANSWER_SUCCESS": {
      const index = state.data.question.answers.findIndex(it => it.id === action.answer);
      const lens = R.lensPath(["data", "question", "answers", index, "approved"]);

      return R.compose(R.set(lens, true))(state);
    }
    case "QUESTIONS/DISAPPROVE_ANSWER_SUCCESS": {
      const index = state.data.question.answers.findIndex(it => it.id === action.answer);
      const lens = R.lensPath(["data", "question", "answers", index, "approved"]);

      return R.compose(R.set(lens, false))(state);
    }
    case "QUESTIONS/USER_COMMUNITY_METRICS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingUserMetrics"]);

      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/USER_COMMUNITY_METRICS_REQUEST_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingUserMetrics"]);
      const questionslens = R.lensPath(["data", "metrics", "questions"]);
      const answersLens = R.lensPath(["data", "metrics", "answers"]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(questionslens, action.data.questions),
        R.set(answersLens, action.data.answers)
      )(state);
    }
    case "QUESTIONS/USER_COMMUNITY_METRICS_REQUEST_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingUserMetrics"]);

      return R.set(fetchLens, false, state);
    }
    case "QUESTIONS/HIGHLIGHTS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingHighlights"]);

      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/HIGHLIGHTS_REQUEST_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingHighlights"]);
      const questionslens = R.lensPath(["data", "highlights", "questions"]);
      const answersLens = R.lensPath(["data", "highlights", "answers"]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(questionslens, action.data.questions),
        R.set(answersLens, action.data.answers)
      )(state);
    }

    case "QUESTIONS/UPDATE_FILTER": {
      const filterLens = R.lensPath(["ui", "selectedFilter"]);

      return R.set(filterLens, action.filter, state);
    }

    case "QUESTIONS/TOGGLE_MODAL_QUESTION": {
      const filterLens = R.lensPath(["ui", "isToggleModal"]);

      return R.set(filterLens, action.modal, state);
    }
    case "QUESTIONS/TOGGLE_MODAL_NOT_USER": {
      const filterLens = R.lensPath(["ui", "isToggleModalNotUser"]);

      return R.set(filterLens, action.modal, state);
    }
    case "QUESTIONS/TOGGLE_MODAL_EDIT_QUESTION": {
      const filterLens = R.lensPath(["ui", "isToggleModalEditQuestion"]);

      return R.set(filterLens, action.modal, state);
    }
    case "QUESTIONS/TOGGLE_MODAL_REPORT_QUESTION": {
      const filterLens = R.lensPath(["ui", "isToggleModalReportQuestion"]);

      return R.set(filterLens, action.modal, state);
    }
    case "QUESTIONS/TOGGLE_MODAL_REPORT_ANSWER": {
      const filterLens = R.lensPath(["ui", "isToggleModalReportAnswer"]);

      return R.set(filterLens, action.modal, state);
    }
    case "QUESTIONS/TOGGLE_MODAL_CREATE_QUESTION": {
      const filterLens = R.lensPath(["ui", "isToggleModalCreateQuestion"]);
      const lens = R.lensPath(["data", "suggest"]);

      return R.compose(R.set(filterLens, action.modal), R.set(lens, []))(state);
    }

    case "QUESTIONS/TOGGLE_MODAL_ANSWER": {
      const filterLens = R.lensPath(["ui", "isToggleModalAnswer"]);

      return R.set(filterLens, action.modal, state);
    }

    case "QUESTIONS/QUESTIONS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestions"]);

      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/QUESTIONS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestions"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "QUESTIONS/QUESTIONS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestions"]);
      const lens = R.lensPath(["data", "questions"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }

    case "QUESTIONS/QUESTIONS_NEXT_PAGE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestions"]);

      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/QUESTIONS_NEXT_PAGE_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestions"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "QUESTIONS/QUESTIONS_NEXT_PAGE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestions"]);
      const lens = R.lensPath(["data", "questions"]);

      const data = {
        meta: action.data.meta,
        data: [...state.data.questions.data, ...action.data.data]
      };

      return R.compose(R.set(fetchLens, false), R.set(lens, data))(state);
    }

    case "QUESTIONS/QUESTION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestion"]);

      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/QUESTION_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestion"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "QUESTIONS/QUESTION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestion"]);
      const lens = R.lensPath(["data", "question"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }

    case "QUESTIONS/ANSWER_SEND_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSendAnswer"]);

      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/ANSWER_SEND_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingSendAnswer"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "QUESTIONS/ANSWER_SEND_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSendAnswer"]);
      const lens = R.lensPath(["data", "question"]);
      const lensQuestions = R.lensPath(["data", "questions", "data"]);
      let dataQuestion;

      // Condicional para dataQuestion receber action.data quando a
      // resposta for criada na ModalCommunity nos exercicios
      if (Object.keys(state.data.question).length > 0) {
        dataQuestion = state.data.question;
        dataQuestion.answers = [...dataQuestion.answers, action.data];
      } else {
        dataQuestion = action.data;
      }

      const questions = state.data.questions.data.map(it => {
        if (Number(it.id) === Number(dataQuestion.id)) {
          return {
            ...it,
            answersCount: dataQuestion.answers.length
          };
        } else {
          return it;
        }
      });

      return R.compose(R.set(fetchLens, false), R.set(lens, dataQuestion), R.set(lensQuestions, questions))(state);
    }

    case "QUESTIONS/LIKE_QUESTION": {
      const fetchLens = R.lensPath(["ui", "isFetchingLikeQuestion"]);

      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/LIKE_QUESTION_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingLikeQuestion"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "QUESTIONS/LIKE_QUESTION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingLikeQuestion"]);
      const lensQuestions = R.lensPath(["data", "questions"]);
      const lensQuestion = R.lensPath(["data", "question"]);

      const d = state.data.questions.data.map(it => {
        if (it.id === Number(action.data.questionId)) {
          it.votesCount = action.data.votesCount;
          it.hasBeenLiked = action.data.hasBeenLiked;
          it.hasBeenDisliked = action.data.hasBeenDisliked;
        }
        return it;
      });

      const data = {
        meta: state.data.questions.meta,
        data: d
      };

      let dataQuestion = state.data.question;

      if (dataQuestion.id === Number(action.data.questionId)) {
        dataQuestion.votesCount = action.data.votesCount;
        dataQuestion.hasBeenLiked = action.data.hasBeenLiked;
        dataQuestion.hasBeenDisliked = action.data.hasBeenDisliked;
      }

      return R.compose(R.set(fetchLens, false), R.set(lensQuestions, data), R.set(lensQuestion, dataQuestion))(state);
    }
    case "QUESTIONS/DISLIKE_QUESTION": {
      const fetchLens = R.lensPath(["ui", "isFetchingDislikeQuestion"]);

      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/DISLIKE_QUESTION_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingDislikeQuestion"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "QUESTIONS/DISLIKE_QUESTION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingDislikeQuestion"]);
      const lensQuestions = R.lensPath(["data", "questions"]);
      const lensQuestion = R.lensPath(["data", "question"]);

      const d = state.data.questions.data.map(it => {
        if (it.id === Number(action.data.questionId)) {
          it.dislikesCount = action.data.dislikesCount;
          it.hasBeenDisliked = action.data.hasBeenDisliked;
          it.hasBeenLiked = action.data.hasBeenLiked;
        }
        return it;
      });

      const data = {
        meta: state.data.questions.meta,
        data: d
      };

      let dataQuestion = state.data.question;

      if (dataQuestion.id === Number(action.data.questionId)) {
        dataQuestion.dislikesCount = action.data.dislikesCount;
        dataQuestion.hasBeenDisliked = action.data.hasBeenDisliked;
        dataQuestion.hasBeenLiked = action.data.hasBeenLiked;
      }

      return R.compose(R.set(fetchLens, false), R.set(lensQuestions, data), R.set(lensQuestion, dataQuestion))(state);
    }

    case "QUESTIONS/LIKE_ANSWER_QUESTION": {
      const fetchLens = R.lensPath(["ui", "isFetchingLikeAnswer"]);

      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/LIKE_ANSWER_QUESTION_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingLikeAnswer"]);

      return R.compose(R.set(fetchLens, true))(state);
    }

    case "QUESTIONS/LIKE_ANSWER_QUESTION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingLikeAnswer"]);
      const lens = R.lensPath(["data", "question"]);

      const answers = state.data.question.answers.map(it => {
        if (it.id === action.data.answerId) {
          it.votesCount = action.data.votesCount;
          it.hasBeenLiked = action.data.hasBeenLiked;
          it.hasBeenDisliked = action.data.hasBeenDisliked;
        }
        return it;
      });

      let data = state.data.question;
      data.answers = answers;

      return R.compose(R.set(fetchLens, false), R.set(lens, data))(state);
    }

    case "QUESTIONS/DISLIKE_ANSWER_QUESTION": {
      const fetchLens = R.lensPath(["ui", "isFetchingDislikeAnswer"]);

      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/DISLIKE_ANSWER_QUESTION_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingDislikeAnswer"]);

      return R.compose(R.set(fetchLens, true))(state);
    }

    case "QUESTIONS/DISLIKE_ANSWER_QUESTION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingDislikeAnswer"]);
      const lens = R.lensPath(["data", "question"]);

      const answers = state.data.question.answers.map(it => {
        if (it.id === action.data.answerId) {
          it.votesCount = action.data.votesCount;
          it.hasBeenLiked = action.data.hasBeenLiked;
          it.hasBeenDisliked = action.data.hasBeenDisliked;
        }
        return it;
      });

      let data = state.data.question;
      data.answers = answers;

      return R.compose(R.set(fetchLens, false), R.set(lens, data))(state);
    }

    case "QUESTIONS/DELETE_QUESTION": {
      const fetchLens = R.lensPath(["ui", "isFetchingDeleteQuestion"]);

      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/DELETE_QUESTION_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingDeleteQuestion"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "QUESTIONS/DELETE_QUESTION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingDeleteQuestion"]);
      const lens = R.lensPath(["data", "question"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, {}))(state);
    }

    case "QUESTIONS/DELETE_ANSWER": {
      const fetchLens = R.lensPath(["ui", "isFetchingDeleteAnswer"]);
      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/DELETE_ANSWER_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingDeleteAnswer"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "QUESTIONS/DELETE_ANSWER_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingDeleteAnswer"]);
      const lens = R.lensPath(["data", "question"]);
      const lensQuestions = R.lensPath(["data", "questions", "data"]);

      let question = state.data.question;
      question.answers = question.answers.filter(it => it.id !== action.data.answerId);

      const questions = state.data.questions.data.map(it => {
        if (Number(it.id) === Number(question.id)) {
          return {
            ...it,
            answersCount: question.answers.length
          };
        } else {
          return it;
        }
      });

      return R.compose(R.set(fetchLens, false), R.set(lens, question), R.set(lensQuestions, questions))(state);
    }

    case "QUESTIONS/CREATE_QUESTION": {
      const fetchLens = R.lensPath(["ui", "isFetchingCreateQuestion"]);
      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/CREATE_QUESTION_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingCreateQuestion"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "QUESTIONS/CREATE_QUESTION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingCreateQuestion"]);
      const lens = R.lensPath(["data", "questions"]);
      const suggest = R.lensPath(["data", "suggest"]);

      const data = {
        meta: state.data.questions.meta,
        data: [action.data, ...state.data.questions.data]
      };

      return R.compose(R.set(fetchLens, false), R.set(lens, data), R.set(suggest, []))(state);
    }

    case "QUESTIONS/INITIAL": {
      const fetchLens = R.lensPath(["ui", "isFetchingInitial"]);
      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/INITIAL_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingInitial"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "QUESTIONS/INITIAL_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingInitial"]);
      const lens = R.lensPath(["data", "initial"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }

    case "QUESTIONS/ANSWER_REPORT": {
      const fetchLens = R.lensPath(["ui", "isFetchingAnswerReport"]);
      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/ANSWER_REPORT_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingAnswerReport"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "QUESTIONS/ANSWER_REPORT_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingAnswerReport"]);
      const lens = R.lensPath(["data", "report"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }

    case "QUESTIONS/QUESTION_REPORT": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestionReport"]);
      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/QUESTION_REPORT_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestionReport"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "QUESTIONS/QUESTION_REPORT_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestionReport"]);
      const lens = R.lensPath(["data", "report"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }

    case "QUESTIONS/QUESTION_EDIT": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestionEdit"]);
      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/QUESTION_EDIT_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestionEdit"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "QUESTIONS/QUESTION_EDIT_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestionEdit"]);
      const lens = R.lensPath(["data", "question"]);
      const lensQuestions = R.lensPath(["data", "questions"]);

      let question = state.data.question;
      question.title = action.data.title;
      question.text = action.data.text;
      question.area = action.data.area;
      question.subarea = action.data.subarea;
      question.subjects = action.data.subjects;
      question.attachments = action.data.attachments;

      const questions = {
        meta: state.data.questions.meta,
        data: state.data.questions.data.map(it => {
          if (Number(it.id) === Number(action.data.questionId)) {
            return {
              ...it,
              title: action.data.title,
              text: action.data.text,
              area: action.data.area,
              subarea: action.data.subarea,
              subjects: action.data.subjects,
              attachments: action.data.attachments
            };
          } else {
            return it;
          }
        })
      };

      return R.compose(R.set(fetchLens, false), R.set(lens, question), R.set(lensQuestions, questions))(state);
    }

    case "QUESTIONS/ANSWER_EDIT": {
      const fetchLens = R.lensPath(["ui", "isFetchingAnswerEdit"]);
      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/ANSWER_EDIT_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingAnswerEdit"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "QUESTIONS/ANSWER_EDIT_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingAnswerEdit"]);
      const lens = R.lensPath(["data", "question"]);

      const answers = state.data.question.answers.map(it => {
        if (it.id === action.data.answerId) {
          it.text = action.data.text;
          it.attachments = action.data.attachments;
        }
        return it;
      });

      let data = state.data.question;
      data.answers = answers;

      return R.compose(R.set(fetchLens, false), R.set(lens, data))(state);
    }

    case "QUESTIONS/SUGGEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSuggest"]);
      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/SUGGEST_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSuggest"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "QUESTIONS/SUGGEST_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSuggest"]);
      const lens = R.lensPath(["data", "suggest"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }

    case "QUESTIONS/SUBAREAS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubareas"]);
      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/SUBAREAS_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubareas"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "QUESTIONS/SUBAREAS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubareas"]);
      const lens = R.lensPath(["data", "subareas"]);

      let subareas = [];

      if (action.data && action.data[0]) {
        subareas = action.data.map(it => {
          return {
            name: it.title,
            value: it.slug
          };
        });
      }

      return R.compose(R.set(fetchLens, false), R.set(lens, subareas))(state);
    }

    case "QUESTIONS/SUBAREAS_FILTER": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubareasFilter"]);
      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/SUBAREAS_FILTER_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubareasFilter"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "QUESTIONS/SUBAREAS_FILTER_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubareasFilter"]);
      const lens = R.lensPath(["data", "subareasFilter"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }

    case "QUESTIONS/SUBJECTS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubjects"]);
      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/SUBJECTS_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubjects"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "QUESTIONS/SUBJECTS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubjects"]);
      const lens = R.lensPath(["data", "subjects"]);

      let subjects = [];

      if (action.data && action.data[0]) {
        subjects = action.data.map(it => {
          return {
            label: it.title,
            value: it.id,
            slug: it.slug
          };
        });
      }

      return R.compose(R.set(fetchLens, false), R.set(lens, subjects))(state);
    }
    case "QUESTIONS/DIVERSAS_QUESTIONS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingPreviewQuestions"]);

      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/DIVERSAS_QUESTIONS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingPreviewQuestions"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "QUESTIONS/DIVERSAS_QUESTIONS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingPreviewQuestions"]);
      const lens = R.lensPath(["data", "previewQuestions"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }
    case "QUESTIONS/PREVIEW_QUESTIONS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingPreviewQuestions"]);

      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/PREVIEW_QUESTIONS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingPreviewQuestions"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "QUESTIONS/PREVIEW_QUESTIONS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingPreviewQuestions"]);
      const lens = R.lensPath(["data", "previewQuestions"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }
    case "QUESTIONS/PREVIEW_QUESTIONS_NEXT_PAGE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingPreviewNextPage"]);

      return R.set(fetchLens, true, state);
    }
    case "QUESTIONS/PREVIEW_QUESTIONS_NEXT_PAGE_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingPreviewNextPage"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "QUESTIONS/PREVIEW_QUESTIONS_NEXT_PAGE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingPreviewNextPage"]);
      const lens = R.lensPath(["data", "previewQuestions"]);

      const data = {
        meta: action.data.meta,
        data: [...state.data.previewQuestions.data, ...action.data.data]
      };

      return R.compose(R.set(fetchLens, false), R.set(lens, data))(state);
    }

    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}

export default questionsReducer;
