import account from "../scenes/account/state/reducer";
import { areasReducer } from "../scenes/areas/state/reducer";
import exercises from "../scenes/exercises/state/reducer";
import materials from "../scenes/materials/state/reducer";
import practicetests from "../scenes/practicetests/state/reducer";
import simulationWeek from "../scenes/simulationWeek/state/reducer";
import revision from "../scenes/revision/state/reducer";
import lessons from "../scenes/lessons/state/reducer";
import app from "../scenes/app/state/reducer";
import profile from "../scenes/profile/state/reducers";
import home from "../scenes/home/state/reducer";
import subareas from "../scenes/subareas/state/reducer";
import metrics from "../scenes/metrics/state/reducer";
import podcasts from "../scenes/podcasts/state/reducer";
import maps from "../scenes/maps/state/reducer";
import courses from "../scenes/courses/state/reducer";
import milzao from "../scenes/courses/stateMilzao/reducer";
import lives from "../scenes/live/state/reducer";
import search from "../scenes/search/state/reducer";
import onboarding from "../scenes/onboarding/state/reducer";
import questions from "../scenes/questions/state/reducer";
import uploadReducer from "../scenes/components/upload/state/reducer";
import { simulationReducer as simulation } from "../scenes/enemSimulation/state/reducer";
import { commentsReducer as comments } from "../scenes/components/comments/state/reducer";
import { commentsSquadReducer as commentsSquad } from "../scenes/squad/components/comments/state/reducer";
import { commentsMilzaoReducer as commentsMilzao } from "../scenes/courses/components/commentsMilzao/state/reducer";
import sharedData from "../scenes/sharedData/state/reducer";
import favorites from "../scenes/favorites/state/reducer";
import essays from "../scenes/essays/state/reducer";
import feed from "../scenes/feed/state/reducer";
import squad from "../scenes/squad/state/reducer";
import myArea from "../scenes/myArea/state/reducer";
import regionals from "../scenes/regionals/state/reducer";
import relatedQuestions from "../scenes/modalCommunity/state/reducer";
import simulator from "../scenes/simulator/state/reducer";
import monitorias from "../scenes/monitoring/state/reducer";
import calendar from "../scenes/calendar/state/reducer";
import npsSurvey from "../scenes/npsSurvey/state/reducer";
import { toursReducer as tours } from "../scenes/tour/state/reducer";
import aulasAoVivo from '../scenes/aulasaovivo/state/reducer';

export default {
  account,
  app,
  areasReducer,
  calendar,
  comments,
  commentsMilzao,
  commentsSquad,
  courses,
  essays,
  exercises,
  favorites,
  feed,
  home,
  lessons,
  lives,
  maps,
  materials,
  metrics,
  milzao,
  monitorias,
  myArea,
  npsSurvey,
  onboarding,
  podcasts,
  practicetests,
  profile,
  questions,
  regionals,
  relatedQuestions,
  revision,
  search,
  sharedData,
  simulation,
  simulationWeek,
  simulator,
  squad,
  subareas,
  uploadReducer,
  tours,
  aulasAoVivo
};
