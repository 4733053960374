import React from "react";
import { hotjar } from "../../../core/hotjar";

export function withTracker(WrappedComponent, options = {}) {
  const trackPage = () => {
    window.gtag("event", "page_view", options);

    // hjid: Stands for 'Hotjar ID' - Your site's ID.
    // hjsv: Stands for 'Hotjar Snippet Version' - The version of the Tracking Code you are using.
    hotjar(1035664, 6);
  };

  // BT: 2835397
  // AT: 1035664

  const HOC = class extends React.Component {
    componentDidMount() {
      trackPage();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const {
        location: { pathname: currentPage }
      } = this.props;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        trackPage();
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
}
