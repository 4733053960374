/* @flow */
import * as R from "ramda";
import type { Action } from "../../../types";

type State = {
  ui: {
    isFetchingSearch: boolean,
    isFetchinglessons: boolean,
    isFetchingworkbooks: boolean,
    isFetchingexercises: boolean,
    isFetchingpracticetests: boolean,
    isFetchingmindmaps: boolean,
    isFetchingpodcasts: boolean,
    isFetchinglives: boolean,
    selectedFilter: string
  },
  data: {
    count: Array<Object>,
    lessons: Array<Object>,
    workbooks: Array<Object>,
    exercises: Array<Object>,
    practicetests: Array<Object>,
    mindmaps: Array<Object>,
    podcasts: Array<Object>,
    lives: Array<Object>
  }
};

const initialState = {
  ui: {
    isFetchingSearch: false,
    isFetchinglessons: false,
    isFetchingworkbooks: false,
    isFetchingexercises: false,
    isFetchingpracticetests: false,
    isFetchingmindmaps: false,
    isFetchingpodcasts: false,
    isFetchinglives: false,
    selectedFilter: "all"
  },
  data: {
    count: [],
    lessons: [],
    workbooks: [],
    exercises: [],
    practicetests: [],
    mindmaps: [],
    podcasts: [],
    lives: []
  }
};

function searchReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case "SEARCH/UPDATE_FILTER": {
      const filterLens = R.lensPath(["ui", "selectedFilter"]);

      return R.set(filterLens, action.filter, state);
    }

    case "SEARCH/SEARCH_REQUEST": {
      const fetchLens = R.lensPath(["ui", `isFetching${action.module}`]);

      return R.set(fetchLens, true, state);
    }
    case "SEARCH/SEARCH_ERROR": {
      const fetchLens = R.lensPath(["ui", `isFetching${action.module}`]);

      return R.compose(R.set(fetchLens, true))(state);
    }

    case "SEARCH/SEARCH_SUCCESS": {
      const fetchLens = R.lensPath(["ui", `isFetching${action.module}`]);
      const searchLens = R.lensPath(["data", action.module]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(searchLens, action.data)
      )(state);
    }

    case "SEARCH/SEARCH_COUNT_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSearch"]);

      return R.set(fetchLens, true, state);
    }
    case "SEARCH/SEARCH_COUNT_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingSearch"]);

      return R.compose(R.set(fetchLens, true))(state);
    }

    case "SEARCH/SEARCH_COUNT_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSearch"]);
      const searchLens = R.lensPath(["data", "count"]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(searchLens, action.data)
      )(state);
    }
    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}

export default searchReducer;
