/**
 * Returns an area object
 *
 * @param {string} slug slug of an area.
 * @return {object} Returns area object.
 */

export const GetArea = slug => {
  const areas = [
    {
      slug: "biologia",
      label: "Biologia",
      ing: "biology",
      icon: "biology",
      order: 0
    },
    {
      slug: "fisica",
      label: "Física",
      ing: "physics",
      icon: "physical",
      order: 1
    },
    {
      slug: "quimica",
      label: "Química",
      ing: "chemistry",
      icon: "chemestry",
      order: 2
    },
    {
      slug: "matematica",
      label: "Matemática",
      ing: "mathematics",
      icon: "mathematics",
      order: 3
    },
    {
      slug: "historia",
      label: "História",
      ing: "history",
      icon: "history",
      order: 4
    },
    {
      slug: "geografia",
      label: "Geografia",
      ing: "geography",
      icon: "geography",
      order: 5
    },
    {
      slug: "filosofia",
      label: "Filosofia",
      ing: "philosophy",
      icon: "philosophy",
      order: 6
    },
    {
      slug: "sociologia",
      label: "Sociologia",
      ing: "sociology",
      icon: "sociology",
      order: 7
    },
    {
      slug: "portugues",
      label: "Português",
      ing: "portuguese",
      icon: "portuguese",
      order: 8
    },
    {
      slug: "literatura",
      label: "Literatura",
      ing: "literature",
      icon: "literature",
      order: 9
    },
    {
      slug: "redacao",
      label: "Redação",
      ing: "essay",
      icon: "essay",
      order: 10
    },
    {
      slug: "ingles",
      label: "Inglês",
      ing: "english",
      icon: "english",
      order: 11
    },
    {
      slug: "espanhol",
      label: "Espanhol",
      ing: "spanish",
      icon: "spanish",
      order: 12
    },
    {
      slug: "interdisciplinar",
      label: "Interdisciplinar",
      ing: "interdisciplinary",
      icon: "interdisciplinary",
      order: 13
    },
    {
      slug: "diversas",
      label: "Diversas",
      ing: "primary",
      icon: "diversas",
      order: 14
    }
  ];
  return areas.find(area => slug === area.slug) || {};
};
