import { GetBaseArea } from "../../../utils";

const AREAS = GetBaseArea();

function GetExercisesByArea(exercises = [], area) {
  return (exercises || []).filter(it => area === String(it && it.ancestors.area && it.ancestors.area.id));
}

function GetAreasByFilterFooter(exercises = []) {
  const allAreasIds = [...new Set(exercises.map(it => it && it.ancestors.area && it.ancestors.area.id))];

  const filter = [];

  allAreasIds.forEach(it => {
    let area = AREAS.find(area => area.id === it);
    if (area)
      filter.push({
        id: area.id,
        label: area.label,
        firstQuestion: getLastExercise(exercises, area.id)
      });
  });
  return filter;
}

function getLastExercise(exercises, areaId) {
  if (!exercises || !areaId) return null;
  const exercise = exercises.find(it => (it && it.ancestors.area && it.ancestors.area.id) === areaId) || {};
  return exercise.id || exercise._id;
}

function VerifyIsAnsweredQuestion(answers = [], exerciseId) {
  return (answers || []).filter(it => String(exerciseId) === String(it.question)).length > 0;
}

function SortExercises(exercises) {
  exercises.sort((a, b) => {
    const aOrder = (
      AREAS.find(function(area) {
        return area.id === a.ancestors.area.id;
      }) || { order: -1 }
    ).order;
    const bOrder = (
      AREAS.find(function(area) {
        return area.id === b.ancestors.area.id;
      }) || { order: -1 }
    ).order;
    return aOrder - bOrder;
  });
  return exercises;
}

function SortResolutions(resolutions) {
  resolutions.sort((a, b) => {
    const aOrder = (
      AREAS.find(function(area) {
        return area.id === a.area.id;
      }) || { order: -1 }
    ).order;
    const bOrder = (
      AREAS.find(function(area) {
        return area.id === b.area.id;
      }) || { order: -1 }
    ).order;
    return aOrder - bOrder;
  });
  return resolutions;
}

export { GetExercisesByArea, GetAreasByFilterFooter, VerifyIsAnsweredQuestion, SortExercises, SortResolutions };
