import * as R from "ramda";
import { getModuleObject } from "./selectors";

const initialState = {
  ui: {
    isFetchingModule: false,
    isFetchingModuleSummary: false,
    isFetchingSelectedContent: false
  },
  data: {
    modules: [],
    module: {},
    selectedContent: {}
  }
};

function squadReducer(state = initialState, action) {
  switch (action.type) {
    case "SQUAD/MODULES_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingModules"]);

      return R.set(fetchLens, true, state);
    }
    case "SQUAD/MODULES_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingModules"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SQUAD/MODULES_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingModules"]);
      const lens = R.lensPath(["data", "modules"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }
    case "SQUAD/LOAD_REQUEST": {
      const fetchingLens = R.lensPath(["ui", "isFetchingModuleSummary"]);

      return R.set(fetchingLens, true, state);
    }
    case "SQUAD/LOAD_RESPONSE_FAILURE": {
      const fetchingLens = R.lensPath(["ui", "isFetchingModuleSummary"]);
      const summaryLens = R.lensPath(["data", "module", action.module]);
      return R.compose(
        R.set(summaryLens, { title: action.error.module, ...action.error }),
        R.set(fetchingLens, false)
      )(state);
    }
    case "SQUAD/LOAD_LESSON_REQUEST": {
      const fetchingLens = R.lensPath(["ui", "isFetchingSelectedContent"]);

      return R.set(fetchingLens, true, state);
    }
    case "SQUAD/LOAD_LESSON_RESPONSE_FAILURE": {
      const fetchingLens = R.lensPath(["ui", "isFetchingSelectedContent"]);
      const dataLens = R.lensPath(["data", "selectedContent"]);
      return R.compose(R.set(dataLens, {}), R.set(fetchingLens, false))(state);
    }
    case "SQUAD/LOAD_LESSON_RESPONSE_SUCCESS": {
      const summaryLens = R.lensPath(["data", "selectedContent"]);
      const fetchingLens = R.lensPath(["ui", "isFetchingSelectedContent"]);
      return R.compose(R.set(summaryLens, action.data), R.set(fetchingLens, false))(state);
    }
    case "SQUAD/LOAD_MATERIAL_REQUEST": {
      const fetchingLens = R.lensPath(["ui", "isFetchingSelectedContent"]);

      return R.set(fetchingLens, true, state);
    }
    case "SQUAD/LOAD_MATERIAL_RESPONSE_FAILURE": {
      const fetchingLens = R.lensPath(["ui", "isFetchingSelectedContent"]);
      const dataLens = R.lensPath(["data", "selectedContent"]);
      return R.compose(R.set(dataLens, {}), R.set(fetchingLens, false))(state);
    }
    case "SQUAD/LOAD_MATERIAL_RESPONSE_SUCCESS": {
      const summaryLens = R.lensPath(["data", "selectedContent"]);
      const fetchingLens = R.lensPath(["ui", "isFetchingSelectedContent"]);
      return R.compose(R.set(summaryLens, action.data), R.set(fetchingLens, false))(state);
    }

    case "SQUAD/LOAD_RESPONSE_SUCCESS": {
      const summaryLens = R.lensPath(["data", "module", action.module]);
      const fetchingLens = R.lensPath(["ui", "isFetchingModuleSummary"]);

      return R.compose(R.set(summaryLens, action.data), R.set(fetchingLens, false))(state);
    }

    case "SQUAD/FINISH_SQUAD_REQUEST": {
      const fetchingLens = R.lensPath(["ui", "isFetchingModule"]);
      return R.set(fetchingLens, true, state);
    }
    case "SQUAD/FINISH_SQUAD_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingModule"]);

      return R.set(fetchLens, false, state);
    }

    case "SQUAD/FINISH_SQUAD_SUCCESS": {
      const summaryLens = R.lensPath(["data", "module", action.module.selectedModule.slug]);
      const fetchLens = R.lensPath(["ui", "isFetchingModule"]);

      const module = action.module;
      const indexContent = module.content.findIndex(it => it._id === action.materialId);
      module.content[indexContent].view = true;

      return R.compose(R.set(summaryLens, module), R.set(fetchLens, false))(state);
    }
    default:
      return state;
  }
}

export default squadReducer;
