// @flow
import * as R from "ramda";

const initialState = {
  ui: {
    isFetchingLessons: false,
    isFetchingLesson: false,
    isFetchingContentRelated: false,
    isFetchingCheckRelatedContent: false,
    isFetchingVerifyRelatedContent: false,
    isToggleModal: false
  },
  data: {
    lessons: [],
    checkRelatedContent: [],
    lesson: {},
    relatedContent: {
      materials: [],
      mindmaps: [],
      podcasts: [],
      exercises: [],
      practicetests: [],
      workbooks: []
    }
  }
};

function lessonsReducer(state = initialState, action) {
  switch (action.type) {
    case "LESSONS/TRACKER_EVENTS_LESSON_REQUEST": {
      if (action.event !== "onFinish") {
        return state;
      }

      const updatedLessons = state.data.lessons.lessons ?
        state.data.lessons &&
        state.data.lessons.lessons &&
        state.data.lessons.lessons.map(it => {
          if (it.slug !== action.slug) {
            return it;
          }

          return {
            ...it,
            done: true
          };
        }) : state.data.lessons && state.data.lessons.map(it => {
          if (it.slug !== action.slug) {
            return it;
          }

          return {
            ...it,
            done: true
          };
        });

      const lessonsLens = state.data.lessons.lessons ? R.lensPath(["data", "lessons", "lessons"]) : R.lensPath(["data", "lessons"]);

      return R.set(lessonsLens, updatedLessons, state);
    }
    case "LESSONS/CLEAN_DETAILS": {
      const fetchLens = R.lensPath(["data", "lesson"]);
      return R.set(fetchLens, {}, state);
    }
    case "LESSONS/TOGGLE_MODAL_LESSON": {
      const filterLens = R.lensPath(["ui", "isToggleModal"]);

      return R.set(filterLens, action.modal, state);
    }
    case "LESSONS/LESSONS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingLessons"]);
      return R.set(fetchLens, true, state);
    }
    case "LESSONS/LESSONS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingLessons"]);
      return R.set(fetchLens, false, state);
    }
    case "LESSONS/LESSONS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingLessons"]);
      const lessonsLens = R.lensPath(["data", "lessons"]);

      return R.compose(R.set(fetchLens, false), R.set(lessonsLens, action.data))(state);
    }
    case "LESSONS/RELATED_CONTENT_EVENTS_LESSON_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingCheckRelatedContent"]);
      return R.set(fetchLens, true, state);
    }
    case "LESSONS/RELATED_CONTENT_EVENTS_LESSON_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingCheckRelatedContent"]);
      return R.set(fetchLens, false, state);
    }
    case "LESSONS/RELATED_CONTENT_EVENTS_LESSON_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingCheckRelatedContent"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "LESSONS/VERIFY_RELATED_CONTENT_EVENTS_LESSON_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingVerifyRelatedContent"]);
      return R.set(fetchLens, true, state);
    }
    case "LESSONS/VERIFY_RELATED_CONTENT_EVENTS_LESSON_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingVerifyRelatedContent"]);
      return R.set(fetchLens, false, state);
    }
    case "LESSONS/VERIFY_RELATED_CONTENT_EVENTS_LESSON_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingVerifyRelatedContent"]);
      const lessonsLens = R.lensPath(["data", "checkRelatedContent"]);
      return R.compose(R.set(fetchLens, false), R.set(lessonsLens, action.data))(state);
    }
    case "LESSONS/LESSON_DETAILS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingLesson"]);
      return R.set(fetchLens, true, state);
    }
    case "LESSONS/LESSON_DETAILS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingLesson"]);
      return R.set(fetchLens, false, state);
    }
    case "LESSONS/LESSON_DETAILS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingLesson"]);
      const lessonLens = R.lensPath(["data", "lesson"]);

      return R.compose(R.set(fetchLens, false), R.set(lessonLens, action.data))(state);
    }
    case "LESSONS/LESSON_RELATED_CONTENT_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingContentRelated"]);
      return R.set(fetchLens, true, state);
    }
    case "LESSONS/LESSON_RELATED_CONTENT_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingContentRelated"]);
      return R.set(fetchLens, false, state);
    }
    case "LESSONS/LESSON_RELATED_CONTENT_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingContentRelated"]);
      const relatedContentLens = R.lensPath(["data", "relatedContent"]);

      return R.compose(R.set(fetchLens, false), R.set(relatedContentLens, action.data))(state);
    }
    case "LESSONS/LESSONS_BY_SUBJECT_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingLessons"]);
      return R.set(fetchLens, true, state);
    }
    case "LESSONS/LESSONS_BY_SUBJECT_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingLessons"]);
      return R.set(fetchLens, false, state);
    }
    case "LESSONS/LESSONS_BY_SUBJECT_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingLessons"]);
      const lessonsLens = R.lensPath(["data", "lessons"]);

      return R.compose(R.set(fetchLens, false), R.set(lessonsLens, action.data))(state);
    }
    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}

export default lessonsReducer;
