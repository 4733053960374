import type { Action } from "../../../../types";
import * as R from "ramda";

type State = {
  ui: {
    isUploading: boolean
  },
  data: {
    file: Object
  }
};

const initialState = {
  ui: {
    isUploading: false
  },
  data: {
    file: []
  }
};

function uploadReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case "UPLOAD/UPLOAD_FILE": {
      const fetchLens = R.lensPath(["ui", "isUploading"]);
      return R.set(fetchLens, true, state);
    }
    case "UPLOAD/UPLOAD_FILE_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isUploading"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "UPLOAD/UPLOAD_FILE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isUploading"]);
      const lens = R.lensPath(["data", "file"]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(lens, action.data)
      )(state);
    }

    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}

export default uploadReducer;
