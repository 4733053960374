import * as R from "ramda";
import moment from "moment";
import type { Action } from "../../../types";

function joinMessages(previous, next) {
  previous.dateTime = next.dateTime;
  previous.message = `${previous.message}\n
                        ${next.message}`;
  if (previous.aggregate) {
    previous.aggregate = [...previous.aggregate, next.messageId];
  } else {
    previous.aggregate = [next.messageId];
  }
  return previous;
}
const initialState = {
  ui: {
    isFetchingTours: false,
    fetchingTour: true,
    fetchingTimeline: false,
    selectedFilter: "all",
    isToggleModal: false,
    isToggleModalSambatech: false,
    isToggleModalMaterial: false,
    isToggleNotify: false,
    fetchingHistory: false,
    selectedDay: moment()
      .utc()
      .format("MM/DD/YYYY"),
    selectedHour: moment()
      .utc(-3)
      .format("HH:mm")
  },
  data: {
    tours: [],
    tour: {}
  }
};

function toursReducer(state = initialState, action: Action) {
  switch (action.type) {
    case "TOUR/UPDATE_FILTER": {
      const filterLens = R.lensPath(["ui", "selectedFilter"]);

      return R.set(filterLens, action.filter, state);
    }

    case "TOUR/TOGGLE_MODAL_DELETE_QUESTION": {
      const filterLens = R.lensPath(["ui", "isToggleModal"]);

      return R.set(filterLens, action.modal, state);
    }

    case "TOUR/TOGGLE_MODAL_TOUR_MATERIAL": {
      const filterLens = R.lensPath(["ui", "isToggleModalMaterial"]);

      return R.set(filterLens, action.modal, state);
    }
    case "TOUR/TOGGLE_MODAL_TOKEN_SAMBATECH": {
      const filterLens = R.lensPath(["ui", "isToggleModalSambatech"]);

      return R.set(filterLens, action.modal, state);
    }

    case "TOUR/SET_HISTORY": {
      const fetchLens = R.lensPath(["ui", "fetchingHistory"]);
      return R.set(fetchLens, true, state);
    }
    case "TOUR/SET_HISTORY_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "fetchingHistory"]);

      return R.compose(R.set(fetchLens, false))(state);
    }
    case "TOUR/SET_HISTORY_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingHistory"]);

      return R.compose(R.set(fetchLens, false))(state);
    }

    case "TOUR/TOGGLE_NOTIFY": {
      const filterLens = R.lensPath(["ui", "isToggleNotify"]);
      return R.set(filterLens, action.modal, state);
    }

    case "TOUR/CLEAN_DETAILS": {
      const fetchLens = R.lensPath(["data", "pastTour"]);
      return R.set(fetchLens, {}, state);
    }

    case "TOUR/TOURS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingTours"]);
      return R.set(fetchLens, true, state);
    }
    case "TOUR/TOURS_SUCCESS": {
      const dataLens = R.lensPath(["data", "tours"]);
      const fetchLens = R.lensPath(["ui", "isFetchingTours"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "TOUR/TOURS_FAILURE": {
      const dataLens = R.lensPath(["data", "tours"]);
      const fetchLens = R.lensPath(["ui", "isFetchingTours"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "TOUR/TIMELINE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingTimeline"]);
      return R.set(fetchLens, true, state);
    }
    case "TOUR/TIMELINE_SUCCESS": {
      const dataLens = R.lensPath(["data", "timeline"]);
      const fetchLens = R.lensPath(["ui", "fetchingTimeline"]);

      let filteredTours = action.data.filter(it => it.events.length > 0);

      return R.compose(R.set(dataLens, filteredTours), R.set(fetchLens, false))(state);
    }
    case "TOUR/TIMELINE_FAILURE": {
      const dataLens = R.lensPath(["data", "timeline"]);
      const fetchLens = R.lensPath(["ui", "fetchingTimeline"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "TOUR/TOUR_LIKE_DISLIKE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingPastTour"]);
      return R.set(fetchLens, true, state);
    }
    case "TOUR/TOUR_LIKE_DISLIKE_SUCCESS": {
      const dataLens = R.lensPath(["data", "pastTour"]);
      const fetchLens = R.lensPath(["ui", "fetchingPastTour"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "TOUR/TOUR_LIKE_DISLIKE_FAILURE": {
      const dataLens = R.lensPath(["data", "pastTour"]);
      const fetchLens = R.lensPath(["ui", "fetchingPastTour"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "TOUR/QUESTION_LIKE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingLikeQuestions"]);
      return R.set(fetchLens, true, state);
    }
    case "TOUR/QUESTION_LIKE_SUCCESS": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingLikeQuestions"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "TOUR/QUESTION_LIKE_FAILURE": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingLikeQuestions"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "TOUR/QUESTION_DELETE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingDeleteQuestions"]);
      return R.set(fetchLens, true, state);
    }
    case "TOUR/QUESTION_DELETE_SUCCESS": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingDeleteQuestions"]);
      const tourLens = R.lensPath(["data", "tour", "isQuestionAsked"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false), R.set(tourLens, false))(state);
    }
    case "TOUR/QUESTION_DELETE_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingDeleteQuestions"]);

      return R.compose(R.set(fetchLens, false))(state);
    }
    case "TOUR/QUESTION_UPDATE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingUpdateQuestions"]);
      return R.set(fetchLens, true, state);
    }
    case "TOUR/QUESTION_UPDATE_SUCCESS": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingUpdateQuestions"]);
      const newQuestions = [...state.data.questions];

      const positionEdited = newQuestions.findIndex(question => question.id === action.data.id);
      newQuestions[positionEdited] = action.data;

      return R.compose(R.set(dataLens, newQuestions), R.set(fetchLens, false))(state);
    }
    case "TOUR/QUESTION_UPDATE_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingUpdateQuestions"]);

      return R.compose(R.set(fetchLens, false))(state);
    }

    case "TOUR/QUESTION_SELECT_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingSelectQuestions"]);
      return R.set(fetchLens, true, state);
    }
    case "TOUR/QUESTION_SELECT_SUCCESS": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingSelectQuestions"]);
      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "TOUR/QUESTION_SELECT_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingSelectQuestions"]);

      return R.compose(R.set(fetchLens, false))(state);
    }

    case "TOUR/COURSE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingSelectCourses"]);
      return R.set(fetchLens, true, state);
    }
    case "TOUR/COURSE_SELECT_SUCCESS": {
      const dataLens = R.lensPath(["data", "courses"]);
      const fetchLens = R.lensPath(["ui", "fetchingSelectCourses"]);
      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "TOUR/COURSE_SELECT_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingSelectCourses"]);

      return R.compose(R.set(fetchLens, false))(state);
    }

    case "TOUR/QUESTIONS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingQuestions"]);
      return R.set(fetchLens, true, state);
    }
    case "TOUR/QUESTIONS_SUCCESS": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingQuestions"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "TOUR/QUESTIONS_FAILURE": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingQuestions"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "TOUR/QUESTION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingQuestion"]);
      return R.set(fetchLens, true, state);
    }
    case "TOUR/QUESTION_SUCCESS": {
      const dataLens = R.lensPath(["data", "question"]);
      const tourLens = R.lensPath(["data", "tour", "isQuestionAsked"]);
      const dataLensQuestions = R.lensPath(["data", "questions"]);

      const fetchLens = R.lensPath(["ui", "fetchingQuestion"]);
      const newQuestions = [...state.data.questions];
      newQuestions.push(action.data);

      return R.compose(
        R.set(dataLens, action.data),
        R.set(tourLens, true),
        R.set(fetchLens, false),
        R.set(dataLensQuestions, newQuestions)
      )(state);
    }
    case "TOUR/QUESTION_FAILURE": {
      const dataLens = R.lensPath(["data", "question"]);
      const fetchLens = R.lensPath(["ui", "fetchingQuestion"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "TOUR/TOUR_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingTour"]);
      return R.set(fetchLens, true, state);
    }
    case "TOUR/TOUR_SUCCESS": {
      const dataLens = R.lensPath(["data", "tour"]);
      const fetchLens = R.lensPath(["ui", "fetchingTour"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "TOUR/TOUR_FAILURE": {
      const dataLens = R.lensPath(["data", "tour"]);
      const fetchLens = R.lensPath(["ui", "fetchingTour"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }
    case "TOUR/PAST_TOUR_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingPastTours"]);
      return R.set(fetchLens, true, state);
    }
    case "TOUR/PAST_TOUR_SUCCESS": {
      const dataLens = R.lensPath(["data", "pastTours"]);
      const fetchLens = R.lensPath(["ui", "fetchingPastTours"]);

      let actualData = state.data.pastTours;
      if (action.data.data) {
        actualData.meta = action.data.meta;
        if (action.data.meta.currentPage === 1) {
          actualData.data = [];
        }
        actualData.data = [...actualData.data, ...action.data.data];
      }

      return R.compose(R.set(dataLens, actualData), R.set(fetchLens, false))(state);
    }
    case "TOUR/PAST_TOUR_FAILURE": {
      const dataLens = R.lensPath(["data", "pastTours"]);
      const fetchLens = R.lensPath(["ui", "fetchingPastTours"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "TOUR/TOURS_SUBSCRIPTION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingTourSubscription"]);
      return R.set(fetchLens, true, state);
    }

    case "TOUR/TOURS_SUBSCRIPTION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingTourSubscription"]);
      const dataLens = R.lensPath(["data", "tour", "isSubscribed"]);

      return R.compose(R.set(dataLens, true), R.set(fetchLens, false))(state);
    }

    case "TOUR/TOURS_SUBSCRIPTION_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingTourSubscription"]);
      return R.set(fetchLens, false, state);
    }

    case "TOUR/DAY_ACTIVE_CHANGED": {
      const filterLens = R.lensPath(["ui", "selectedDay"]);

      return R.set(filterLens, action.filter, state);
    }

    case "TOUR/HOUR_ACTIVE_CHANGED": {
      const filterLens = R.lensPath(["ui", "selectedHour"]);

      return R.set(filterLens, action.filter, state);
    }

    case "TOUR/SELECT_TOUR": {
      const filterLens = R.lensPath(["data", "tour"]);

      return R.set(filterLens, action.event, state);
    }

    case "TOUR/SELECT_TOUR_QUESTION": {
      const filterLens = R.lensPath(["data", "question"]);

      return R.set(filterLens, action.question, state);
    }

    case "TOUR/UPDATE_MESSAGE_HISTORY": {
      const copyState = { ...state };
      if (copyState.chat.messages.length) {
        let lastMsg = copyState.chat.messages.pop();

        if (lastMsg.userId === action.data.userId && lastMsg.messageType === action.data.messageType) {
          lastMsg = joinMessages(lastMsg, action.data);
          copyState.chat.messages = [...copyState.chat.messages, lastMsg];
        } else {
          copyState.chat.messages = [...copyState.chat.messages, lastMsg, action.data];
        }
        return { ...copyState };
      } else {
        copyState.chat.messages = [...copyState.chat.messages, action.data];
        return { ...copyState };
      }
    }
    case "TOUR/UPDATE_MESSAGE_FIELD": {
      const filterLens = R.lensPath(["chat", "messageField"]);

      return R.set(filterLens, action.data, state);
    }
    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}

export { toursReducer };
