import { AREAS_SUCCESS, AREA_DETAILS_SUCCESS } from "./actionTypes";

const initialState = {
  ui: {
  },
  data: {
  }
};

export function areasReducer(state = initialState, action) {
  switch (action.type) {
    case AREA_DETAILS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.data.slug]: { ...action.data }
        }
      };
    }
    case AREAS_SUCCESS: {
      return { ...state, data: { ...state.data, areas: action.data } };
    }
    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}