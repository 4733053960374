/* @flow
 */
import * as R from "ramda";
import type { Action } from "../../../types";

type State = {
  ui: {
    isFetchingMaps: boolean,
    isFetchingMap: boolean
  },
  data: {
    maps: Array<Object>,
    map: Object
  }
};

const initialState = {
  ui: {
    isFetchingMaps: false,
    isFetchingMap: false
  },
  data: {
    maps: [],
    map: {}
  }
};

function mapsReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case "MAPS/MAPS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingMaps"]);
      return R.set(fetchLens, true, state);
    }
    case "MAPS/MAPS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingMaps"]);
      return R.set(fetchLens, false, state);
    }
    case "MAPS/MAPS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingMaps"]);
      const mapsLens = R.lensPath(["data", "maps"]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(mapsLens, action.data)
      )(state);
    }

    case "MAPS/MAPS_DETAILS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingMap"]);
      return R.set(fetchLens, true, state);
    }
    case "MAPS/MAPS_DETAILS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingMap"]);
      return R.set(fetchLens, false, state);
    }
    case "MAPS/MAPS_DETAILS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingMap"]);
      const maplLens = R.lensPath(["data", "map"]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(maplLens, action.data)
      )(state);
    }
    case "APP/CLEAN_STATE": {
      return initialState;
    }

    default:
      return state;
  }
}

export default mapsReducer;
