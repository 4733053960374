import * as R from "ramda";
import config from "../../../core/config";

const EMPTY_RELEASENOTE = {
  items: [
    {
      suggestion: false,
      title: "Melhorias gerais:",
      text:
        "Fizemos algumas atualizações para melhorar a <strong>performance</strong> e <strong>corrigir bugs</strong> relatados pelos JubiAlunos. Você vai perceber a plataforma mais rápida e com menos erros nessa versão."
    }
  ],
  version: "",
  highlight: false,
  userHasSeen: true
};

const emptyUser = {
  name: "Jubialuno (a)",
  email: ""
};

const emptyHelmet = {
  title: "Aprova Total",
  canonical: config.baseURL,
  pathname: ""
};

const initialState = {
  buildNumber: config.buildNumber,
  ui: {
    tokenExpired: false,
    open: true,
    isRequestingMe: true,
    fetchingNotifications: false,
    isFetchingReleaseNotes: false,
    isFetchingConfirmAccept: false,
    isRequestingLive: false,
    isErrorReport: false,
    maskSearch: false,
    notificationsLoadMore: true,
    hasError: false,
    isFetchingSendEmail: false,
    toggleTeacherResearch: false,
    isSendingNPS: false,
    isFetchingCompleteRegistration: false,
    isMatriculatingSimuladao: false
  },
  data: {
    user: { ...emptyUser },
    notifications: [],
    releaseNotes: {},
    errorReport: [],
    payment: {},
    live: [],
    helmet: { ...emptyHelmet },
    cachedReleaseNotes: null
  }
};

function getFullPathFromCanonical(data) {
  if (!data.title || !data.canonical) {
    return {
      ...emptyHelmet
    };
  } else {
    return {
      ...data,
      title: `${data.title} - Aprova Total`,
      canonical: `${config.platformURL}${data.canonical}`,
      pathname: data.canonical
    };
  }
}

function appReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case "APP/REQUEST_VERIFY_EMAIL": {
      const fetchLens = R.lensPath(["ui", "isFetchingSendEmail"]);
      return R.set(fetchLens, true, state);
    }
    case "APP/REQUEST_VERIFY_EMAIL_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSendEmail"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "APP/REQUEST_VERIFY_EMAIL_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSendEmail"]);
      const lens = R.lensPath(["data", "user"]);

      const user = state.data.user;

      if (!action.data.error) {
        user.emailSend = true;
        user.emailToConfirm = action.data.emailToConfirm;
      }

      return R.compose(R.set(fetchLens, false), R.set(lens, user))(state);
    }

    case "APP/CLOSE_LIVE_NOTIFICATION": {
      const lens = R.lensPath(["data", "live"]);
      const lives = state.data.live;

      const newLives = lives.map(it => (it.event.slug === action.slug ? { ...it, close: true } : it));

      return R.set(lens, newLives, state);
    }
    case "APP/REQUEST_COUNTDOWN_TOGGLE_SUCCESS": {
      const fetchLens = R.lensPath(["data", "user", "showEnemCountdown"]);
      return R.set(fetchLens, !state.data.user.showEnemCountdown, state);
    }
    case "APP/REQUEST_SOUND_TOGGLE_SUCCESS": {
      const fetchLens = R.lensPath(["data", "user", "exercisePlaySound"]);
      return R.set(fetchLens, !state.data.user.exercisePlaySound, state);
    }
    case "APP/REQUEST_PERCENT_TOGGLE_SUCCESS": {
      const fetchLens = R.lensPath(["data", "user", "exercisePercentage"]);
      return R.set(fetchLens, !state.data.user.exercisePercentage, state);
    }
    case "APP/ERROR_FOUND": {
      const lens = R.lensPath(["ui", "hasError"]);
      return R.set(lens, true, state);
    }
    case "APP/NEW_LOGIN": {
      const lens = R.lensPath(["ui", "tokenExpired"]);
      return R.set(lens, false, state);
    }
    case "APP/TOKEN_EXPIRED": {
      const lens = R.lensPath(["ui", "tokenExpired"]);
      return R.set(lens, true, state);
    }
    case "APP/LOGOUT": {
      const lens = R.lensPath(["data", "user"]);
      return R.set(lens, { ...emptyUser }, state);
    }
    case "APP/TOGGLE_MENU": {
      const fetchLens = R.lensPath(["ui", "open"]);
      return R.set(fetchLens, !state.ui.open, state);
    }
    case "APP/MASK_SEARCH": {
      const fetchLens = R.lensPath(["ui", "maskSearch"]);
      return R.set(fetchLens, action.data, state);
    }
    case "APP/REQUEST_ME": {
      const lens = R.lensPath(["ui", "isRequestingMe"]);
      return R.set(lens, true, state);
    }

    case "APP/UPDATE_MATERIAL_CPF": {
      const { materialCPF } = action;
      const lens = R.lensPath(["data", "user", "materialCPF"]);
      return R.set(lens, materialCPF, state);
    }

    case "APP/REQUEST_ME_SUCCESS": {
      const lens = R.lensPath(["ui", "isRequestingMe"]);
      const lensData = R.lensPath(["data", "user"]);
      return R.compose(
        R.set(lens, false),
        R.set(lensData, {
          ...action.data,
          daysTillFreeTrialExpire: (new Date(action.data.freeTrialUntil).valueOf() - Date.now()) / 60 / 60 / 1000 / 24
        })
      )(state);
    }
    case "APP/REQUEST_ME_FAILURE":
    case "APP/REQUEST_ME_ERROR": {
      const lens = R.lensPath(["ui", "isRequestingMe"]);
      return R.set(lens, false, state);
    }

    case "APP/REQUEST_LIVE_SUCCESS": {
      const lens = R.lensPath(["ui", "isRequestingLive"]);
      const lensData = R.lensPath(["data", "live"]);
      return R.compose(R.set(lens, false), R.set(lensData, action.data))(state);
    }
    case "APP/REQUEST_LIVE":
    case "APP/REQUEST_LIVE_FAILURE": {
      const lens = R.lensPath(["ui", "isRequestingLive"]);
      return R.set(lens, false, state);
    }

    case "APP/NOTIFICATIONS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingNotifications"]);
      return R.set(fetchLens, true, state);
    }
    case "APP/NOTIFICATIONS_SUCCESS": {
      const dataLens = R.lensPath(["data", "notifications"]);
      const fetchLens = R.lensPath(["ui", "fetchingNotifications"]);
      const fetchLens1 = R.lensPath(["ui", "notificationsLoadMore"]);

      let newNotifications = action.data;
      let notifications;
      if (action.data) {
        notifications = state.data.notifications.concat(newNotifications);
      }
      let notificationsLoadMore = newNotifications.length < 5 ? false : true;

      return R.compose(
        R.set(dataLens, notifications),
        R.set(fetchLens, false),
        R.set(fetchLens1, notificationsLoadMore)
      )(state);
    }
    case "APP/CLEAN_NOTIFICATION": {
      const dataLens = R.lensPath(["data", "notifications"]);

      return R.compose(R.set(dataLens, []))(state);
    }
    case "APP/NOTIFICATIONS_FAILURE": {
      const dataLens = R.lensPath(["data", "notifications"]);
      const fetchLens = R.lensPath(["ui", "fetchingNotifications"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "APP/NOTIFICATIONS_HISTORY_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingNotificationsHistory"]);
      return R.set(fetchLens, true, state);
    }
    case "APP/NOTIFICATIONS_HISTORY_SUCCESS": {
      const dataLens = R.lensPath(["data", "notifications"]);
      const fetchLens = R.lensPath(["ui", "fetchingNotificationsHistory"]);

      let newNotifications = [];
      if (action.data.ok) {
        const notifications = state.data.notifications;
        newNotifications = notifications.map(it => {
          it.seenDate = new Date().toISOString();
          return it;
        });
      }

      return R.compose(R.set(dataLens, newNotifications), R.set(fetchLens, false))(state);
    }
    case "APP/NOTIFICATIONS_HISTORY_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingNotificationsHistory"]);

      return R.compose(R.set(fetchLens, false))(state);
    }

    case "APP/HELMET_REQUEST": {
      const helmetLens = R.lensPath(["data", "helmet"]);

      return R.set(helmetLens, { ...getFullPathFromCanonical(action.data) }, state);
    }

    case "APP/ERROR_REPORT_SUCCESS": {
      const lens = R.lensPath(["ui", "isErrorReport"]);
      const lensData = R.lensPath(["data", "errorReport"]);
      return R.compose(R.set(lens, false), R.set(lensData, action.data))(state);
    }
    case "APP/ERROR_REPORT_REQUEST":
    case "APP/ERROR_REPORT_FAILURE": {
      const lens = R.lensPath(["ui", "isErrorReport"]);
      return R.set(lens, false, state);
    }

    case "APP/UPDATE_PICTURE_SUCCESS": {
      const lensData = R.lensPath(["data", "user"]);

      let _user = state.data.user;
      if (action.data.picture) {
        _user.picture = action.data.picture;
      }

      return R.compose(R.set(lensData, _user))(state);
    }

    case "APP/CLEAN_STATE": {
      return initialState;
    }

    case "APP/REQUEST_RELEASE_NOTES_SUCCESS_EMPTY": {
      const lensData = R.lensPath(["data", "releaseNotes"]);
      const fetchLens = R.lensPath(["ui", "isFetchingReleaseNotes"]);
      return R.compose(
        R.set(lensData, { ...EMPTY_RELEASENOTE, version: action.version }),
        R.set(fetchLens, false)
      )(state);
    }

    case "APP/REQUEST_RELEASE_NOTES_SUCCESS": {
      const lensData = R.lensPath(["data", "releaseNotes"]);
      const cachedReleaseNotesData = R.lensPath(["data", "cachedReleaseNotes"]);
      const fetchLens = R.lensPath(["ui", "isFetchingReleaseNotes"]);
      return R.compose(
        R.set(lensData, action.data),
        R.set(cachedReleaseNotesData, action.data),
        R.set(fetchLens, false)
      )(state);
    }

    case "APP/REQUEST_RELEASE_NOTES_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingReleaseNotes"]);
      return R.set(fetchLens, false, state);
    }

    case "APP/REQUEST_RELEASE_NOTES": {
      const fetchLens = R.lensPath(["ui", "isFetchingReleaseNotes"]);
      return R.set(fetchLens, true, state);
    }

    case "APP/RELEASE_NOTES_SET_SEEN_SUCCESS": {
      const fetchLens = R.lensPath(["data", "releaseNotes", "userHasSeen"]);

      return R.compose(R.set(fetchLens, true))(state);
    }

    case "APP/REQUEST_ACCEPT_TERMS": {
      const fetchLens = R.lensPath(["ui", "isFetchingConfirmAccept"]);
      return R.set(fetchLens, true, state);
    }

    case "APP/REQUEST_ACCEPT_TERMS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingConfirmAccept"]);
      const dataLens = R.lensPath(["data", "user"]);
      let stateUser = state.data.user;
      stateUser.showUpdatedTerms = false;
      return R.compose(R.set(fetchLens, false), R.set(dataLens, stateUser))(state);
    }

    case "APP/REQUEST_ACCEPT_TERMS_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingConfirmAccept"]);

      return R.set(fetchLens, false)(state);
    }

    case "APP/REQUEST_PAYMENT": {
      const fetchLens = R.lensPath("data", "payment");

      return R.set(fetchLens, action.data, state);
    }

    case "APP/TOGGLE_TEACHER_RESEARCH": {
      const fetchLens = R.lensPath(["ui", "toggleTeacherResearch"]);
      return R.set(fetchLens, true, state);
    }

    case "APP/TOGGLE_TEACHER_RESEARCH_CLOSE": {
      const fetchLens = R.lensPath(["ui", "toggleTeacherResearch"]);
      return R.set(fetchLens, false, state);
    }

    case "APP/SUBMIT_NPS": {
      const fetchLens = R.lensPath(["ui", "isSendingNPS"]);
      return R.set(fetchLens, true, state);
    }

    case "APP/SUBMIT_NPS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isSendingNPS"]);
      return R.set(fetchLens, false)(state);
    }

    case "APP/SUBMIT_NPS_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isSendingNPS"]);
      return R.set(fetchLens, false)(state);
    }
    case "ACCOUNT/COMPLETE_REGISTRATION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingCompleteRegistration"]);
      return R.set(fetchLens, true, state);
    }
    case "ACCOUNT/COMPLETE_REGISTRATION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingCompleteRegistration"]);
      const dataLens = R.lensPath(["data", "user"]);
      let stateUser = state.data.user;
      stateUser.showModalComplementDataTransaction = false;

      return R.compose(R.set(fetchLens, false), R.set(dataLens, stateUser))(state);
    }
    case "ACCOUNT/COMPLETE_REGISTRATION_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingCompleteRegistration"]);
      return R.set(fetchLens, false, state);
    }
    case "SIMULATION/SIMULADAO_MATRICULATION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isMatriculatingSimuladao"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULADAO_MATRICULATION_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isMatriculatingSimuladao"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "SIMULATION/SIMULADAO_MATRICULATION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isMatriculatingSimuladao"]);
      const lens = R.lensPath(["data", "user", "simulation", "isSimulationSubscriber"]);
      return R.compose(R.set(fetchLens, false), R.set(lens, true))(state);
    }
    case "APP/REQUEST_DARK_MODE_TOGGLE_SUCCESS": {
      const fetchLens = R.lensPath(["data", "user", "isDarkMode"]);
      return R.set(fetchLens, !state.data.user.isDarkMode, state);
    }

    default:
      return state;
  }
}

export default appReducer;
