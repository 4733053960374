/* @flow
 */
import * as R from "ramda";
import type { Action } from "../../../types";

type State = {
  ui: {
    isPaying: boolean,
    isLogging: boolean,
    isRecovery: boolean,
    isReseting: boolean,
    isRegistering: boolean,
    isFetchingCep: boolean,
    isFetchingPlans: boolean,
    isApplyingCoupon: boolean,
    isFetchingUserInfo: boolean,
    isFetchingNewPassword: boolean,
    isFetchingEmailPassword: boolean,
    isFetchingVerificationCode: boolean
  },
  data: {
    couponCode: {
      value: string,
      warning: string,
      error: string
    },
    selectedPlan: ?Object,
    plans: Array<Object>,
    coupon: ?Object,
    paymentResponse: ?Object,
    lastPurchase: ?Object,
    recovery: Object,
    viaCep: Object
  }
};

const initialState = {
  ui: {
    isPaying: false,
    isLogging: false,
    isRecovery: false,
    isReseting: false,
    isFetchingCep: false,
    isRegistering: false,
    isFetchingPlans: false,
    isFetchingUserInfo: false,
    isFetchingNewPassword: false,
    isFetchingEmailPassword: false,
    isFetchingVerificationCode: false,
    isApplyingCoupon: false
  },
  data: {
    couponCode: {
      value: "",
      warning: "",
      error: ""
    },
    installments: {},
    recovery: {},
    selectedPlan: null,
    plans: [],
    coupon: null,
    price: 0,
    paymentMethod: "CARTAO",
    paymentResponse: null,
    lastPurchase: null,
    viaCep: {}
  }
};

//TODO testar essa logica de planos, warnings e erros
function accountReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case "ACCOUNT/CLEAN_DETAILS": {
      const fetchLens = R.lensPath(["data"]);
      return R.set(fetchLens, initialState.data, state);
    }
    case "ACCOUNT/INSTALLMENTS_SUCCESS": {
      const lens = R.lensPath(["data", "installments"]);

      return R.set(lens, action.data.installments, state);
    }
    case "ACCOUNT/TRANSACTION_UPDATE": {
      const lens = R.lensPath(["data", "lastPurchase"]);

      return R.set(lens, action.data, state);
    }
    case "ACCOUNT/PAY_REQUEST": {
      const lens = R.lensPath(["ui", "isPaying"]);
      return R.set(lens, true, state);
    }
    case "ACCOUNT/PAY_SUCCESS": {
      const lens = R.lensPath(["ui", "isPaying"]);
      const lastPurchaseLens = R.lensPath(["data", "lastPurchase"]);

      return R.compose(R.set(lens, false), R.set(lastPurchaseLens, action.data))(state);
    }
    case "ACCOUNT/PAY_FAILURE": {
      const lens = R.lensPath(["ui", "isPaying"]);
      return R.set(lens, false, state);
    }
    case "ACCOUNT/PAY_ERROR": {
      const lens = R.lensPath(["ui", "isPaying"]);
      return R.set(lens, false, state);
    }
    case "ACCOUNT/COUPON_CODE_CHANGE": {
      const codeLens = R.lensPath(["data", "couponCode", "value"]);
      const errorLens = R.lensPath(["data", "couponCode", "error"]);
      const lens = R.lensPath(["data", "coupon"]);
      const couponCodeLens = R.lensPath(["data", "couponCode"]);

      return R.compose(
        R.set(codeLens, (action.data || "").toUpperCase()),
        R.set(errorLens, ""),
        R.set(lens, null),
        R.set(couponCodeLens, { warning: "", error: "", value: "" })
      )(state);
    }
    case "ACCOUNT/CLEAR_COUPON": {
      const lens = R.lensPath(["data", "coupon"]);
      const couponCodeLens = R.lensPath(["data", "couponCode"]);
      return R.compose(
        R.set(lens, null),
        R.set(couponCodeLens, {
          warning: action.clearInput ? "" : R.view(couponCodeLens, state).warning,
          error: "",
          value: action.clearInput ? "" : R.view(couponCodeLens, state).value
        })
      )(state);
    }
    case "ACCOUNT/APPLY_COUPON_REQUEST": {
      const lens = R.lensPath(["ui", "isApplyingCoupon"]);
      return R.set(lens, true, state);
    }
    case "ACCOUNT/APPLY_COUPON_FAILURE": {
      const lens = R.lensPath(["ui", "isApplyingCoupon"]);
      const errorLens = R.lensPath(["data", "couponCode", "error"]);
      return R.compose(
        R.set(lens, false),
        R.set(errorLens, `O cupom inserido não é válido! <br> Digite um cupom válido e tente novamente`)
      )(state);
    }
    case "ACCOUNT/APPLY_COUPON_SUCCESS": {
      const lens = R.lensPath(["ui", "isApplyingCoupon"]);
      const couponLens = R.lensPath(["data", "coupon"]);
      const planLens = R.lensPath(["data", "selectedPlan"]);
      const warningLens = R.lensPath(["data", "couponCode", "warning"]);
      let newPlan = state.data.selectedPlan || action.data.plan;
      let warningMsg = validateCoupon(newPlan, action.data);

      return R.compose(
        R.set(lens, false),
        R.set(couponLens, action.data),
        R.set(warningLens, warningMsg),
        R.set(planLens, newPlan)
      )(state);
    }
    case "ACCOUNT/SELECT_PLAN": {
      const lens = R.lensPath(["data", "selectedPlan"]);
      const warningLens = R.lensPath(["data", "couponCode", "warning"]);
      //const priceLens = R.lensPath(["data", "price"]);
      let warningMsg = validateCoupon(action.data, state.data.coupon);

      return R.compose(R.set(lens, action.data), R.set(warningLens, warningMsg))(state);
    }
    case "ACCOUNT/REGISTER_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isRegistering"]);
      return R.set(fetchLens, true, state);
    }
    case "ACCOUNT/REGISTER_FAILURE":
    case "ACCOUNT/REGISTER_ERROR": {
      const fetchLens = R.lensPath(["ui", "isRegistering"]);
      return R.set(fetchLens, false, state);
    }
    case "ACCOUNT/REGISTER_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isRegistering"]);
      return R.set(fetchLens, false, state);
    }
    case "ACCOUNT/LOGIN_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isLogging"]);
      return R.set(fetchLens, true, state);
    }
    case "ACCOUNT/LOGIN_FAILURE":
    case "ACCOUNT/LOGIN_ERROR": {
      const fetchLens = R.lensPath(["ui", "isLogging"]);
      return R.set(fetchLens, false, state);
    }
    case "ACCOUNT/LOGIN_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isLogging"]);
      return R.set(fetchLens, false, state);
    }

    case "ACCOUNT/RECOVER_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isRecovery"]);
      return R.set(fetchLens, true, state);
    }
    case "ACCOUNT/RECOVER_SUCCESS": {
      const dataLens = R.lensPath(["data", "recovery"]);
      const fetchLens = R.lensPath(["ui", "isRecovery"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "ACCOUNT/RECOVER_FAILURE": {
      const dataLens = R.lensPath(["data", "recovery"]);
      const fetchLens = R.lensPath(["ui", "isRecovery"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "ACCOUNT/CEP_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingCep"]);
      return R.set(fetchLens, true, state);
    }
    case "ACCOUNT/CEP_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingCep"]);
      return R.set(fetchLens, false, state);
    }
    case "ACCOUNT/CEP_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingCep"]);
      const cepLens = R.lensPath(["data", "viaCep"]);

      return R.compose(R.set(fetchLens, false), R.set(cepLens, action.data))(state);
    }
    case "ACCOUNT/PLANS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingPlans"]);
      return R.set(fetchLens, true, state);
    }
    case "ACCOUNT/PLANS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingPlans"]);
      const plansLens = R.lensPath(["data", "plans"]);
      return R.compose(
        R.set(fetchLens, false),
        R.set(
          plansLens,
          action.data.sort((a, b) => a.order > b.order)
        )
      )(state);
    }
    case "ACCOUNT/PLANS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingPlans"]);
      return R.set(fetchLens, false, state);
    }
    case "ACCOUNT/USERINFO_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingUserInfo"]);
      return R.set(fetchLens, true, state);
    }
    case "ACCOUNT/USERINFO_FAILURE":
    case "ACCOUNT/USERINFO_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingUserInfo"]);
      return R.set(fetchLens, false, state);
    }
    case "ACCOUNT/EMAIL_PASSWORD_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingEmailPassword"]);
      return R.set(fetchLens, true, state);
    }
    case "ACCOUNT/EMAIL_PASSWORD_FAILURE":
    case "ACCOUNT/EMAIL_PASSWORD_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingEmailPassword"]);
      return R.set(fetchLens, false, state);
    }
    case "ACCOUNT/VERIFICATION_CODE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingVerificationCode"]);
      return R.set(fetchLens, true, state);
    }
    case "ACCOUNT/VERIFICATION_CODE_FAILURE":
    case "ACCOUNT/VERIFICATION_CODE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingVerificationCode"]);
      return R.set(fetchLens, false, state);
    }
    case "ACCOUNT/NEW_PASSWORD_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingNewPassword"]);
      return R.set(fetchLens, true, state);
    }
    case "ACCOUNT/NEW_PASSWORD_FAILURE":
    case "ACCOUNT/NEW_PASSWORD_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingNewPassword"]);
      return R.set(fetchLens, false, state);
    }
    case "ACCOUNT/VERIFY_PIX_PAYMENT_SUCCESS": {
      const fetchLens = R.lensPath(["data", "lastPurchase", "status"]);

      if (action.data.status === "paid") {
        return R.set(fetchLens, action.data.status, state);
      }
      return state;
    }
    case "ACCOUNT/CHANGE_METHOD_PAY": {
      const lastPurchaseLens = R.lensPath(["data", "paymentMethod"]);

      return R.set(lastPurchaseLens, action.data, state);
    }
    case "ACCOUNT/RESET_METHOD_PAY": {
      const lastPurchaseLens = R.lensPath(["data", "lastPurchase"]);

      return R.set(lastPurchaseLens, null, state);
    }
    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}

const validateCoupon = (plan, coupon) => {
  if (!coupon) return "";

  if (!plan || coupon.plan.id !== plan.id) {
    return `Este cupom não pode ser aplicado no plano selecionado!`;
  }
  return "";
};

/*
const getPriceWithDiscount = (plan, coupon) => {};

    const { selectedPlan, coupon } = this.props;

    switch (coupon.coupon.type) {
      case "total":
        return selectedPlan.price;
      case "discount":
        return selectedPlan.price * coupon.coupon.discount / 100;
      case "alternative_value":
        return selectedPlan.price - coupon.coupon.alternative_value;
      default:
        throw new Error("Cupom inválido");
    }
  }*/

export default accountReducer;
