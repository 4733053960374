import * as R from "ramda";

const initialState = {
  ui: {
    isToggleModal: false,
    isFetchingQuestions: false,
    isFetchingQuestionsByOrigin: false,
    isFetchingLikeOriginQuestion: false
  },
  data: {}
};

function relatedQuestionsReducer(state = initialState, action) {
  switch (action.type) {
    case "RELATEDQUESTIONS/QUESTION_ORIGIN": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestionsByOrigin"]);
      return R.set(fetchLens, true, state);
    }
    case "RELATEDQUESTIONS/QUESTION_ORIGIN_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestionsByOrigin"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "RELATEDQUESTIONS/QUESTION_ORIGIN_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestionsByOrigin"]);
      const lens = R.lensPath(["data", action.originId]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }
    case "RELATEDQUESTIONS/QUESTIONS_NEXT_PAGE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestions"]);

      return R.set(fetchLens, true, state);
    }
    case "RELATEDQUESTIONS/QUESTIONS_NEXT_PAGE_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestions"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "RELATEDQUESTIONS/QUESTIONS_NEXT_PAGE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestions"]);
      const lens = R.lensPath(["data", action.originId]);

      let originId = action.originId;
      const data = {
        meta: action.data.meta,
        relatedQuestions: [...state.data[originId].relatedQuestions, ...action.data.relatedQuestions]
      };

      return R.compose(R.set(fetchLens, false), R.set(lens, data))(state);
    }
    case "RELATEDQUESTIONS/LIKE_QUESTION": {
      const fetchLens = R.lensPath(["ui", "isFetchingLikeOriginQuestion"]);

      return R.set(fetchLens, true, state);
    }
    case "RELATEDQUESTIONS/LIKE_QUESTION_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingLikeOriginQuestion"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "RELATEDQUESTIONS/LIKE_QUESTION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingLikeOriginQuestion"]);
      const lensQuestion = R.lensPath(["data", action.originId, "relatedQuestions"]);

      let originId = action.originId;
      let dataQuestion = state.data[originId].relatedQuestions.map(it => {
        if (String(it.id) === String(action.data.questionId)) {
          it.votesCount = action.data.votesCount;
          it.hasBeenLiked = action.data.hasBeenLiked;
        }
        return it;
      });
      return R.compose(R.set(fetchLens, false), R.set(lensQuestion, dataQuestion))(state);
    }

    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}

export default relatedQuestionsReducer;
