import * as R from "ramda";

const initialState = {
  ui: {
    fetchingComments: false,
    fetchingLikeComments: false,
    fetchingInsertComments: false,
    fetchingUpdateComments: false,
    fetchingDeleteComments: false,
    isToggleModal: false,
    isToggleToast: false
  },
  data: {
    comments: [],
    comment: [],
    commentDeleted: [],
    idCommentDelete: "",
    isAnswerCommentDelete: false
  }
};

function commentsMilzaoReducer(state = initialState, action) {
  switch (action.type) {
    case "MILZAOCOMMENTS/COMMENTS_DELETE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingDeleteComments"]);
      return R.set(fetchLens, true, state);
    }
    case "MILZAOCOMMENTS/COMMENTS_DELETE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "fetchingDeleteComments"]);
      const dataLens = R.lensPath(["data", "commentDeleted"]);
      const comments = R.lensPath(["data", "comments"]);
      const commentsLens = R.lensPath(["ui", "fetchingComments"]);

      let newComments = state.data.comments.filter(it => !action.data.ids.includes(it.id || it._id));

      newComments.map(it => {
        if (it.children && it.children.length) it.children = it.children.filter(it => !action.data.ids.includes(it.id));
        return it;
      });

      return R.compose(
        R.set(comments, newComments),
        R.set(dataLens, action.data),
        R.set(fetchLens, false),
        R.set(commentsLens, false)
      )(state);
    }
    case "MILZAOCOMMENTS/COMMENTS_DELETE_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingDeleteComments"]);
      return R.compose(R.set(fetchLens, false))(state);
    }

    case "MILZAOCOMMENTS/ANSWERS_DELETE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingDeleteComments"]);
      return R.set(fetchLens, true, state);
    }
    case "MILZAOCOMMENTS/ANSWERS_DELETE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "fetchingDeleteComments"]);
      const dataLens = R.lensPath(["data", "commentDeleted"]);
      const comments = R.lensPath(["data", "comments"]);
      const commentsLens = R.lensPath(["ui", "fetchingComments"]);

      let answerCommentDeleted = state.data.comments.find(it => it._id === action.data.comment.id);
      let otherComments = state.data.comments.filter(it => it._id !== action.data.comment.id);
      answerCommentDeleted.answers = answerCommentDeleted.answers.filter(it => it._id !== action.id);
      const newComments = [answerCommentDeleted, ...otherComments];

      return R.compose(
        R.set(comments, newComments),
        R.set(dataLens, action.data),
        R.set(fetchLens, false),
        R.set(commentsLens, false)
      )(state);
    }

    case "MILZAOCOMMENTS/ANSWERS_DELETE_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingDeleteComments"]);
      return R.compose(R.set(fetchLens, false))(state);
    }

    case "MILZAOCOMMENTS/COMMENTS_UPDATE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingUpdateComments"]);
      return R.compose(R.set(fetchLens, true))(state);
    }

    case "MILZAOCOMMENTS/COMMENTS_UPDATE_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingUpdateComments"]);
      return R.set(fetchLens, false, state);
    }
    case "MILZAOCOMMENTS/COMMENTS_UPDATE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "fetchingUpdateComments"]);
      const comments = R.lensPath(["data", "comments"]);
      const commentsLens = R.lensPath(["ui", "fetchingComments"]);

      const newComments = state.data.comments;
      const indexUpdated = newComments.findIndex(it => it._id === action.id);
      newComments[indexUpdated].text = action.data.text;

      return R.compose(R.set(comments, newComments), R.set(fetchLens, false), R.set(commentsLens, false))(state);
    }

    case "MILZAOCOMMENTS/COMMENTS_LIKE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingLikeComments"]);
      return R.set(fetchLens, true, state);
    }
    case "MILZAOCOMMENTS/COMMENTS_LIKE_SUCCESS": {
      const dataLens = R.lensPath(["data", "comments"]);
      const fetchLens = R.lensPath(["ui", "fetchingLikeComments"]);
      const comments = R.lensPath(["data", "comments"]);

      let c = state.data.comments;

      Promise.all(
        c.map(it => {
          if (it.id === action.data.id) {
            it.isLiked = action.data.like;
            it.likes = action.data.likes;
            it.qtdLikes = action.data.likes.length;
          } else {
            if (it.children && it.children.length) {
              it.children.map(ar => {
                if (ar.id === action.data.id) {
                  ar.isLiked = action.data.like;
                  ar.likes = action.data.likes;
                  ar.qtdLikes = action.data.likes.length;
                }
                return ar;
              });
            }
          }
          return it;
        })
      );

      return R.compose(R.set(comments, c), R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "MILZAOCOMMENTS/COMMENTS_LIKE_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingLikeComments"]);
      return R.compose(R.set(fetchLens, false))(state);
    }

    case "MILZAOCOMMENTS/COMMENTS_INSERT_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingInsertComments"]);
      return R.set(fetchLens, true, state);
    }
    case "MILZAOCOMMENTS/COMMENTS_INSERT_SUCCESS": {
      const dataLens = R.lensPath(["data", "comment"]);
      const fetchLens = R.lensPath(["ui", "fetchingInsertComments"]);
      const comments = R.lensPath(["data", "comments"]);

      let newComments = state.data.comments;

      if (action.data && !action.data.parent) {
        newComments = [action.data, ...newComments];
      } else if (action.data && action.data.parent) {
        newComments.map(it => {
          if (it.id === action.data.parent) {
            it.children = it.children ? [action.data, ...it.children] : [action.data];
          }
          return it;
        });
      }

      return R.compose(R.set(dataLens, action.data), R.set(comments, newComments), R.set(fetchLens, false))(state);
    }
    case "MILZAOCOMMENTS/COMMENTS_INSERT_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingInsertComments"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "MILZAOCOMMENTS/COMMENTS_INSERT_ANSWER_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingInsertComments"]);
      return R.set(fetchLens, true, state);
    }
    case "MILZAOCOMMENTS/COMMENTS_INSERT_ANSWER_SUCCESS": {
      const dataLens = R.lensPath(["data", "comment"]);
      const fetchLens = R.lensPath(["ui", "fetchingInsertComments"]);
      const comments = R.lensPath(["data", "comments"]);

      let newComments = [...state.data.comments].map(comment => {
        if (comment._id === action.data.parent) {
          comment.answers = comment.answers ? [action.data, ...comment.answers] : [action.data];
        }
        return comment;
      });

      return R.compose(R.set(dataLens, action.data), R.set(comments, newComments), R.set(fetchLens, false))(state);
    }
    case "MILZAOCOMMENTS/COMMENTS_INSERT_ANSWER_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingInsertComments"]);
      return R.compose(R.set(fetchLens, false))(state);
    }

    case "MILZAOCOMMENTS/TOGGLE_MODAL_DELETE_COMMENT": {
      const filterLens = R.lensPath(["ui", "isToggleModal"]);
      return R.set(filterLens, action.modal, state);
    }

    case "MILZAOCOMMENTS/TOGGLE_TOAST_COMMENT": {
      const filterLens = R.lensPath(["ui", "isToggleToast"]);
      return R.set(filterLens, action.modal, state);
    }

    case "MILZAOCOMMENTS/SET_ID_COMMENT_MODAL": {
      const filterLens = R.lensPath(["data", "idCommentDelete"]);
      return R.set(filterLens, action.id, state);
    }
    case "MILZAOCOMMENTS/SET_IS_ANSWER_COMMENT_MODAL": {
      const filterLens = R.lensPath(["data", "isAnswerCommentDelete"]);
      return R.set(filterLens, action.bool, state);
    }

    case "MILZAOCOMMENTS/COMMENTS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingComments"]);
      return R.set(fetchLens, true, state);
    }
    case "MILZAOCOMMENTS/COMMENTS_SUCCESS": {
      const dataLens = R.lensPath(["data", "comments"]);
      const fetchLens = R.lensPath(["ui", "fetchingComments"]);
      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "MILZAOCOMMENTS/COMMENTS_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingComments"]);

      return R.compose(R.set(fetchLens, false))(state);
    }

    case "MILZAOCOMMENTS/ANSWER_UPDATE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingUpdateComments"]);
      return R.compose(R.set(fetchLens, true))(state);
    }

    case "MILZAOCOMMENTS/ANSWER_UPDATE_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingUpdateComments"]);
      return R.set(fetchLens, false, state);
    }
    case "MILZAOCOMMENTS/ANSWER_UPDATE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "fetchingUpdateComments"]);
      const comments = R.lensPath(["data", "comments"]);
      const commentsLens = R.lensPath(["ui", "fetchingComments"]);

      const newComments = state.data.comments;
      const indexComment = newComments.findIndex(it => it._id === action.data.updatedId);
      const indexAnswer = newComments[indexComment].answers.findIndex(it => it._id === action.id);
      newComments[indexComment].answers[indexAnswer].text = action.body.text;

      return R.compose(R.set(comments, newComments), R.set(fetchLens, false), R.set(commentsLens, false))(state);
    }

    default:
      return state;
  }
}

export { commentsMilzaoReducer };
