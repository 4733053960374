/* @flow
 */
import * as R from "ramda";
import type { Action } from "../../../types";

type State = {
  ui: {
    fetchingProfile: boolean,
    fetchingUpdateProfile: boolean,
    fetchingInstitutionsCourses: boolean,
    fetchingInstitutions: boolean,
    fetchingEditPassword: boolean,
    fetchingResetData: boolean,
    fetchingCities: boolean,
    isToggleModal: boolean,
    onChangeNewData: boolean,
    fetchingStates: boolean
  },
  data: {
    profile: Array,
    cities: Array,
    institutionscourses: Array,
    institutions: Array,
    editPassword: Array,
    newProfile: Object,
    states: Array
  }
};

const initialState = {
  ui: {
    fetchingProfile: false,
    fetchingUpdateProfile: false,
    fetchingInstitutionsCourses: false,
    fetchingInstitutions: false,
    fetchingCities: false,
    fetchingEditPassword: false,
    fetchingResetData: false,
    isToggleModal: false,
    onChangeNewData: false,
    isUploading: false,
    fetchingUpdateProfilepicture: false,
    fetchingStates: false,
    isCancelSubscription: false
  },
  data: {
    profile: {},
    states: [],
    institutionscourses: [],
    institutions: [],
    editPassword: [],
    newProfile: {},
    cities: []
  }
};

function profileReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case "UPLOAD/UPLOAD_FILE": {
      const fetchLens = R.lensPath(["ui", "isUploading"]);
      return R.set(fetchLens, true, state);
    }
    case "UPLOAD/UPLOAD_FILE_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isUploading"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "UPLOAD/UPLOAD_FILE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isUploading"]);
      const lens = R.lensPath(["data", "file"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }

    case "PROFILE/REQUEST_CANCEL_SUBSCRIPTION": {
      const fetchLens = R.lensPath(["ui", "isCancelSubscription"]);
      return R.set(fetchLens, true, state);
    }
    case "PROFILE/REQUEST_CANCEL_SUBSCRIPTION_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isCancelSubscription"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "PROFILE/REQUEST_CANCEL_SUBSCRIPTION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isCancelSubscription"]);

      if (action.data.ok) state.data.profile.isActiveSubscription = false;

      return R.compose(R.set(fetchLens, false))(state);
    }

    case "PROFILE/TOGGLE_MODAL": {
      const filterLens = R.lensPath(["ui", "isToggleModal"]);
      return R.set(filterLens, action.modal, state);
    }
    case "PROFILE/ON_CHANGE_NEW_VALUE": {
      const lens = R.lensPath(["ui", "onChangeNewData"]);
      const lensData = R.lensPath(["data", "newProfile"]);
      return R.compose(R.set(lens, false), R.set(lensData, action.data))(state);
    }

    case "PROFILE/REQUEST_PROFILE": {
      const lens = R.lensPath(["ui", "fetchingProfile"]);
      return R.set(lens, true, state);
    }

    case "PROFILE/REQUEST_PROFILE_SUCCESS": {
      const lens = R.lensPath(["ui", "fetchingProfile"]);
      const lensData = R.lensPath(["data", "profile"]);
      return R.compose(R.set(lens, false), R.set(lensData, action.data))(state);
    }
    case "PROFILE/REQUEST_PROFILE_FAILURE": {
      const lens = R.lensPath(["ui", "fetchingProfile"]);
      return R.set(lens, false, state);
    }
    case "PROFILE/REQUEST_UPDATE_PROFILE": {
      const lens = R.lensPath(["ui", "fetchingUpdateProfile"]);
      return R.set(lens, true, state);
    }
    case "PROFILE/REQUEST_UPDATE_PROFILE_SUCCESS": {
      const lens = R.lensPath(["ui", "fetchingUpdateProfile"]);
      const updateLens = R.lensPath(["data", "profile", "user"]);

      const merged = R.merge(state.data.profile.user, action.body);

      return R.compose(R.set(lens, false), R.set(updateLens, merged))(state);
    }
    case "PROFILE/REQUEST_UPDATE_PROFILE_FAILURE": {
      const lens = R.lensPath(["ui", "fetchingUpdateProfile"]);
      return R.set(lens, false, state);
    }

    //photo
    case "PROFILE/REQUEST_UPDATE_PROFILE_PICTURE": {
      const lens = R.lensPath(["ui", "fetchingUpdateProfilepicture"]);
      return R.set(lens, true, state);
    }
    case "PROFILE/REQUEST_UPDATE_PROFILE_PICTURE_SUCCESS": {
      const lens = R.lensPath(["ui", "fetchingUpdateProfilepicture"]);

      return R.compose(R.set(lens, false))(state);
    }
    case "PROFILE/REQUEST_UPDATE_PROFILE_PICTURE_FAILURE": {
      const lens = R.lensPath(["ui", "fetchingUpdateProfilepicture"]);
      return R.set(lens, false, state);
    }
    //end phtoo

    case "PROFILE/REQUEST_STATES": {
      const lens = R.lensPath(["ui", "fetchingStates"]);
      return R.set(lens, true, state);
    }
    case "PROFILE/REQUEST_STATES_SUCCESS": {
      const lens = R.lensPath(["ui", "fetchingStates"]);
      const lensData = R.lensPath(["data", "states"]);
      return R.compose(R.set(lens, false), R.set(lensData, action.data))(state);
    }
    case "PROFILE/REQUEST_STATES_FAILURE": {
      const lens = R.lensPath(["ui", "fetchingStates"]);
      return R.set(lens, false, state);
    }

    case "PROFILE/REQUEST_CITIES": {
      const lens = R.lensPath(["ui", "fetchingCities"]);
      return R.set(lens, true, state);
    }
    case "PROFILE/REQUEST_CITIES_SUCCESS": {
      const lens = R.lensPath(["ui", "fetchingCities"]);
      const lensData = R.lensPath(["data", "cities"]);
      return R.compose(R.set(lens, false), R.set(lensData, action.data))(state);
    }
    case "PROFILE/REQUEST_CITIES_FAILURE": {
      const lens = R.lensPath(["ui", "fetchingCities"]);
      return R.set(lens, false, state);
    }

    case "PROFILE/REQUEST_EDIT_PASSWORD": {
      const lens = R.lensPath(["ui", "fetchingEditPassword"]);
      return R.set(lens, true, state);
    }
    case "PROFILE/REQUEST_EDIT_PASSWORD_SUCCESS": {
      const lens = R.lensPath(["ui", "fetchingEditPassword"]);
      const lensData = R.lensPath(["data", "editPassword"]);
      return R.compose(R.set(lens, false), R.set(lensData, action.data))(state);
    }
    case "PROFILE/REQUEST_EDIT_PASSWORD_FAILURE": {
      const lens = R.lensPath(["ui", "fetchingEditPassword"]);
      return R.set(lens, false, state);
    }
    case "RESET/RESET_DATA_REQUEST": {
      const lens = R.lensPath(["ui", "fetchingResetData"]);
      return R.set(lens, true, state);
    }
    case "RESET/RESET_DATA_SUCCESS": {
      const lens = R.lensPath(["ui", "fetchingResetData"]);
      return R.set(lens, false, state);
    }
    case "RESET/RESET_DATA_FAILURE": {
      const lens = R.lensPath(["ui", "fetchingResetData"]);
      return R.set(lens, false, state);
    }
    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}

export default profileReducer;
