const httpInterceptor = (statusHandlers = {}, fetchContext = global) => {
  const _fetch = fetchContext.fetch;

  return createStore => (reducer, initialState, enhancer) => {
    const store = createStore(reducer, initialState, enhancer);

    fetchContext.fetch = async (url, options) => {
      const resp = await _fetch(url, options);
      const maybeHandler = statusHandlers[`${resp.status}`];

      maybeHandler && maybeHandler(store.dispatch, resp);

      return resp;
    };

    return store;
  };
};

export default httpInterceptor;
