export function GetGaUserCode() {
  try {
    const gaUserId = document.cookie
      .match(/_ga=(.+?);/)[1]
      .split(".")
      .slice(-2)
      .join(".");

    if (gaUserId) {
      return gaUserId;
    }

    return null;
  } catch (e) {
    return null;
  }
}

export function GetUserIdCookie(cookieName = "_ldat") {
  try {
    let name = cookieName + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c
          .substring(name.length, c.length)
          .split(".")
          .slice(-2)
          .join(".");
      }
    }
    return "";
  } catch (e) {
    return "";
  }
}
