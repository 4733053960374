import logotipoOpen from "../images/light/logotipo-full.svg";
import logotipoClosed from "../images/light/logotipo-mini.svg";
import logotipoText from "../images/light/logotipo-texto.svg";
import bgSuperior from "../images/light/submenu-superior.png";
import bgBiology from "../images/light/submenu-biology.png";
import bgChemistry from "../images/light/submenu-chemistry.png";
import bgPhysics from "../images/light/submenu-physics.png";
import bgMathematics from "../images/light/submenu-mathematics.png";
import bgHistory from "../images/light/submenu-history.png";
import bgGeography from "../images/light/submenu-geography.png";
import bgPhilosophy from "../images/light/submenu-philosophy.png";
import bgSociology from "../images/light/submenu-sociology.png";
import bgPortuguese from "../images/light/submenu-portuguese.png";
import bgLiterature from "../images/light/submenu-literature.png";
import bgInterdisciplinary from "../images/light/submenu-interdisciplinary.png";
import bgEssay from "../images/light/submenu-essay.png";
import bgLive from "../images/light/submenu-live.png";
import bgCommunity from "../images/light/submenu-community.png";
import bgFavorites from "../images/light/submenu-favorites.png";
import bgEnglish from "../images/light/submenu-english.png";
import bgSpanish from "../images/light/submenu-spanish.png";

export default {
  name: "light",

  colors: {
    bgBtnDisabled: "#dddddd",
    colorBtnDisabled: "#FFFFFF",
    background: "#c41c6d",
    buttonColor: "#2E3440",
    physics: "#00b7ac",
    chemistry: "#c41c6d",
    chemestry: "#c41c6d",
    biology: "#193ba5",
    mathematics: "#6b59a6",
    history: "#AC664E",
    geography: "#05C870",
    philosophy: "#EFA700",
    sociology: "#C9161A",
    portuguese: "#FF6A40",
    literature: "#F7809A",
    essay: "#4495C1",
    interdisciplinary: "#00c5e5",
    english: "#6D8EF2",
    spanish: "#8EA82F",
    live: "#ff8e06",
    community: "#582a5a",
    primary: "#582a5a",
    favorites: "#000C29",
    monitoria: "#078a0c",
    desafio60dias: "#25337B",
    desafio60diasBorder: "#25337B",
    squadLight: "#75592E",
    squadDark: "#382E1E",
    superior: "#3171f1",
    subarea01: "#ff6a40",
    subarea02: "#ff184f",
    subarea03: "#ffbc00",
    subarea04: "#af7dff",
    subarea05: "#13b3ce",
    subarea06: "#ff92aa",
    subarea07: "#592b5b",
    subarea08: "#00c5e5",
    subarea09: "#00b7ac",
    subarea10: "#c41c6d",
    subarea11: "#193ba5",
    subarea12: "#a3c136",
    subarea13: "#0f6c7c",
    subarea14: "#ff8e06",
    loadingBook: "#00c5e5",
    loadingPlay: "#ffd700",
    error: "#db001b",
    alert: "#db001b",
    success: "#44ba3a",
    warning: "#fdd703",
    font1: "#555555",
    font2: "#1B229F",
    font3: "#000C29",
    font4: "#999999",
    font5: "#bbbbbb",
    font6: "#999fa3",
    font7: "#e3e3e3",
    font8: "#333333",
    bg1: "#3171f1",
    bg2: "#000C29",
    bg3: "#eeeeee",
    bg4: "#e3e3e3",
    bg5: "#eeeeee",
    bg6: "#3171f1",
    bg7: "#819BAD",
    bg8: "#bbbbbb",
    transparent: "#ffffff",
    clear: "#ffffff",
    ebony: "#000000",
    vanta: "#000000",
    dimmer: "#cccccc",
    promotion: "#FFBC00",
    titleRules: "#000C29",
    submenuCourses: "#061946",
    regionais: "#133822",
    regionais2: "#133822",
    regionalsFilter: "#e2e3e3",
    regionalsFilterLabel: "#3171F1"
  },

  config: {
    // Menu Lateral
    drawerOpenedWidth: "192px",
    drawerClosedWidth: "48px",
    // Menu Mobile
    drawerMenuMobile: "256px",
    // Header
    headerHeightDesktop: "64px",
    headerHeightMobile: "48px",
    // Submenu
    submenuWidth: "144px",
    submenuHeightMobile: "64px"
  },

  gradients: {
    sexyBlue: "linear-gradient(90deg, #3171f1, #0e98f3)"
  },

  images: {
    logotipoOpen: logotipoOpen,
    logotipoClosed: logotipoClosed,
    logotipoText: logotipoText,
    submenuBiology: bgBiology,
    submenuChemistry: bgChemistry,
    submenuCommunity: bgCommunity,
    submenuFavorite: bgFavorites,
    submenuInterdisciplinary: bgInterdisciplinary,
    submenuEssay: bgEssay,
    submenuLive: bgLive,
    submenuMathematics: bgMathematics,
    submenuHistory: bgHistory,
    submenuGeography: bgGeography,
    submenuPhilosophy: bgPhilosophy,
    submenuSociology: bgSociology,
    submenuPortuguese: bgPortuguese,
    submenuLiterature: bgLiterature,
    submenuPhysics: bgPhysics,
    submenuSuperior: bgSuperior,
    submenuEnglish: bgEnglish,
    submenuSpanish: bgSpanish
  }
};
