import * as R from "ramda";

const initialState = {
  ui: {
    isToggleModal: false,
    isToggleModalFreeTrial: true,
    isGetCPF: false,
    isSetHistory: true
  },
  data: {
    history: [],
    CPF: []
  }
};

function onboardingReducer(state = initialState, action) {
  switch (action.type) {
    case "ONBOARDING/TOGGLE_MODAL_ONBOARDING": {
      const filterLens = R.lensPath(["ui", "isToggleModal"]);
      return R.set(filterLens, action.modal, state);
    }

    case "ONBOARDING/TOGGLE_MODAL_ONBOARDING_FREE_TRIAL": {
      const filterLens = R.lensPath(["ui", "isToggleModalFreeTrial"]);
      return R.set(filterLens, action.modal, state);
    }

    case "ONBOARDING/REQUEST_PROFILE_HISTORY": {
      const lens = R.lensPath(["ui", "isSetHistory"]);
      return R.set(lens, true, state);
    }

    case "ONBOARDING/REQUEST_PROFILE_HISTORY_SUCCESS": {
      const lens = R.lensPath(["ui", "isSetHistory"]);
      const lensData = R.lensPath(["data", "history"]);
      return R.compose(R.set(lens, false), R.set(lensData, action.data))(state);
    }
    case "ONBOARDING/REQUEST_PROFILE_HISTORY_FAILURE": {
      const lens = R.lensPath(["ui", "isSetHistory"]);
      return R.set(lens, false, state);
    }

    case "ONBOARDING/REQUEST_CPF": {
      const lens = R.lensPath(["ui", "isGetCPF"]);
      return R.set(lens, true, state);
    }

    case "ONBOARDING/REQUEST_CPF_SUCCESS": {
      const lens = R.lensPath(["ui", "isGetCPF"]);
      const lensData = R.lensPath(["data", "CPF"]);
      return R.compose(R.set(lens, false), R.set(lensData, action.data))(state);
    }
    case "ONBOARDING/REQUEST_CPF_FAILURE": {
      const lens = R.lensPath(["ui", "isGetCPF"]);
      return R.set(lens, false, state);
    }
    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}

export default onboardingReducer;
