// @flow
import * as R from "ramda";

const buildFilter = filter => {
  let query = {};
  for (let keys in filter) {
    if (filter[keys].constructor === Array && filter[keys].length > 0) {
      query[keys] = filter[keys];
    }
  }
  return query;
};

const filterData = (data, query) => {
  const filteredData = data.filter(item => {
    let stringItemValue = "";

    for (let key in query) {
      stringItemValue = String(item[key]);

      if (stringItemValue === undefined || !query[key].includes(stringItemValue)) {
        return false;
      }
    }
    return true;
  });
  return filteredData;
};

const initialState = {
  ui: {
    isFilterFetching: false,
    isRevisionFetching: false,
    isDeleteModalOpen: false,
    isDeletingRevision: false,
    isRevisionStartFetching: false,
    isRevisionFinishFetching: false,
    isCheckingAnswer: false
  },
  data: {
    filter: [],
    revisions: [],
    filteredRevisions: [],
    revisionIdToDelete: "",
    startedRevisionData: {}
  }
};

const updateExerciseReview = (review, slug, items) =>
  R.map(R.when(R.propEq("slug", slug), R.assoc("review", review)), items);

function revision(state = initialState, action) {
  switch (action.type) {
    case "REVISION/FILTER_REVISION_LIST": {
      const revisionLens = R.lensPath(["data", "filteredRevisions"]);
      const selectedFilterList = action.filterList;

      if (Object.keys(selectedFilterList).length === 0) {
        return R.set(revisionLens, [...state.data.revisions], state);
      } else {
        const query = buildFilter(selectedFilterList);
        const newRevisionList = filterData([...state.data.revisions], query);

        return R.set(revisionLens, newRevisionList, state);
      }
    }
    case "SIMULATOR/LIST_REVISIONS_REQUEST":
    case "REVISION/REVISION_REQUEST": {
      const fetchingLens = R.lensPath(["ui", "isRevisionFetching"]);
      return R.set(fetchingLens, true, state);
    }
    case "SIMULATOR/LIST_REVISIONS_SUCCESS":
    case "REVISION/REVISION_RESPONSE_SUCCESS": {
      const fetchingLens = R.lensPath(["ui", "isRevisionFetching"]);
      const dataRevisionsLens = R.lensPath(["data", "revisions"]);
      const dataFilterLens = R.lensPath(["data", "filter"]);
      const dataFilteredRevisionLens = R.lensPath(["data", "filteredRevisions"]);

      return R.compose(
        R.set(fetchingLens, false),
        R.set(dataRevisionsLens, action.data.revisions),
        R.set(dataFilterLens, action.data.filters),
        R.set(dataFilteredRevisionLens, action.data.revisions)
      )(state);
    }
    case "SIMULATOR/LIST_REVISIONS_ERROR":
    case "REVISION/REVISION_RESPONSE_FAILURE": {
      const fetchingLens = R.lensPath(["ui", "isRevisionFetching"]);
      const lens = R.lensPath(["data"]);
      return R.compose(R.set(fetchingLens, false), R.set(lens, action.data))(state);
    }

    case "REVISION/TOGGLE_DELETE_MODAL": {
      const modalLens = R.lensPath(["ui", "isDeleteModalOpen"]);
      const dataLens = R.lensPath(["data", "revisionIdToDelete"]);

      return R.compose(R.set(modalLens, !state.ui.isDeleteModalOpen), R.set(dataLens, action.revisionId))(state);
    }
    case "SIMULATOR/HIDE_REVISION_REQUEST":
    case "REVISION/REQUEST_DELETE_REVISION": {
      const deletingLens = R.lensPath(["ui", "isDeletingRevision"]);
      return R.set(deletingLens, true, state);
    }
    case "SIMULATOR/HIDE_REVISION_SUCCESS":
    case "REVISION/REVISION_DELETE_SUCCESS": {
      const deletingLens = R.lensPath(["ui", "isDeletingRevision"]);
      const revisionsLens = R.lensPath(["data", "revisions"]);
      const filteredLens = R.lensPath(["data", "filteredRevisions"]);

      const newRevisionList = [...state.data.revisions].filter(revision => revision._id !== action.data._id);
      const newFilteredList = [...state.data.filteredRevisions].filter(revision => revision._id !== action.data._id);

      console.log(newRevisionList);
      console.log(newFilteredList);

      return R.compose(
        R.set(deletingLens, false),
        R.set(revisionsLens, newRevisionList),
        R.set(filteredLens, newFilteredList)
      )(state);
    }
    case "SIMULATOR/HIDE_REVISION_ERROR":
    case "REVISION/REVISION_DELETE_FAILURE": {
      const fetchingLens = R.lensPath(["ui", "isRevisionFetching"]);
      const lens = R.lensPath(["data"]);
      return R.compose(R.set(fetchingLens, false), R.set(lens, action.data))(state);
    }
    case "SIMULATOR/REVISION_QUESTIONS_REQUEST":
    case "REVISION/REVISION_START_REQUEST": {
      const fetchingLens = R.lensPath(["ui", "isRevisionStartFetching"]);

      return R.set(fetchingLens, true, state);
    }
    case "SIMULATOR/REVISION_QUESTIONS_SUCCESS":
    case "REVISION/REVISION_START_SUCCESS": {
      const fetchingLens = R.lensPath(["ui", "isRevisionStartFetching"]);
      const revisionsLens = R.lensPath(["data", "startedRevisionData"]);

      return R.compose(R.set(fetchingLens, false), R.set(revisionsLens, action.data))(state);
    }
    case "SIMULATOR/REVISION_QUESTIONS_ERROR":
    case "REVISION/REVISION_START_FAILURE": {
      const fetchingLens = R.lensPath(["ui", "isRevisionStartFetching"]);

      return R.set(fetchingLens, false, state);
    }
    case "SIMULATOR/CHECK_REVISION_QUESTION_REQUEST":
    case "REVISION/VERIFY_EXERCISE_ANSWER_REQUEST": {
      const checkingLens = R.lensPath(["ui", "isCheckingAnswer"]);

      return R.set(checkingLens, true, state);
    }
    case "SIMULATOR/CHECK_REVISION_QUESTION_SUCCESS":
    case "REVISION/VERIFY_EXERCISE_ANSWER_RESPONSE_SUCCESS": {
      const checkingLens = R.lensPath(["ui", "isCheckingAnswer"]);

      const exerciseIndex = state.data.startedRevisionData.exercises
        .map(exercise => exercise._id)
        .indexOf(action.exerciseId);

      const exerciseLens = R.lensPath(["data", "startedRevisionData", "exercises", exerciseIndex]);

      const newExercise = {
        ...state.data.startedRevisionData.exercises[exerciseIndex],
        answered: true,
        status: action.status ? "correct" : "incorrect"
      };

      return R.compose(R.set(checkingLens, false), R.set(exerciseLens, newExercise))(state);
    }
    case "SIMULATOR/CHECK_REVISION_QUESTION_ERROR":
    case "REVISION/VERIFY_EXERCISE_ANSWER_RESPONSE_FAILURE": {
      const checkingLens = R.lensPath(["ui", "isCheckingAnswer"]);

      return R.set(checkingLens, false, state);
    }
    case "SIMULATOR/FINISH_REVISION_REQUEST":
    case "REVISION/REVISION_FINISH_REQUEST": {
      const fetchingLens = R.lensPath(["ui", "isRevisionFinishFetching"]);

      return R.set(fetchingLens, true, state);
    }
    case "SIMULATOR/FINISH_REVISION_SUCCESS":
    case "REVISION/REVISION_FINISH_SUCCESS": {
      const fetchingLens = R.lensPath(["ui", "isRevisionFinishFetching"]);

      const revisionIndex = state.data.revisions.map(revision => revision._id).indexOf(action.data.id);

      const revisionLens = R.lensPath(["data", "revisions", revisionIndex]);
      const filteredRevisionLens = R.lensPath(["data", "filteredRevisions", revisionIndex]);

      const updatedRevision = {
        ...state.data.revisions[revisionIndex],
        dateToReview: action.data.dateToReview,
        futureRevision: true
      };

      return R.compose(
        R.set(fetchingLens, false),
        R.set(revisionLens, updatedRevision),
        R.set(filteredRevisionLens, updatedRevision)
      )(state);
    }
    case "SIMULATOR/FINISH_REVISION_ERROR":
    case "REVISION/REVISION_FINISH_FAILURE": {
      const fetchingLens = R.lensPath(["ui", "isRevisionFinishFetching"]);

      return R.set(fetchingLens, false, state);
    }

    case "REVISION/CLEAR_STARTED_REVISION_DATA": {
      const revisionsLens = R.lensPath(["data", "startedRevisionData"]);

      return R.set(revisionsLens, {}, state);
    }

    case "REVISION/REVIEW_RESOLUTION_EXERCISE_REQUEST": {
      const lens = R.lensPath(["data", "courses", action.course, "contents", action.content, "targets"]);

      const newExerciseTargets = updateExerciseReview(action.review, action.slug, R.view(lens, state));

      return R.set(lens, newExerciseTargets, state);
    }
    default:
      return state;
  }
}

export default revision;
