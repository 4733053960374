import * as R from "ramda";
import moment from "moment";
import type { Action } from "../../../types";

type State = {
  ui: {
    isFetchingAulasAoVivo: boolean,
    fetchingDeleteQuestions: boolean,
    isFetchingAulasCard: boolean,
    fetchingUpdateQuestions: boolean,
    fetchingSelectQuestions: boolean,
    fetchingSelectCourses: boolean,
    fetchingAulaAoVivoSubscription: boolean,
    fetchingQuestions: boolean,
    fetchingLikeQuestions: boolean,
    fetchingQuestion: boolean,
    fetchingPastMonitorias: boolean,
    fetchingPastMonitoria: boolean,
    selectedFilter: string,
    selectedDay: string,
    selectedHour: string,
    fetchingHistory: boolean,
    isToggleModal: boolean,
    isToggleModalSambatech: boolean,
    isToggleModalMaterial: boolean,
    isToggleNotify: boolean
  },
  data: {
    aulasaovivo: Array<any>,
    aulaaovivo: Array<any>,
    questions: Array<any>,
    courses: Array<any>,
    question: Array<any>,
    aulasCard: Object<any>,
  }
};

const initialState = {
  ui: {
    isFetchingAulasAoVivo: false,
    isFetchingAulasCard: false,
    fetchingDeleteQuestions: false,
    fetchingUpdateQuestions: false,
    fetchingSelectQuestions: false,
    fetchingSelectCourses: false,
    fetchingMonitoria: true,
    fetchingQuestions: false,
    fetchingLikeQuestions: false,
    fetchingAulaAoVivoSubscription: false,
    fetchingQuestion: false,
    fetchingPastMonitorias: false,
    fetchingTimeline: false,
    selectedFilter: "all",
    fetchingPastMonitoria: false,
    isToggleModal: false,
    isToggleModalSambatech: false,
    isToggleModalMaterial: false,
    isToggleNotify: false,
    fetchingHistory: false,
    selectedDay: moment()
      .utc()
      .format("MM/DD/YYYY"),
    selectedHour: moment()
      .utc(-3)
      .format("HH:mm")
  },
  data: {
    aulasaovivo: [],
    aulaaovivo: {},
    aulasCard: {},
    questions: [],
    courses: [],
    timeline: [],
    question: {
      course: "",
      week: "",
      reason: "",
      text: ""
    }
  }
};

function aulasAoVivo(state: State = initialState, action: Action): State {
  switch (action.type) {
    case "AULAAOVIVO/TOGGLE_MODAL_DELETE_QUESTION": {
      const filterLens = R.lensPath(["ui", "isToggleModal"]);

      return R.set(filterLens, action.modal, state);
    }

    case "AULAAOVIVO/TOGGLE_MODAL_MONITORIAS_MATERIAL": {
      const filterLens = R.lensPath(["ui", "isToggleModalMaterial"]);

      return R.set(filterLens, action.modal, state);
    }
    case "AULAAOVIVO/TOGGLE_MODAL_TOKEN_SAMBATECH": {
      const filterLens = R.lensPath(["ui", "isToggleModalSambatech"]);

      return R.set(filterLens, action.modal, state);
    }

    case "AULAAOVIVO/SET_HISTORY": {
      const fetchLens = R.lensPath(["ui", "fetchingHistory"]);
      return R.set(fetchLens, true, state);
    }
    case "AULAAOVIVO/SET_HISTORY_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "fetchingHistory"]);

      return R.compose(R.set(fetchLens, false))(state);
    }
    case "AULAAOVIVO/SET_HISTORY_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingHistory"]);

      return R.compose(R.set(fetchLens, false))(state);
    }

    case "AULAAOVIVO/TOGGLE_NOTIFY": {
      const filterLens = R.lensPath(["ui", "isToggleNotify"]);
      return R.set(filterLens, action.modal, state);
    }

    case "AULAAOVIVO/CLEAN_DETAILS": {
      const fetchLens = R.lensPath(["data", "pastMonitoria"]);
      return R.set(fetchLens, {}, state);
    }

    case "AULAAOVIVO/AULASAOVIVO_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingAulasAoVivo"]);
      return R.set(fetchLens, true, state);
    }
    case "AULAAOVIVO/AULASAOVIVO_SUCCESS": {
      const dataLens = R.lensPath(["data", "aulasaovivo"]);
      const fetchLens = R.lensPath(["ui", "isFetchingAulasAoVivo"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "AULAAOVIVO/AULASAOVIVO_FAILURE": {
      const dataLens = R.lensPath(["data", "aulasaovivo"]);
      const fetchLens = R.lensPath(["ui", "isFetchingAulasAoVivo"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "AULAAOVIVO/TIMELINE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingTimeline"]);
      return R.set(fetchLens, true, state);
    }
    case "AULAAOVIVO/TIMELINE_SUCCESS": {
      const dataLens = R.lensPath(["data", "timeline"]);
      const fetchLens = R.lensPath(["ui", "fetchingTimeline"]);

      let filteredMonitorias = action.data.filter(it => it.events.length > 0);

      return R.compose(R.set(dataLens, filteredMonitorias), R.set(fetchLens, false))(state);
    }
    case "AULAAOVIVO/TIMELINE_FAILURE": {
      const dataLens = R.lensPath(["data", "timeline"]);
      const fetchLens = R.lensPath(["ui", "fetchingTimeline"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "AULAAOVIVO/MONITORIA_LIKE_DISLIKE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingPastMonitoria"]);
      return R.set(fetchLens, true, state);
    }
    case "AULAAOVIVO/MONITORIA_LIKE_DISLIKE_SUCCESS": {
      const dataLens = R.lensPath(["data", "pastMonitoria"]);
      const fetchLens = R.lensPath(["ui", "fetchingPastMonitoria"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "AULAAOVIVO/MONITORIA_LIKE_DISLIKE_FAILURE": {
      const dataLens = R.lensPath(["data", "pastMonitoria"]);
      const fetchLens = R.lensPath(["ui", "fetchingPastMonitoria"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "AULAAOVIVO/QUESTION_LIKE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingLikeQuestions"]);
      return R.set(fetchLens, true, state);
    }
    case "AULAAOVIVO/QUESTION_LIKE_SUCCESS": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingLikeQuestions"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "AULAAOVIVO/QUESTION_LIKE_FAILURE": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingLikeQuestions"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "AULAAOVIVO/QUESTION_DELETE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingDeleteQuestions"]);
      return R.set(fetchLens, true, state);
    }
    case "AULAAOVIVO/QUESTION_DELETE_SUCCESS": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingDeleteQuestions"]);
      const monitoriaLens = R.lensPath(["data", "monitoria", "isQuestionAsked"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false), R.set(monitoriaLens, false))(state);
    }
    case "AULAAOVIVO/QUESTION_DELETE_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingDeleteQuestions"]);

      return R.compose(R.set(fetchLens, false))(state);
    }
    case "AULAAOVIVO/QUESTION_UPDATE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingUpdateQuestions"]);
      return R.set(fetchLens, true, state);
    }
    case "AULAAOVIVO/QUESTION_UPDATE_SUCCESS": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingUpdateQuestions"]);
      const newQuestions = [...state.data.questions];

      const positionEdited = newQuestions.findIndex(question => question.id === action.data.id);
      newQuestions[positionEdited] = action.data;

      return R.compose(R.set(dataLens, newQuestions), R.set(fetchLens, false))(state);
    }
    case "AULAAOVIVO/QUESTION_UPDATE_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingUpdateQuestions"]);

      return R.compose(R.set(fetchLens, false))(state);
    }

    case "AULAAOVIVO/QUESTION_SELECT_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingSelectQuestions"]);
      return R.set(fetchLens, true, state);
    }
    case "AULAAOVIVO/QUESTION_SELECT_SUCCESS": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingSelectQuestions"]);
      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "AULAAOVIVO/QUESTION_SELECT_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingSelectQuestions"]);

      return R.compose(R.set(fetchLens, false))(state);
    }

    case "AULAAOVIVO/COURSE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingSelectCourses"]);
      return R.set(fetchLens, true, state);
    }
    case "AULAAOVIVO/COURSE_SELECT_SUCCESS": {
      const dataLens = R.lensPath(["data", "courses"]);
      const fetchLens = R.lensPath(["ui", "fetchingSelectCourses"]);
      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "AULAAOVIVO/COURSE_SELECT_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingSelectCourses"]);

      return R.compose(R.set(fetchLens, false))(state);
    }

    case "AULAAOVIVO/QUESTIONS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingQuestions"]);
      return R.set(fetchLens, true, state);
    }
    case "AULAAOVIVO/QUESTIONS_SUCCESS": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingQuestions"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "AULAAOVIVO/QUESTIONS_FAILURE": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingQuestions"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "AULAAOVIVO/QUESTION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingQuestion"]);
      return R.set(fetchLens, true, state);
    }
    case "AULAAOVIVO/QUESTION_SUCCESS": {
      const dataLens = R.lensPath(["data", "question"]);
      const monitoriaLens = R.lensPath(["data", "monitoria", "isQuestionAsked"]);
      const dataLensQuestions = R.lensPath(["data", "questions"]);

      const fetchLens = R.lensPath(["ui", "fetchingQuestion"]);
      const newQuestions = [...state.data.questions];
      newQuestions.push(action.data);

      return R.compose(
        R.set(dataLens, action.data),
        R.set(monitoriaLens, true),
        R.set(fetchLens, false),
        R.set(dataLensQuestions, newQuestions)
      )(state);
    }
    case "AULAAOVIVO/QUESTION_FAILURE": {
      const dataLens = R.lensPath(["data", "question"]);
      const fetchLens = R.lensPath(["ui", "fetchingQuestion"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "AULAAOVIVO/MONITORIA_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingAulaAoVivo"]);
      return R.set(fetchLens, true, state);
    }
    case "AULAAOVIVO/MONITORIA_SUCCESS": {
      const dataLens = R.lensPath(["data", "aulaaovivo"]);
      const fetchLens = R.lensPath(["ui", "fetchingAulaAoVivo"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "AULAAOVIVO/MONITORIA_FAILURE": {
      const dataLens = R.lensPath(["data", "aulaaovivo"]);
      const fetchLens = R.lensPath(["ui", "fetchingAulaAoVivo"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "AULAAOVIVO/AULASAOVIVO_SUBSCRIPTION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingAulaAoVivoSubscription"]);
      return R.set(fetchLens, true, state);
    }

    case "AULAAOVIVO/AULASAOVIVO_SUBSCRIPTION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "fetchingAulaAoVivoSubscription"]);
      const dataLens = R.lensPath(["data", "aulaaovivo", "isSubscribed"]);

      return R.compose(R.set(dataLens, true), R.set(fetchLens, false))(state);
    }

    case "AULAAOVIVO/AULASAOVIVO_SUBSCRIPTION_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingAulaAoVivoSubscription"]);
      return R.set(fetchLens, false, state);
    }

    case "AULAAOVIVO/DAY_ACTIVE_CHANGED": {
      const filterLens = R.lensPath(["ui", "selectedDay"]);

      return R.set(filterLens, action.filter, state);
    }

    case "AULAAOVIVO/HOUR_ACTIVE_CHANGED": {
      const filterLens = R.lensPath(["ui", "selectedHour"]);

      return R.set(filterLens, action.filter, state);
    }

    case "AULAAOVIVO/SELECT_AULASAOVIVO": {
      const filterLens = R.lensPath(["data", "aulaaovivo"]);

      return R.set(filterLens, action.event, state);
    }

    case "AULAAOVIVO/SELECT_MONITORIA_QUESTION": {
      const filterLens = R.lensPath(["data", "question"]);

      return R.set(filterLens, action.question, state);
    }

    case "AULAAOVIVO/AULASAOVIVO_CARD_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingAulasCard"]);
      return R.set(fetchLens, true, state);
    }

    case "AULAAOVIVO/AULASAOVIVO_CARD_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingAulasCard"]);
      const dataLens = R.lensPath(["data", "aulasCard"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }

    case "AULAAOVIVO/AULASAOVIVO_CARD_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingAulasCard"]);
      return R.set(fetchLens, false, state);
    }

    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}

export default aulasAoVivo;
