import * as R from "ramda";

const initialState = {
  ui: {
    isFetchingSubareas: false,
    isFetchingExercises: false,
    isFetchingMaterials: false,
    isFetchingLessons: false,
    isFetchingMaterialDetails: false
  },
  data: {
    universities: [],
    exercises: [],
    exercise: {},
    workbooks: [],
    lessons: [],
    cardData: [],
    workbookDetails: []
    // subareas: [],
    // subjects: [],
    // cachedSubareas: {},
    // cachedSubjects: {}
  }
};

function RegionalsReducer(state = initialState, action) {
  switch (action.type) {
    case "REGIONALS/SUBAREAS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubareas"]);
      return R.set(fetchLens, true, state);
    }
    case "REGIONALS/SUBAREAS_REQUEST_SUCCESS": {
      const dataLens = R.lensPath(["data", "cardData"]);
      const fetchLens = R.lensPath(["ui", "isFetchingSubareas"]);
      return R.compose(R.set(fetchLens, false), R.set(dataLens, action.data))(state);
    }
    case "REGIONALS/SUBAREAS_REQUEST_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubareas"]);
      return R.set(fetchLens, false, state);
    }
    case "REGIONALS/EXERCISES_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingExercises"]);
      return R.set(fetchLens, true, state);
    }
    case "REGIONALS/EXERCISES_REQUEST_SUCCESS": {
      const dataLens = R.lensPath(["data", "exercises"]);
      const fetchLens = R.lensPath(["ui", "isFetchingExercises"]);
      return R.compose(R.set(fetchLens, false), R.set(dataLens, action.data))(state);
    }
    case "REGIONALS/EXERCISES_REQUEST_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingExercises"]);
      return R.set(fetchLens, false, state);
    }
    case "REGIONALS/SLUG_EXERCISE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingExercises"]);
      return R.set(fetchLens, true, state);
    }
    case "REGIONALS/SLUG_EXERCISE_REQUEST_SUCCESS": {
      const dataLens = R.lensPath(["data", "exercise"]);
      const fetchLens = R.lensPath(["ui", "isFetchingExercises"]);
      return R.compose(R.set(fetchLens, false), R.set(dataLens, action.data))(state);
    }
    case "REGIONALS/SLUG_EXERCISE_REQUEST_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingExercises"]);
      return R.set(fetchLens, false, state);
    }
    case "REGIONALS/MATERIALS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingMaterials"]);
      return R.set(fetchLens, true, state);
    }
    case "REGIONALS/MATERIALS_REQUEST_SUCCESS": {
      const dataLens = R.lensPath(["data", "workbooks"]);
      const fetchLens = R.lensPath(["ui", "isFetchingMaterials"]);
      return R.compose(R.set(fetchLens, false), R.set(dataLens, action.data))(state);
    }
    case "REGIONALS/MATERIALS_REQUEST_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingMaterials"]);
      return R.set(fetchLens, false, state);
    }
    case "REGIONALS/MATERIAL_DETAILS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingMaterialDetails"]);
      return R.set(fetchLens, true, state);
    }
    case "REGIONALS/MATERIAL_DETAILS_REQUEST_SUCCESS": {
      const dataLens = R.lensPath(["data", "workbookDetails"]);
      const fetchLens = R.lensPath(["ui", "isFetchingMaterialDetails"]);
      return R.compose(R.set(fetchLens, false), R.set(dataLens, action.data))(state);
    }
    case "REGIONALS/MATERIAL_DETAILS_REQUEST_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingMaterialDetails"]);
      return R.set(fetchLens, false, state);
    }
    case "REGIONALS/LESSONS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingLessons"]);
      return R.set(fetchLens, true, state);
    }
    case "REGIONALS/LESSONS_REQUEST_SUCCESS": {
      const dataLens = R.lensPath(["data", "lessons"]);
      const fetchLens = R.lensPath(["ui", "isFetchingLessons"]);
      return R.compose(R.set(fetchLens, false), R.set(dataLens, action.data))(state);
    }
    case "REGIONALS/LESSONS_REQUEST_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingLessons"]);
      return R.set(fetchLens, false, state);
    }
    case "REGIONALS/RELATED_CONTENT_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingRelatedContent"]);
      return R.set(fetchLens, true, state);
    }
    case "REGIONALS/RELATED_CONTENT_REQUEST_SUCCESS": {
      const dataLens = R.lensPath(["data", "relatedContent"]);
      const fetchLens = R.lensPath(["ui", "isFetchingRelatedContent"]);
      return R.compose(R.set(fetchLens, false), R.set(dataLens, action.data))(state);
    }
    case "REGIONALS/RELATED_CONTENT_REQUEST_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingRelatedContent"]);
      return R.set(fetchLens, false, state);
    }
    case "REGIONALS/SLUG_LESSON_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingLesson"]);
      return R.set(fetchLens, true, state);
    }
    case "REGIONALS/SLUG_LESSON_REQUEST_SUCCESS": {
      const dataLens = R.lensPath(["data", "lesson"]);
      const fetchLens = R.lensPath(["ui", "isFetchingLesson"]);
      return R.compose(R.set(fetchLens, false), R.set(dataLens, action.data))(state);
    }
    case "REGIONALS/SLUG_LESSON_REQUEST_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingLesson"]);
      return R.set(fetchLens, false, state);
    }
    default:
      return state;
  }
}

export default RegionalsReducer;
