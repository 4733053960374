import * as R from "ramda";

const defaultFetched = {
  lesson: false,
  exercise: false,
  practicetest: false,
  workbook: false,
  podcast: false,
  mindmap: false,
  subjectLesson: false
};

const initialState = {
  ui: {
    isFetchingSeenArea: false,
    isFetchingCountArea: false,
    isFetchingSubareas: false,
    isFetchingSubjects: false,
    isFetchingSubarea: false,
    isFetchingSeenSubjects: false,

    hasFetchedSeen: defaultFetched,
    hasFetchedCount: defaultFetched
  },
  data: {
    subareas: [],
    subjects: [],
    subjectsBySubarea: [],
    subjectsSeen: [],
    cachedSubareas: {},
    cachedSubjects: {}
  }
};

//TODO testar essa logica de planos, warnings e erros
function subareasReducer(state = initialState, action) {
  switch (action.type) {
    case "SUBAREAS/SEEN_COUNT_AREA_CONTENT_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSeenArea"]);

      return R.set(fetchLens, true, state);
    }
    case "SUBAREAS/SEEN_COUNT_AREA_CONTENT_ERROR": {
      return state;
    }
    case "SUBAREAS/SEEN_COUNT_AREA_CONTENT_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSeenArea"]);
      const subareasLens = R.lensPath(["data", "subareas"]);
      const hasFetchedSeen = R.lensPath(["ui", "hasFetchedSeen", action.contentType]);
      
      const subareasWithCount = state.data.subareas.map(it => {
        return {
          ...it,
          seen: {
            ...it.seen,
            [action.contentType]: action.counts[it.id] || 0
          }
        };
      });

      return R.compose(
        R.set(fetchLens, false),
        R.set(subareasLens, subareasWithCount),
        R.set(hasFetchedSeen, true)
      )(state);
    }

    case "SUBAREAS/COUNT_AREA_CONTENT_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingCountArea"]);

      return R.set(fetchLens, true, state);
    }
    case "SUBAREAS/COUNT_AREA_CONTENT_ERROR": {
      return state;
    }
    case "SUBAREAS/COUNT_AREA_CONTENT_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingCountArea"]);
      const subareasLens = R.lensPath(["data", "subareas"]);
      const hasFetchedCount = R.lensPath(["ui", "hasFetchedCount", action.contentType]);

      const subareasWithCount = state.data.subareas.map(it => {
        return {
          ...it,
          contentCount: {
            ...it.contentCount,
            [action.contentType]: action.counts[it.id] || 0
          }
        };
      });

      return R.compose(
        R.set(fetchLens, false),
        R.set(subareasLens, subareasWithCount),
        R.set(hasFetchedCount, true)
      )(state);
    }
    case "SUBAREAS/SUBAREAS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubareas"]);
      const hasFetchedSeen = R.lensPath(["ui", "hasFetchedSeen"]);
      const hasFetchedCount = R.lensPath(["ui", "hasFetchedCount"]);

      return R.compose(
        R.set(fetchLens, true),
        R.set(hasFetchedCount, defaultFetched),
        R.set(hasFetchedSeen, defaultFetched)
      )(state);
    }
    case "SUBAREAS/SUBAREAS_FAILURE":
    case "SUBAREAS/SUBAREAS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubareas"]);
      return R.set(fetchLens, false, state);
    }
    case "SUBAREAS/SUBAREAS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubareas"]);
      const subareasLens = R.lensPath(["data", "subareas"]);

      const subareasCache = R.lensPath(["data", "cachedSubareas"]);

      const cached = {
        ...state.data.cachedSubareas,
        [action.slug]: action.data
      };

      return R.compose(R.set(fetchLens, false), R.set(subareasLens, action.data), R.set(subareasCache, cached))(state);
    }
    case "SUBAREAS/SUBAREA_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubarea"]);
      return R.set(fetchLens, true, state);
    }
    case "SUBAREAS/SUBAREA_FAILURE":
    case "SUBAREAS/SUBAREA_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubarea"]);
      return R.set(fetchLens, false, state);
    }
    case "SUBAREAS/SUBAREA_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubarea"]);
      const subareasLens = R.lensPath(["data", "subareas"]);
      const subarea = action.data[0];
      const subareas = state.data.subareas;
      let iten = subareas.filter(it => it.slug === subarea.slug);
      if (
        (subareas && subarea && subarea.seen.exercises !== iten[0].seen.exercises) ||
        subarea.seen.lessons !== iten[0].seen.lessons ||
        subarea.seen.mindmaps !== iten[0].seen.mindmaps ||
        subarea.seen.podcasts !== iten[0].seen.podcasts ||
        subarea.seen.practicetests !== iten[0].seen.practicetests ||
        subarea.seen.workbooks !== iten[0].seen.workbooks
      ) {
        let index = subareas.indexOf(iten[0]);
        if (index) subareas[index] = subarea;
      }
      return R.compose(R.set(fetchLens, false), R.set(subareasLens, subareas))(state);
    }
    case "SUBAREAS/INCREMENT_SUBAREA_SEEN_COUNT": {
      if (!state.data.subareas.length) {
        return state;
      }

      const subareasLens = R.lensPath(["data", "subareas"]);
      const newSubareas = state.data.subareas.map(subarea => {
        if (subarea.slug !== action.subareaSlug) {
          return subarea;
        }
        const seen = subarea.seen || {};

        return {
          ...subarea,
          seen: {
            ...seen,
            [action.contentType]: (seen[action.contentType] || 0) + action.increment
          }
        };
      });

      return R.compose(R.set(subareasLens, newSubareas))(state);
    }
    case "SUBAREAS/SUBJECTS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubjects"]);
      return R.set(fetchLens, true, state);
    }

    case "SUBAREAS/SUBJECTS_FAILURE":
    case "SUBAREAS/SUBJECTS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubjects"]);
      return R.set(fetchLens, false, state);
    }
    case "SUBAREAS/SUBJECTS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubjects"]);
      const subareasLens = R.lensPath(["data", "subjects"]);
      const cachedSubjectsLens = R.lensPath(["data", "cachedSubjects", action.slug]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(subareasLens, action.data),
        R.set(cachedSubjectsLens, action.data)
      )(state);
    }
    case "SUBAREAS/SUBJECTS_BY_SUBAREA_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubjects"]);
      return R.set(fetchLens, true, state);
    }
    case "SUBAREAS/SUBJECTS_BY_SUBAREA_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubjects"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SUBAREAS/SUBJECTS_BY_SUBAREA_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubjects"]);
      const lens = R.lensPath(["data", "subjectsBySubarea"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }
    case "SUBAREAS/SUBJECTS_SEEN_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSeenSubjects"]);
      return R.set(fetchLens, true, state);
    }
    case "SUBAREAS/SUBJECTS_SEEN_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingSeenSubjects"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SUBAREAS/SUBJECTS_SEEN_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSeenSubjects"]);
      const lens = R.lensPath(["data", "subjectsSeen"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }
    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}

export default subareasReducer;
