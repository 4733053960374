import React, { Component, lazy, Suspense } from "react";
import { Route, withRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Switch } from 'react-router';
import { Toast } from "ratel-ui";

import config from "../../core/config.js";
import Contexts from "../../core/context";
import { getToken, setToken } from "../../core/token";

import { withTracker } from "../components/withTracker";
import lightTheme from "../themes/light";
import darkTheme from "../themes/dark";
import * as S from "./styles";
import "../app/App.scss";
import { pageViewDispatch } from "../../core/pageview/assine";
import { clickDispatch } from "../../core/clicks/clicks";
import InitApm from "../../core/apm";
import { Helmet } from 'react-helmet';

const App = lazy(() => import("../app/App"));
const AccountRegularRouter = lazy(() => import("../../scenes/account"));
const Welcome = lazy(() => import("../welcome"));
const ConfirmAccount = lazy(() => import("../app/components/confirmAccount"));
const ConfirmEmail = lazy(() => import("../app/components/validateEmail"));
const TermsOfUse = lazy(() => import("../termsOfUse"));
const PrivacyPolicy = lazy(() => import("../privacyPolicy"));
const Affiliates = lazy(() => import("../affiliates"));
const Lgpd = lazy(() => import("../lgpd"));
// const NpsSurvey = lazy(() => import("../npsSurvey/router.js"));

const AppRoute = props => <App {...props} />;
const AccountRegularRoute = props => <AccountRegularRouter {...props} />;
const WelcomeRoute = props => <Welcome {...props} />;
const ConfirmAccountRoute = props => <ConfirmAccount {...props} />;
const ConfirmEmailRoute = props => <ConfirmEmail {...props} />;
const TermsOfUseRoute = props => <TermsOfUse {...props} />;
const PrivacyPolicyRoute = props => <PrivacyPolicy {...props} />;
const AffiliatesRoute = props => <Affiliates {...props} />;
// const NpsSurveyRoute = props => <NpsSurvey {...props} />;
const LgpdRouter = props => <Lgpd {...props} />;

class Root extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.location.pathname !== this.props.location.pathname;
  }

  verifyTokenParam = () => {
    try {
      const { location } = this.props;
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get("token");

      if (token) {
        setToken(token);
        
        // this.props.history.replace("/redirecionamento");

        let urlAtual = new URL(window.location.href);
        urlAtual.searchParams.delete("token");
        window.history.pushState({}, '', urlAtual.href);

        window.location.reload(false);
      }
    } catch (e) {}
  };

  componentDidMount() {
    InitApm();
    this.dispatchClickLogin();
    this.verifyTokenParam();

    this.props.history.listen(({ pathname }) => {
      if (pathname.indexOf("assine") !== -1 || pathname.indexOf("comprar") !== -1) {
        pageViewDispatch();
      }
    });

    const token = getToken();

    if (!!token && this.props.location.pathname === "/") {
      this.props.history.replace("/redirecionamento");
    }
  }

  dispatchClickLogin = () => {
    try {
      const pagesEnabled = ["login-b", "login"];
      const { location } = this.props.history;
      const urlSearch = new URLSearchParams(location.search);

      if (pagesEnabled.includes(urlSearch.get("click"))) {
        clickDispatch(location.search);
      }
    } catch (e) {}
  };

  renderHelmet = () => {
    return (
      <>
        {config.env === "alpha" ?
          <Helmet>
            <meta name="robots" content="noindex"/>
          </Helmet>
        : <></>}
      </>
    )
  }

  render() {
    return (
      <ThemeProvider theme={localStorage.getItem("isDarkMode") === "true" ? darkTheme : lightTheme}>
        <Contexts>
          <S.App className="App">
            {this.renderHelmet()}
            <Suspense fallback={<div />}>
              <Switch>
                <Route path="/bem-vindo" component={WelcomeRoute} />
                <Route path="/superior" component={AppRoute} />
                <Route path="/medio/minha-area" component={AppRoute} />
                <Route path="/redirecionamento" component={AppRoute} />
                <Route path="/logout" component={AppRoute} />
                <Route path="/login" component={AccountRegularRoute} />
                <Route path={["/renovacao/:course",'/renovacao']} component={AccountRegularRoute} />
                <Route path={["/assine/:course", "/assine"]} render={() =>  {
                  window.location.href = "https://checkout.aprovatotal.com.br/lead" +  window.location.search
                  return null;
                }} />
                <Route path="/cadastrar" render={() =>  {
                  window.location.href = "https://checkout.aprovatotal.com.br/lead" +  window.location.search
                  return null;
                }}  />
                <Route path="/nova-senha" component={AccountRegularRoute} />
                <Route path="/recupera-sucesso" component={AccountRegularRoute} />
                <Route path="/recupera/:content" component={AccountRegularRoute} />
                <Route path="/cadastrar-simuladao" component={AccountRegularRoute} />
                <Route path="/cadastrar-esquadrao" component={AccountRegularRoute} />
                <Route path={["/comprar/:course", "/comprar"]} render={() =>  {
                  window.location.href = "https://checkout.aprovatotal.com.br/lead" +  window.location.search
                  return null;
                }} />
                <Route path="/confirma-conta/email/:token" component={ConfirmEmailRoute} />
                <Route path="/confirma-conta/:token" component={ConfirmAccountRoute} />
                <Route path="/termos-de-uso" component={TermsOfUseRoute} />
                <Route path="/politica-de-privacidade" component={PrivacyPolicyRoute} />
                <Route path="/regulamento-do-programa-de-afiliados" component={AffiliatesRoute} />
                {/* <Route path="/pesquisa-nps" component={NpsSurveyRoute} /> */}
                 <Route path="/formulario-lei-geral-de-protecao-de-dados" component={LgpdRouter} />
                <Route component={AppRoute} />
              </Switch>
            </Suspense>
            <Toast.box />
          </S.App>
        </Contexts>
      </ThemeProvider>
    );
  }
}

const trackedRoot = config.env === "production" || config.env === "alpha" ? withTracker(Root) : Root;

export default withRouter(trackedRoot);
