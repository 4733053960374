// @flow
import * as R from "ramda";
import type { Action } from "../../../types";

type State = {
  ui: {
    isFetchingPracticetests: boolean,
    isFetchingPracticetest: boolean,
    isFetchingRanking: boolean,
    isStarting: boolean,
    isFinishing: boolean
  },
  data: {
    practicetests: Array<Object>,
    userPracticetest: Object,
    ranking: Array<Object>
  }
};

const initialState = {
  ui: {
    isFetchingPracticetests: false,
    isFetchingPracticetest: false,
    isFetchingRanking: false,
    isStarting: false,
    isFinishing: false
  },
  data: {
    practicetests: [],
    userPracticetest: {},
    ranking: []
  }
};

function practicetestsReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case "PRACTICETESTS/FINISH_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFinishing"]);
      return R.set(fetchLens, true, state);
    }

    case "PRACTICETESTS/FINISH_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFinishing"]);
      const practicetestSlugLens = R.lensPath(["data", "userPracticetest", action.data.slug, "feedback"]);
      const practicetestScore = R.lensPath(["data", "userPracticetest", action.data.slug, "score"]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(practicetestSlugLens, action.data.feedback),
        R.set(practicetestScore, action.data.score)
      )(state);
    }

    case "PRACTICETESTS/FINISH_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFinishing"]);
      return R.set(fetchLens, false, state);
    }

    case "PRACTICETESTS/REMAKE_REQUEST":
    case "PRACTICETESTS/START_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isStarting"]);
      return R.set(fetchLens, true, state);
    }

    case "PRACTICETESTS/REMAKE_SUCCESS":
    case "PRACTICETESTS/START_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isStarting"]);
      const practicetestSlugLens = R.lensPath(["data", "userPracticetest", action.data.slug]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(practicetestSlugLens, action.data)
      )(state);
    }

    case "PRACTICETESTS/REMAKE_FAILURE":
    case "PRACTICETESTS/START_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isStarting"]);
      return R.set(fetchLens, false, state);
    }

    case "PRACTICETESTS/PRACTICETESTS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingPracticetests"]);
      return R.set(fetchLens, true, state);
    }
    case "PRACTICETESTS/PRACTICETESTS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingPracticetests"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "PRACTICETESTS/PRACTICETESTS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingPracticetests"]);
      const practicetestsLens = R.lensPath(["data", "practicetests"]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(practicetestsLens, action.data)
      )(state);
    }

    case "PRACTICETESTS/PRACTICETEST_USER_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingPracticetest"]);
      return R.set(fetchLens, true, state);
    }
    case "PRACTICETESTS/PRACTICETEST_USER_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingPracticetest"]);
      return R.set(fetchLens, false, state);
    }
    case "PRACTICETESTS/PRACTICETEST_USER_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingPracticetest"]);
      const practicetestSlugLens = R.lensPath(["data", "userPracticetest", action.data.practicetest.slug]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(practicetestSlugLens, action.data)
      )(state);
    }

    case "PRACTICETESTS/PRACTICETEST_DETAILS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingPracticetest"]);
      return R.set(fetchLens, true, state);
    }
    case "PRACTICETESTS/PRACTICETEST_DETAILS_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingPracticetest"]);
      return R.set(fetchLens, false, state);
    }
    case "PRACTICETESTS/PRACTICETEST_DETAILS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingPracticetest"]);
      const practicetestSlugLens = R.lensPath(["data", "userPracticetest", action.data.practicetest.slug]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(practicetestSlugLens, action.data)
      )(state);
    }

    case "PRACTICETESTS/PRACTICETEST_RANKING_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingRanking"]);
      return R.set(fetchLens, true, state);
    }
    case "PRACTICETESTS/PRACTICETEST_RANKING_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingRanking"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "PRACTICETESTS/PRACTICETEST_RANKING_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingRanking"]);
      const rankingLens = R.lensPath(["data", "ranking"]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(rankingLens, action.data)
      )(state);
    }
    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}

export default practicetestsReducer;
