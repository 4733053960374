import * as R from "ramda";
import moment from "moment";

const initialState = {
  ui: {
    isFetchingEvents: false,
    selectedDay: moment().format("MM/DD/YYYY"),
    selectedHour: moment()
      .utc(-3)
      .format("HH:mm")
  },
  data: {
    events: [],
    event: {}
  }
};

function calendarReducer(state = initialState, action) {
  switch (action.type) {
    case "CALENDAR/DAY_ACTIVE_CHANGED": {
      const filterLens = R.lensPath(["ui", "selectedDay"]);

      return R.set(filterLens, action.filter, state);
    }
    case "CALENDAR/SELECT_EVENT": {
      const filterLens = R.lensPath(["data", "event"]);

      return R.set(filterLens, action.event, state);
    }
    case "CALENDAR/EVENTS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingEvents"]);
      return R.set(fetchLens, true, state);
    }
    case "CALENDAR/EVENTS_SUCCESS": {
      const dataLens = R.lensPath(["data", "events"]);
      const fetchLens = R.lensPath(["ui", "isFetchingEvents"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "CALENDAR/EVENTS_FAILURE": {
      const dataLens = R.lensPath(["data", "events"]);
      const fetchLens = R.lensPath(["ui", "isFetchingEvents"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }
    case "CALENDAR/ONBOARDING_SUBSCRIPTION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingOnboardingSubscription"]);
      return R.set(fetchLens, true, state);
    }

    case "CALENDAR/ONBOARDING_SUBSCRIPTION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "fetchingOnboardingSubscription"]);
      const dataLens = R.lensPath(["data", "event", "isSubscribed"]);

      return R.compose(R.set(dataLens, true), R.set(fetchLens, false))(state);
    }

    case "CALENDAR/ONBOARDING_SUBSCRIPTION_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingOnboardingSubscription"]);
      return R.set(fetchLens, false, state);
    }

    default:
      return state;
  }
}

export default calendarReducer;
