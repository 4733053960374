import config from "./config";
import { getToken } from "./token";
import { GetGaUserCode, GetUserIdCookie } from "./getGaCode";

function http(url, method, data, stringify = true) {
  let headers = { Accept: "application/json" };
  let token = getToken();

  if (data) {
    data.uniqueGaId = GetGaUserCode();
    data.userIdCookie = GetUserIdCookie();
  }

  headers = { ...headers, Authorization: "Bearer " + (token || "none") };

  if (stringify) {
    headers = { ...headers, "Content-Type": "application/json" };
  }
  return fetch(`${config.apiUrl}/${url}`, {
    method: method,
    headers,
    body: stringify ? JSON.stringify(data) : data
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      return response;
    })
    .catch(err => {
      return { error: err };
    });
}

function httpAsResponse(url, method, data, stringify = true) {
  let headers = { Accept: "application/json" };
  let token = getToken();

  headers = { ...headers, Authorization: "Bearer " + (token || "none") };

  if (stringify) {
    headers = { ...headers, "Content-Type": "application/json" };
  }
  return fetch(`${config.apiUrl}/${url}`, {
    method: method,
    headers,
    body: stringify ? JSON.stringify(data) : data
  }).catch(err => {
    return { error: err };
  });
}

function auth(url, method, data, stringify = true) {
  let headers = { Accept: "application/json" };
  let token = getToken();

  if (token) {
    headers = { ...headers, Authorization: "Bearer " + token };
  }
  if (stringify) {
    headers = { ...headers, "Content-Type": "application/json" };
  }
  return fetch(`${config.apiUrl}/api/auth/v2${url}`, {
    method: method,
    headers,
    body: stringify ? JSON.stringify(data) : data
  })
    .then(response => response.json())
    .catch(err => {
      return { error: err };
    });
}

export { http, auth, httpAsResponse };
