import { createSelector } from "reselect";
import { createMatchSelector } from "react-router-redux";

const getModules = state => state.squad.data.module;
export const getModuleId = state => {
  const match = createMatchSelector({ path: "/:context/esquadrao/modulos/:id" })(state);

  if (match) {
    return match.params.id;
  }
  return undefined;
};

export const getModuleObject = createSelector([getModules, getModuleId], (module, moduleId) => {
  return module[moduleId];
});

export const getFinishedObject = createSelector([getModuleObject], (course = {}) => {
  return course.finished;
});

export const getSelectedModuleObject = createSelector([getModuleObject], (module = {}) => {
  return module.selectedModule;
});
const getSelectedContentId = createSelector([getModuleObject], (course = {}) => {
  return course.selectedContent;
});

const getModuleContents = createSelector([getModuleObject], (module = {}) => {
  return module.content;
});

export const getSelectedProgramObject = createSelector(
  [getModuleObject, getSelectedModuleObject],
  (module = {}, selectedProgram) => {
    return (module.programs || {})[selectedProgram];
  }
);

export const getSelectedContentObject = createSelector(
  [getModuleContents, getSelectedContentId],
  (contents = {}, selectedContent) => {
    return contents[0];
  }
);
