// @flow
import * as R from "ramda";

const initialState = {
  ui: {
    isOpenSimulationWeek: false,
    isStartingSimulationWeek: false,
    isFinishingSimulationWeek: false,
    isRemakingSimulationWeek: false
  },
  data: {},
  hasSimulationWeek: false
};

function simulationWeek(state = initialState, action) {
  switch (action.type) {
    //VERIFY
    case "SIMULATIONWEEK/SIMULATIONWEEK_VERIFY_RESPONSE_SUCCESS": {
      const lens = R.lensPath(["hasSimulationWeek"]);
      return R.set(lens, action.data, state);
    }

    //LOAD
    case "SIMULATIONWEEK/SIMULATIONWEEK_RESPONSE": {
      const fetchLens = R.lensPath(["ui", "isOpenSimulationWeek"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATIONWEEK/SIMULATIONWEEK_RESPONSE_SUCCESS": {
      const lens = R.lensPath(["data"]);
      const fetchLens = R.lensPath(["ui", "isOpenSimulationWeek"]);
      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }

    // START
    case "SIMULATIONWEEK/SIMULATIONWEEK_START_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isStartingSimulationWeek"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATIONWEEK/SIMULATIONWEEK_START_RESPONSE_FAILURE": {
      const fetchingLens = R.lensPath(["ui", "isStartingSimulationWeek"]);
      return R.set(fetchingLens, false, state);
    }
    case "SIMULATIONWEEK/SIMULATIONWEEK_START_RESPONSE_SUCCESS": {
      const lens = R.lensPath(["data"]);
      const fetchLens = R.lensPath(["ui", "isStartingSimulationWeek"]);
      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }

    // FINISH
    case "SIMULATIONWEEK/SIMULATIONWEEK_FINISH_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFinishingSimulationWeek"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATIONWEEK/SIMULATIONWEEK_FINISH_RESPONSE_FAILURE": {
      const fetchingLens = R.lensPath(["ui", "isFinishingSimulationWeek"]);
      return R.set(fetchingLens, false, state);
    }
    case "SIMULATIONWEEK/SIMULATIONWEEK_FINISH_RESPONSE_SUCCESS": {
      const lens = R.lensPath(["data"]);
      const fetchLens = R.lensPath(["ui", "isFinishingSimulationWeek"]);
      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }

    // REMAKE
    case "SIMULATIONWEEK/SIMULATIONWEEK_REMAKE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isRemakingSimulationWeek"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATIONWEEK/SIMULATIONWEEK_REMAKE_RESPONSE_FAILURE": {
      const fetchingLens = R.lensPath(["ui", "isRemakingSimulationWeek"]);
      return R.set(fetchingLens, false, state);
    }
    case "SIMULATIONWEEK/SIMULATIONWEEK_REMAKE_RESPONSE_SUCCESS": {
      const lens = R.lensPath(["data"]);
      const fetchLens = R.lensPath(["ui", "isRemakingSimulationWeek"]);
      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }
    default:
      return state;
  }
}

export default simulationWeek;
