import * as R from "ramda";
import { SortExercises, SortResolutions } from "../components/doSimulated/auxiliar";

const initialState = {
  ui: {
    isFetchingFilters: false,
    isFetchingAnswers: false,
    isFetchingFeedback: false,
    isFetchingSimulated: false,
    isCreatingSimulation: false,
    isFetchingEssayThemes: false,
    isFinishingSimulation: false,
    isFetchingSimulationInfo: false,
    isFetchingSimulationsList: false,
    isFetchingSimulationDelete: false,
    isFetchingSimulationDetails: false,
    isFetchingEssayThematicAxes: false,
    isSubmittingSimulationAnswers: false
  },
  data: {
    filters: {},
    feedback: {},
    userCourse: {},
    simulation: {},
    simulations: [],
    essayThemes: [],
    simulationInfo: {},
    userSimulations: [],
    deleteSimulation: {},
    essayThematicAxes: []
  }
};

function simulatorReducer(state = initialState, action) {
  switch (action.type) {
    case "SIMULATOR/FILTERS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingFilters"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATOR/FILTERS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingFilters"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "SIMULATOR/FILTERS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingFilters"]);
      const lens = R.lensPath(["data", "filters"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }
    case "SIMULATOR/USER_COURSE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingFilters"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATOR/USER_COURSE_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingFilters"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "SIMULATOR/USER_COURSE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingFilters"]);
      const lens = R.lensPath(["data", "filters"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }
    case "SIMULATOR/ESSAY_THEMES_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingEssayThemes"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATOR/ESSAY_THEMES_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingEssayThemes"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "SIMULATOR/ESSAY_THEMES_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingEssayThemes"]);
      const lens = R.lensPath(["data", "essayThemes"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }
    case "SIMULATOR/CREATE_SIMULATION_REQUEST":
    case "SIMULATOR/CREATE_QUESTION": {
      const fetchLens = R.lensPath(["ui", "isCreatingSimulation"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATOR/CREATE_SIMULATION_ERROR":
    case "SIMULATOR/CREATE_QUESTION_ERROR": {
      const fetchLens = R.lensPath(["ui", "isCreatingSimulation"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "SIMULATOR/CREATE_SIMULATION_SUCCESS":
    case "SIMULATOR/CREATE_QUESTION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isCreatingSimulation"]);
      const lens = R.lensPath(["data", "simulation"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }
    case "SIMULATOR/GET_SIMULATION_INFO_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationInfo"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATOR/GET_SIMULATION_INFO_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationInfo"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "SIMULATOR/GET_SIMULATION_INFO_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationInfo"]);
      const lens = R.lensPath(["data", "simulationInfo"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }
    case "SIMULATOR/SIMULATIONS_LIST_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationsList"]);

      return R.set(fetchLens, true, state);
    }
    case "SIMULATOR/GET_SIMULATION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationDetails"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATOR/GET_SIMULATION_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationDetails"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "SIMULATOR/GET_SIMULATION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationDetails"]);
      const lens = R.lensPath(["data", "simulation"]);

      const data = action.data;

      const sortedData = {
        ...data,
        exercises: SortExercises(data.exercises)
      };

      return R.compose(R.set(fetchLens, false), R.set(lens, sortedData))(state);
    }
    case "SIMULATOR/ANSWER_SIMULATION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingAnswers"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATOR/ANSWER_SIMULATION_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingAnswers"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "SIMULATOR/ANSWER_SIMULATION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingAnswers"]);
      const lens = R.lensPath(["data", "simulation"]);

      let _state = state.data.simulation;
      const _history = _state.history || { answers: [] };

      let questionBody = {
        question: action.data && action.data.question,
        answer: action.data && action.data.answer
      };

      let index = _history.answers.findIndex(item => item.question === questionBody.question);

      if (index === -1) {
        // Caso não tenha encontrado um item com a mesma pergunta, adiciona um novo objeto ao array.
        _history.answers.push(questionBody);
      } else {
        // Caso contrário, atualiza o objeto existente no índice encontrado.
        _history.answers[index].answer = questionBody.answer;
      }

      const newSimulationState = { ..._state, history: _history };

      return R.compose(R.set(fetchLens, false), R.set(lens, newSimulationState))(state);
    }
    case "SIMULATOR/END_SIMULATION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFinishingSimulation"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATOR/END_SIMULATION_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFinishingSimulation"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "SIMULATOR/END_SIMULATION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFinishingSimulation"]);
      const lens = R.lensPath(["data", "simulation"]);

      let _state = state.data.simulation;

      const newSimulationState = { ..._state, finished: true };

      return R.compose(R.set(fetchLens, false), R.set(lens, newSimulationState))(state);
    }
    case "SIMULATOR/FEEDBACK_SIMULATION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingFeedback"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATOR/FEEDBACK_SIMULATION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingFeedback"]);
      const lens = R.lensPath(["data", "feedback"]);
      
      const data = action.data;

      const sortedData = {
        ...data,
        exercises: SortExercises(data.exercises),
        resolutions: SortResolutions(data.resolutions)
      };

      return R.compose(R.set(fetchLens, false), R.set(lens, sortedData))(state);
    }
    case "SIMULATOR/FEEDBACK_SIMULATION_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingFeedback"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "SIMULATOR/ESSAY_THEMATIC_AXES_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingEssayThematicAxes"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATOR/ESSAY_THEMATIC_AXES_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingEssayThematicAxes"]);
      return R.set(fetchLens, false, state);
    }
    case "SIMULATOR/ESSAY_THEMATIC_AXES_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingEssayThematicAxes"]);
      const lens = R.lensPath(["data", "essayThematicAxes"]);

      const formatedOptions = action.data.map(theme => {
        return {
          value: theme.id,
          label: theme.title
        };
      });

      return R.compose(R.set(fetchLens, false), R.set(lens, formatedOptions))(state);
    }
    case "SIMULATOR/USER_SIMULATION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationList"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATOR/USER_SIMULATION_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationList"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "SIMULATOR/USER_SIMULATION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationList"]);
      const lens = R.lensPath(["data", "userSimulations"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }
    case "SIMULATOR/DELETE_SIMULATION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationDelete"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATOR/DELETE_SIMULATION_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationDelete"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "SIMULATOR/DELETE_SIMULATION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationDelete"]);
      const lens = R.lensPath(["data", "deleteSimulation"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }
    case "SIMULATOR/DELETE_SIMULATION_CLEAR": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationDelete"]);
      const lens = R.lensPath(["data", "deleteSimulation"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }
    default:
      return state;
  }
}

export default simulatorReducer;
