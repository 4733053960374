/* @flow
 */
import * as R from "ramda";
import type { Action } from "../../../types";

type State = {
  ui: {
    isFetchingMaterials: boolean,
    isFetchingMaterial: boolean
  },
  data: {
    materials: Array<Object>,
    material: ?Object
  }
};

const initialState = {
  ui: {
    isFetchingMaterials: false,
    isFetchingMaterial: false
  },
  data: {
    materials: [],
    material: {}
  }
};

function materialsReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case "MATERIALS/MATERIALS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingMaterials"]);
      return R.set(fetchLens, true, state);
    }
    case "MATERIALS/MATERIALS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingMaterials"]);
      return R.set(fetchLens, false, state);
    }
    case "MATERIALS/MATERIALS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingMaterials"]);
      const materialsLens = R.lensPath(["data", "materials"]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(materialsLens, action.data)
      )(state);
    }

    case "MATERIALS/MATERIAL_DETAILS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingMaterial"]);
      return R.set(fetchLens, true, state);
    }
    case "MATERIALS/MATERIAL_DETAILS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingMaterial"]);
      return R.set(fetchLens, false, state);
    }
    case "MATERIALS/MATERIAL_DETAILS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingMaterial"]);
      const materialLens = R.lensPath(["data", "material"]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(materialLens, action.data)
      )(state);
    }

    case "MATERIALS/TRACKER_EVENTS_MATERIAL_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingMaterialEvents"]);
      return R.set(fetchLens, true, state);
    }
    case "MATERIALS/TRACKER_EVENTS_MATERIAL_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingMaterialEvents"]);
      return R.set(fetchLens, false, state);
    }
    case "MATERIALS/TRACKER_EVENTS_MATERIAL_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingMaterialEvents"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}

export default materialsReducer;
