import * as R from "ramda";

const initialState = {
  ui: {
    isAlreadyAnswered: false,
    isFetchingNpsSurvey: false
  },
  data: {
    nps: ""
  }
};

function npsSureyReducer(state = initialState, action) {
  switch (action.type) {
    case "NPS_SURVEY/REQUEST_NPS_SURVEY": {
      const lens = R.lensPath(["ui", "isFetchingNpsSurvey"]);
      return R.set(lens, true, state);
    }

    case "NPS_SURVEY/REQUEST_NPS_SURVEY_SUCCESS": {
      const lens = R.lensPath(["ui", "isFetchingNpsSurvey"]);
      const lensData = R.lensPath(["data", "nps"]);
      return R.compose(R.set(lens, false), R.set(lensData, action.data))(state);
    }
    case "NPS_SURVEY/REQUEST_NPS_SURVEY_FAILURE": {
      const fetchingLens = R.lensPath(["ui", "isFetchingNpsSurvey"]);
      const answeredLens = R.lensPath(["ui", "isAlreadyAnswered"]);

      if (action.data === 404) {
        return R.compose(R.set(fetchingLens, false), R.set(answeredLens, true))(state);
      }

      return R.set(fetchingLens, false, state);
    }
    default:
      return state;
  }
}

export default npsSureyReducer;
