// @flow
import React from "react";
//$FlowFixMe
import "ratel-ui/index.css";

import { render } from "react-snapshot";
import "moment/locale/pt-br";

import { ConnectedRouter, routerReducer, routerMiddleware } from "react-router-redux";
import { Root } from "./scenes/_root";

import thunkMiddleware from "redux-thunk";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import reducers from "./core/reducers";
import httpInterceptor from "./core/httpInterceptor";

import "./core/config";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// TROCAR PARA ESSE composeEnhancers CASO QUEIRA LOCALIZAR ONDE A ACTION É CHAMADA PELO REDUXDEVTOOLS
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//   trace: true,
//   traceLimit: 25,
// }) || compose;

const history = createBrowserHistory();
//const loggerMiddleware = createLogger()

const middleware = [
  routerMiddleware(history),
  //  loggerMiddleware,
  thunkMiddleware
];

const store = createStore(
  combineReducers({
    ...reducers,
    router: routerReducer
  }),
  composeEnhancers(
    httpInterceptor({
      "403": (dispatch, response) => dispatch({ type: "APP/TOKEN_EXPIRED" })
    }),
    applyMiddleware(...middleware)
  )
);

export default (state, action) => (action.type === "USER_LOGOUT" ? store(undefined, action) : store(state, action));

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Root />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
