/**
 * Returns an area object
 *
 * @param {string} slug slug of an area.
 * @return {object} Returns area object.
 */

export const GetBaseArea = slug => {
  const areas = [
    {
      id: "5b2186ec9c5aa43084ff7d1a",
      slug: "biologia",
      label: "Biologia",
      ing: "biology",
      icon: "biology",
      order: 0,
      enem: "ciencias-da-natureza-e-suas-tecnologias"
    },
    {
      id: "5b2186ec9c5aa43084ff7d19",
      slug: "fisica",
      label: "Física",
      ing: "physics",
      icon: "physical",
      order: 1,
      enem: "ciencias-da-natureza-e-suas-tecnologias"
    },
    {
      id: "5b2186ec9c5aa43084ff7d18",
      slug: "quimica",
      label: "Química",
      ing: "chemistry",
      icon: "chemestry",
      order: 2,
      enem: "ciencias-da-natureza-e-suas-tecnologias"
    },
    {
      id: "5b2186ec9c5aa43084ff7d1c",
      slug: "matematica",
      label: "Matemática",
      ing: "mathematics",
      icon: "mathematics",
      order: 3,
      enem: "matematica-e-suas-tecnologias"
    },
    {
      id: "5fe9d27135e60c598a36ba79",
      slug: "historia",
      label: "História",
      ing: "history",
      icon: "history",
      order: 4,
      enem: "ciencias-humanas-e-suas-tecnologias"
    },
    {
      id: "5fe9d2a035e60c598a36ba7d",
      slug: "geografia",
      label: "Geografia",
      ing: "geography",
      icon: "geography",
      order: 5,
      enem: "ciencias-humanas-e-suas-tecnologias"
    },
    {
      id: "5fe9d23435e60c598a36ba6e",
      slug: "filosofia",
      label: "Filosofia",
      ing: "philosophy",
      icon: "philosophy",
      order: 6,
      enem: "ciencias-humanas-e-suas-tecnologias"
    },
    {
      id: "5fe9d29435e60c598a36ba7c",
      slug: "sociologia",
      label: "Sociologia",
      ing: "sociology",
      icon: "sociology",
      order: 7,
      enem: "ciencias-humanas-e-suas-tecnologias"
    },
    {
      id: "5fe9d27935e60c598a36ba7a",
      slug: "portugues",
      label: "Português",
      ing: "portuguese",
      icon: "portuguese",
      order: 8,
      enem: "linguagens-codigos-e-suas-tecnologias"
    },
    {
      id: "5fe9d28b35e60c598a36ba7b",
      slug: "literatura",
      label: "Literatura",
      ing: "literature",
      icon: "literature",
      order: 9,
      enem: "linguagens-codigos-e-suas-tecnologias"
    },
    {
      id: "604a59b730958969fcd75a3a",
      slug: "ingles",
      label: "Inglês",
      ing: "english",
      icon: "english",
      order: 10,
      enem: "linguagens-codigos-e-suas-tecnologias"
    },
    {
      id: "604a59bf30958969fcd75a43",
      slug: "espanhol",
      label: "Espanhol",
      ing: "spanish",
      icon: "spanish",
      order: 11,
      enem: "linguagens-codigos-e-suas-tecnologias"
    },
    {
      id: "5cdd651e8941b042eba44241",
      slug: "interdisciplinar",
      label: "Interdisciplinar",
      ing: "interdisciplinary",
      icon: "interdisciplinary",
      order: 12,
      enem: "interdisciplinar"
    }
  ];
  return areas;
};
