import logotipoOpen from "../images/dark/logotipo-full.svg";
import logotipoClosed from "../images/dark/logotipo-mini.svg";
import logotipoText from "../images/dark/logotipo-texto.svg";
import bgSuperior from "../images/dark/submenu-superior.png";
import bgBiology from "../images/dark/submenu-biology.png";
import bgChemistry from "../images/dark/submenu-chemistry.png";
import bgPhysics from "../images/dark/submenu-physics.png";
import bgMathematics from "../images/dark/submenu-mathematics.png";
import bgHistory from "../images/dark/submenu-history.png";
import bgGeography from "../images/dark/submenu-geography.png";
import bgPhilosophy from "../images/dark/submenu-philosophy.png";
import bgSociology from "../images/dark/submenu-sociology.png";
import bgPortuguese from "../images/dark/submenu-portuguese.png";
import bgLiterature from "../images/dark/submenu-literature.png";
import bgInterdisciplinary from "../images/dark/submenu-interdisciplinary.png";
import bgEssay from "../images/dark/submenu-essay.png";
import bgLive from "../images/dark/submenu-live.png";
import bgCommunity from "../images/dark/submenu-community.png";
import bgEnglish from "../images/dark/submenu-english.png";
import bgSpanish from "../images/dark/submenu-spanish.png";

export default {
  name: "dark",

  colors: {
    bgBtnDisabled: "#555555",
    colorBtnDisabled: "#999999",
    background: "#2E3440",
    buttonColor: "#2E3440",
    physics: "#10746A",
    chemistry: "#95285D",
    biology: "#415EB9",
    mathematics: "#604F98",
    history: "#814B38",
    geography: "#03A35B",
    philosophy: "#B98204",
    sociology: "#A31215",
    portuguese: "#E2562E",
    literature: "#DE758C",
    essay: "#317091",
    interdisciplinary: "#13b3ce",
    english: "#637FD5",
    spanish: "#6E8224",
    live: "#BE6416",
    monitoria: "#007805",
    desafio60dias: "#223282",
    desafio60diasBorder: "#B3AFAF",
    community: "#754C77",
    primary: "#754C77",
    favorites: "#003558",
    superior: "#000C29",
    subarea01: "#E3E3E3",
    subarea02: "#E3E3E3",
    subarea03: "#E3E3E3",
    subarea04: "#E3E3E3",
    subarea05: "#E3E3E3",
    subarea06: "#E3E3E3",
    subarea07: "#E3E3E3",
    subarea08: "#E3E3E3",
    subarea09: "#E3E3E3",
    subarea10: "#E3E3E3",
    subarea11: "#E3E3E3",
    subarea12: "#E3E3E3",
    subarea13: "#E3E3E3",
    subarea14: "#E3E3E3",
    loadingBook: "#E3E3E3",
    loadingPlay: "#E3E3E3",
    error: "#AB2434",
    alert: "#E3E3E3",
    success: "#4C8E45",
    warning: "#E2C11E",
    font1: "#E3E3E3",
    font2: "#FFFFFF",
    font3: "#E3E3E3",
    font4: "#BBBBBB",
    font5: "#999999",
    font6: "#CACACA",
    font7: "#e3e3e3",
    font8: "#E3E3E3",
    bg1: "#1B229F",
    bg2: "#1B229F",
    bg3: "#121212",
    bg4: "#1e1e1e",
    bg5: "#eeeeee",
    bg6: "#FFFFFF",
    bg7: "#819BAD", // pegar a cor certa com a nalin
    transparent: "#383838",
    clear: "#ffffff",
    ebony: "#A0A0A0",
    vanta: "#000000",
    dimmer: "#cccccc",
    promotion: "#bbbbbb",
    titleRules: "#E3E3E3",
    submenuCourses: "#061946",
    regionais: "#133822",
    regionais2: "#ffffff",
    regionalsFilter: "#383838",
    regionalsFilterLabel: "#ffffff"
  },

  config: {
    // Menu Lateral
    drawerOpenedWidth: "192px",
    drawerClosedWidth: "48px",
    // Menu Mobile
    drawerMenuMobile: "256px",
    // Header
    headerHeightDesktop: "64px",
    headerHeightMobile: "48px",
    // Submenu
    submenuWidth: "144px",
    submenuHeightMobile: "64px"
  },

  gradients: {
    sexyBlue: "linear-gradient(90deg, #3171f1, #0e98f3)"
  },

  images: {
    logotipoOpen: logotipoOpen,
    logotipoClosed: logotipoClosed,
    logotipoText: logotipoText,
    submenuBiology: bgBiology,
    submenuChemistry: bgChemistry,
    submenuCommunity: bgCommunity,
    submenuInterdisciplinary: bgInterdisciplinary,
    submenuEssay: bgEssay,
    submenuLive: bgLive,
    submenuMathematics: bgMathematics,
    submenuHistory: bgHistory,
    submenuGeography: bgGeography,
    submenuPhilosophy: bgPhilosophy,
    submenuSociology: bgSociology,
    submenuPortuguese: bgPortuguese,
    submenuLiterature: bgLiterature,
    submenuPhysics: bgPhysics,
    submenuSuperior: bgSuperior,
    submenuEnglish: bgEnglish,
    submenuSpanish: bgSpanish
  }
};
