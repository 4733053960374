/**
 * Returns if the user is a subscriber.
 *
 * @param {object} user Object with user information.
 * @param {boolean} contentIsPaid Boolean informing if the content is paid.
 * @param {string} area Area the user is in (eg biology, chemistry...).
 * @return {boolean} if the user is a subscriber.
 */

export function IsSubscriber(user, contentIsPaid, area) {
  if (user.permission === "trial" && !contentIsPaid) {
    return true;
  }
  if (user.isSubscriberEssay && area === "redacao") {
    return true;
  }
  if (user.situation === "subscriber" && area !== "redacao") {
    return true;
  }
  return false;
}
