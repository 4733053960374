import * as R from "ramda";
import { DISCIPLINES } from "../containers/utils";

const cleanExerciseData = {
  averageDay: 1, //%
  correct: 0,
  incorrect: 0,
  total: 0
};

const cleanPracticetestData = {
  averageScore: 0,
  count: 0
};

const cleanPracticetestAreaData = {
  1: cleanPracticetestData,
  2: cleanPracticetestData,
  3: cleanPracticetestData
};

const cleanContentDone = {
  count: 0,
  list: []
};

const initialState = {
  ui: {
    isFetchingUserMetrics: false,
    isFetchingOthersMetrics: false,
    isFetchingRanking: false,
    isFetchingCommunityMVP: false,

    isFetchingUserExerciseMetrics: false,
    isFetchingComparisonExerciseMetrics: false,
    isFetchingComparisonSubareaExerciseMetrics: false,
    isFetchingUserSubareaExerciseMetrics: false,
    isFetchingUserPracticetestMetrics: false,
    isFetchingUserPracticetestAreaMetrics: false,
    isFetchingUserPracticetestSubAreaMetrics: false,
    isFetchingComparisonPracticetestAreaMetrics: false,
    isFetchingComparisonPracticetestSubAreaMetrics: false,
    isFetchingComparisonPracticetestMetrics: false,
    isFetchingUserExerciseDiscursiveMetrics: false,
    isFetchingComparisonExerciseDiscursiveMetrics: false,

    isFetchingContentDone: false
  },
  data: {
    filter: {
      dateFilter: "week",
      comparisonFilter: "none",
      courseFilter: {
        course: "",
        university: ""
      }
    },
    ranking: [],
    communityMVP: [],
    comparisonMetrics: {
      exercises: {
        general: cleanExerciseData,
        discursives: {},
        ...DISCIPLINES.reduce((acc, current) => {
          return {
            ...acc,
            [current.scope]: cleanExerciseData
          };
        }, {})
      },
      practicetests: {
        general: cleanPracticetestAreaData,
        ...DISCIPLINES.reduce((acc, current) => {
          return {
            ...acc,
            [current.scope]: cleanPracticetestAreaData
          };
        }, {})
      }
    },
    userMetrics: {
      exercises: {
        general: cleanExerciseData,
        discursives: {},
        ...DISCIPLINES.reduce((acc, current) => {
          return {
            ...acc,
            [current.scope]: cleanExerciseData
          };
        }, {})
      },
      practicetests: {
        general: cleanPracticetestAreaData,
        ...DISCIPLINES.reduce((acc, current) => {
          return {
            ...acc,
            [current.scope]: cleanPracticetestAreaData
          };
        }, {})
      }
    },
    contentDone: {
      lesson: cleanContentDone,
      workbook: cleanContentDone,
      podcast: cleanContentDone,
      mindmap: cleanContentDone
    }
  }
};

function metricsReducer(state = initialState, action) {
  switch (action.type) {
    case "METRICS/FILTER": {
      const fetchLens = R.lensPath(["data", "filter"]);
      return R.set(fetchLens, action.filter, state);
    }
    case "METRICS/CONTENT_DONE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingContentDone"]);
      return R.set(fetchLens, true, state);
    }
    case "METRICS/CONTENT_DONE_RESPONSE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingContentDone"]);
      const contentDoneLens = R.lensPath(["data", "contentDone", action.path]);
      return R.compose(R.set(fetchLens, false), R.set(contentDoneLens, action.data))(state);
    }
    case "METRICS/CONTENT_DONE_RESPONSE_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingContentDone"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "METRICS/COMPARISON_METRICS_EXERCISE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingComparisonExerciseMetrics"]);

      return R.set(fetchLens, true, state);
    }
    case "METRICS/COMPARISON_METRICS_EXERCISE_RESPONSE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingComparisonExerciseMetrics"]);
      const exerciseLens = R.lensPath(["data", "comparisonMetrics", "exercises", action.scope, action.path]);
      return R.compose(R.set(exerciseLens, action.data), R.set(fetchLens, false))(state);
    }
    case "MATERIALS/COMPARISON_METRICS_EXERCISE_RESPONSE_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingComparisonExerciseMetrics"]);

      return R.set(fetchLens, false, state);
    }

    case "METRICS/COMPARISON_METRICS_EXERCISE_DISCURSIVE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingComparisonExerciseDiscursiveMetrics"]);

      return R.set(fetchLens, true, state);
    }
    case "METRICS/COMPARISON_METRICS_EXERCISE_DISCURSIVE_RESPONSE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingComparisonExerciseDiscursiveMetrics"]);
      const exerciseLens = R.lensPath([
        "data",
        "comparisonMetrics",
        "exercises",
        "discursives",
        action.scope,
        action.path
      ]);
      return R.compose(R.set(exerciseLens, action.data), R.set(fetchLens, false))(state);
    }
    case "MATERIALS/COMPARISON_METRICS_EXERCISE_DISCURSIVE_RESPONSE_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingComparisonExerciseDiscursiveMetrics"]);

      return R.set(fetchLens, false, state);
    }

    case "METRICS/COMPARISON_SUBAREA_METRICS_EXERCISE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingComparisonSubareaExerciseMetrics"]);

      return R.set(fetchLens, true, state);
    }
    case "METRICS/COMPARISON_SUBAREA_METRICS_EXERCISE_RESPONSE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingComparisonSubareaExerciseMetrics"]);
      const exerciseLens = R.lensPath(["data", "comparisonSubareaMetrics", "exercises", action.scope, action.path]);
      return R.compose(R.set(exerciseLens, action.data), R.set(fetchLens, false))(state);
    }
    case "MATERIALS/COMPARISON_SUBAREA_METRICS_EXERCISE_RESPONSE_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingComparisonSubareaExerciseMetrics"]);

      return R.set(fetchLens, false, state);
    }

    case "METRICS/USER_SUBAREA_METRICS_EXERCISE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingUserSubareaExerciseMetrics"]);

      return R.set(fetchLens, true, state);
    }
    case "METRICS/USER_SUBAREA_METRICS_EXERCISE_RESPONSE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingUserSubareaExerciseMetrics"]);
      const exerciseLens = R.lensPath(["data", "userSubareaMetrics", "exercises", action.scope, action.path]);
      return R.compose(R.set(exerciseLens, action.data), R.set(fetchLens, false))(state);
    }
    case "MATERIALS/USER_SUBAREA_METRICS_EXERCISE_RESPONSE_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingUserSubareaExerciseMetrics"]);

      return R.set(fetchLens, false, state);
    }

    case "METRICS/USER_METRICS_EXERCISE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingUserExerciseMetrics"]);

      return R.set(fetchLens, true, state);
    }
    case "METRICS/USER_METRICS_EXERCISE_RESPONSE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingUserExerciseMetrics"]);
      const exerciseLens = R.lensPath(["data", "userMetrics", "exercises", action.scope, action.path]);

      return R.compose(R.set(exerciseLens, action.data), R.set(fetchLens, false))(state);
    }
    case "MATERIALS/USER_METRICS_EXERCISE_RESPONSE_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingUserExerciseMetrics"]);

      return R.set(fetchLens, false, state);
    }

    case "METRICS/USER_METRICS_EXERCISE_DISCURSIVE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingUserExerciseDiscursiveMetrics"]);

      return R.set(fetchLens, true, state);
    }
    case "METRICS/USER_METRICS_EXERCISE_DISCURSIVE_RESPONSE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingUserExerciseDiscursiveMetrics"]);
      const exerciseLens = R.lensPath(["data", "userMetrics", "exercises", "discursives", action.scope, action.path]);

      return R.compose(R.set(exerciseLens, action.data), R.set(fetchLens, false))(state);
    }
    case "MATERIALS/USER_METRICS_EXERCISE_DISCURSIVE_RESPONSE_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingUserExerciseDiscursiveMetrics"]);

      return R.set(fetchLens, false, state);
    }

    case "METRICS/USER_METRICS_PRACTICETEST_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingUserPracticetestMetrics"]);

      return R.set(fetchLens, true, state);
    }
    case "METRICS/USER_METRICS_PRACTICETEST_RESPONSE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingUserPracticetestMetrics"]);
      const practicetestLens = R.lensPath(["data", "userMetrics", "practicetests", action.scope, action.path]);

      return R.compose(R.set(practicetestLens, action.data), R.set(fetchLens, false))(state);
    }

    case "METRICS/USER_METRICS_PRACTICETEST_AREA_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingUserPracticetestAreaMetrics"]);

      return R.set(fetchLens, true, state);
    }
    case "METRICS/USER_METRICS_PRACTICETEST_AREA_RESPONSE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingUserPracticetestAreaMetrics"]);
      const practicetestLens = R.lensPath(["data", "userMetricsArea", "practicetests", action.scope, action.path]);

      return R.compose(R.set(practicetestLens, action.data), R.set(fetchLens, false))(state);
    }

    case "METRICS/USER_METRICS_PRACTICETEST_SUBAREA_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingUserPracticetestSubAreaMetrics"]);

      return R.set(fetchLens, true, state);
    }
    case "METRICS/USER_METRICS_PRACTICETEST_SUBAREA_RESPONSE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingUserPracticetestSubAreaMetrics"]);
      const practicetestLens = R.lensPath(["data", "userMetricsSubArea", "practicetests", action.scope, action.path]);

      return R.compose(R.set(practicetestLens, action.data), R.set(fetchLens, false))(state);
    }

    case "METRICS/COMPARISON_METRICS_PRACTICETEST_AREA_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingComparisonPracticetestAreaMetrics"]);

      return R.set(fetchLens, true, state);
    }
    case "METRICS/COMPARISON_METRICS_PRACTICETEST_AREA_RESPONSE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingComparisonPracticetestAreaMetrics"]);
      const practicetestLens = R.lensPath([
        "data",
        "comparisonMetricsArea",
        "practicetests",
        action.scope,
        action.path
      ]);

      return R.compose(R.set(practicetestLens, action.data), R.set(fetchLens, false))(state);
    }

    case "METRICS/COMPARISON_METRICS_PRACTICETEST_SUBAREA_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingComparisonPracticetestSubAreaMetrics"]);

      return R.set(fetchLens, true, state);
    }
    case "METRICS/COMPARISON_METRICS_PRACTICETEST_SUBAREA_RESPONSE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingComparisonPracticetestSubAreaMetrics"]);
      const practicetestLens = R.lensPath([
        "data",
        "comparisonMetricsSubArea",
        "practicetests",
        action.scope,
        action.path
      ]);

      return R.compose(R.set(practicetestLens, action.data), R.set(fetchLens, false))(state);
    }

    case "METRICS/COMPARISON_METRICS_PRACTICETEST_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingComparisonPracticetestMetrics"]);

      return R.set(fetchLens, true, state);
    }
    case "METRICS/COMPARISON_METRICS_PRACTICETEST_RESPONSE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingComparisonPracticetestMetrics"]);
      const practicetestLens = R.lensPath(["data", "comparisonMetrics", "practicetests", action.scope, action.path]);

      return R.compose(R.set(practicetestLens, action.data), R.set(fetchLens, false))(state);
    }
    case "METRICS/RANKING_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingRanking"]);
      return R.set(fetchLens, true, state);
    }

    case "METRICS/RANKING_RESPONSE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingRanking"]);
      const rankingLens = R.lensPath(["data", "ranking", action.dateFilter]);
      return R.compose(R.set(rankingLens, action.data), R.set(fetchLens, false))(state);
    }

    case "METRICS/COMMUNITY_MVP_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingCommunityMVP"]);
      return R.set(fetchLens, true, state);
    }
    case "METRICS/COMMUNITY_MVP_RESPONSE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingCommunityMVP"]);
      const communityMVPLens = R.lensPath(["data", "communityMVP"]);
      return R.compose(R.set(communityMVPLens, action.data), R.set(fetchLens, false))(state);
    }
    default:
      return state;
  }
}

export default metricsReducer;
