import React, { useState, useEffect } from "react";

export const PlansContext = React.createContext({
  plansFromJSON: null,
  setPlansFromJSON: () => {}
});

const PlansProvider = ({ children }) => {
  // useEffect(() => {
  //   fetchPlans();
  // }, []);

  // async function fetchPlans() {
  //   try {
  //     let response = await fetch("https://storage.aprovatotal.com.br/platform-storage-prod/planos.json");
  //     response = await response.json();
  //     setPlansFromJSON(response);
  //   } catch (e) {}
  // }

  const [plansFromJSON, setPlansFromJSON] = useState(null);
  return <PlansContext.Provider value={{ plansFromJSON }}>{children}</PlansContext.Provider>;
};

export default function Contexts({ children }) {
  return <PlansProvider>{children}</PlansProvider>;
}
