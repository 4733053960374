/* @flow
 */
import * as R from "ramda";
import type { Action } from "../../../../types";

type State = {|
  ui: {
    fetchingComments: boolean,
    fetchingLikeComments: boolean,
    fetchingInsertComments: boolean,
    fetchingDeleteComments: boolean,
    isToggleModal: boolean,
    isToggleToast: boolean
  },
  data: {
    comments: Array<any>,
    comment: Array<any>,
    commentDeleted: Array<any>,
    idCommentDelete: string
  }
|};

const initialState = {
  ui: {
    fetchingComments: false,
    fetchingLikeComments: false,
    fetchingInsertComments: false,
    fetchingInsertMilzaoComments: false,
    fetchingDeleteComments: false,
    isToggleModal: false,
    isToggleToast: false
  },
  data: {
    comments: [],
    comment: [],
    commentDeleted: [],
    idCommentDelete: ""
  }
};

function commentsReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case "COMMENTS/COMMENTS_DELETE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingDeleteComments"]);
      return R.set(fetchLens, true, state);
    }
    case "COMMENTS/COMMENTS_DELETE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "fetchingDeleteComments"]);
      const dataLens = R.lensPath(["data", "commentDeleted"]);
      const comments = R.lensPath(["data", "comments"]);
      const commentsLens = R.lensPath(["ui", "fetchingComments"]);

      let newComments = state.data.comments.filter(it => !action.data.ids.includes(it.id));

      newComments.map(it => {
        if (it.children && it.children.length) it.children = it.children.filter(it => !action.data.ids.includes(it.id));
        return it;
      });

      return R.compose(
        R.set(comments, newComments),
        R.set(dataLens, action.data),
        R.set(fetchLens, false),
        R.set(commentsLens, false)
      )(state);
    }
    case "COMMENTS/COMMENTS_DELETE_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingDeleteComments"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "COMMENTS/COMMENTS_LIKE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingLikeComments"]);
      return R.set(fetchLens, true, state);
    }
    case "COMMENTS/COMMENTS_LIKE_SUCCESS": {
      const dataLens = R.lensPath(["data", "comments"]);
      const fetchLens = R.lensPath(["ui", "fetchingLikeComments"]);
      const comments = R.lensPath(["data", "comments"]);

      let c = state.data.comments;

      Promise.all(
        c.map(it => {
          if (it.id === action.data.id) {
            it.isLiked = action.data.like;
            it.likes = action.data.likes;
            it.qtdLikes = action.data.likes.length;
          } else {
            if (it.children && it.children.length) {
              it.children.map(ar => {
                if (ar.id === action.data.id) {
                  ar.isLiked = action.data.like;
                  ar.likes = action.data.likes;
                  ar.qtdLikes = action.data.likes.length;
                }
                return ar;
              });
            }
          }
          return it;
        })
      );

      return R.compose(R.set(comments, c), R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "COMMENTS/COMMENTS_LIKE_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingLikeComments"]);
      return R.compose(R.set(fetchLens, false))(state);
    }

    case "COMMENTS/COMMENTS_INSERT_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingInsertComments"]);
      return R.set(fetchLens, true, state);
    }
    case "COMMENTS/COMMENTS_INSERT_SUCCESS": {
      const dataLens = R.lensPath(["data", "comment"]);
      const fetchLens = R.lensPath(["ui", "fetchingInsertComments"]);
      const comments = R.lensPath(["data", "comments"]);

      let newComments = state.data.comments;

      if (action.data && !action.data.parent) {
        newComments = [action.data, ...newComments];
      } else if (action.data && action.data.parent) {
        newComments.map(it => {
          if (it.id === action.data.parent) {
            it.children = it.children ? [action.data, ...it.children] : [action.data];
          }
          return it;
        });
      }

      return R.compose(R.set(dataLens, action.data), R.set(comments, newComments), R.set(fetchLens, false))(state);
    }
    case "COMMENTS/COMMENTS_INSERT_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingInsertComments"]);
      return R.compose(R.set(fetchLens, false))(state);
    }

    case "COMMENTS/COMMENTS_INSERT_MILZAO_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingInsertMilzaoComments"]);
      return R.set(fetchLens, true, state);
    }
    case "COMMENTS/COMMENTS_INSERT_MILZAO_SUCCESS": {
      const dataLens = R.lensPath(["data", "comment"]);
      const fetchLens = R.lensPath(["ui", "fetchingInsertMilzaoComments"]);
      const comments = R.lensPath(["data", "comments"]);

      let newComments = state.data.comments;

      if (action.data && !action.data.parent) {
        newComments = [action.data, ...newComments];
      } else if (action.data && action.data.parent) {
        newComments.map(it => {
          if (it.id === action.data.parent) {
            it.children = it.children ? [action.data, ...it.children] : [action.data];
          }
          return it;
        });
      }

      return R.compose(R.set(dataLens, action.data), R.set(comments, newComments), R.set(fetchLens, false))(state);
    }
    case "COMMENTS/COMMENTS_INSERT_MILZAO_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingInsertMilzaoComments"]);
      return R.compose(R.set(fetchLens, false))(state);
    }

    case "COMMENTS/TOGGLE_MODAL_DELETE_COMMENT": {
      const filterLens = R.lensPath(["ui", "isToggleModal"]);
      return R.set(filterLens, action.modal, state);
    }

    case "COMMENTS/TOGGLE_TOAST_COMMENT": {
      const filterLens = R.lensPath(["ui", "isToggleToast"]);
      return R.set(filterLens, action.modal, state);
    }

    case "COMMENTS/SET_ID_COMMENT_MODAL": {
      const filterLens = R.lensPath(["data", "idCommentDelete"]);
      return R.set(filterLens, action.id, state);
    }

    case "COMMENTS/COMMENTS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingComments"]);
      return R.set(fetchLens, true, state);
    }
    case "COMMENTS/COMMENTS_SUCCESS": {
      const dataLens = R.lensPath(["data", "comments"]);
      const fetchLens = R.lensPath(["ui", "fetchingComments"]);
      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "COMMENTS/COMMENTS_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingComments"]);

      return R.compose(R.set(fetchLens, false))(state);
    }

    default:
      return state;
  }
}

export { commentsReducer };
