/* eslint-disable no-duplicate-case */
/* @flow
 */
import * as R from "ramda";
import moment from "moment";
import type { Action } from "../../../types";

function joinMessages(previous, next) {
  previous.dateTime = next.dateTime;
  previous.message = `${previous.message}\n
                        ${next.message}`;
  if (previous.aggregate) {
    previous.aggregate = [...previous.aggregate, next.messageId];
  } else {
    previous.aggregate = [next.messageId];
  }
  return previous;
}

type State = {
  ui: {
    isFetchingMonitorias: boolean,
    fetchingDeleteQuestions: boolean,
    fetchingUpdateQuestions: boolean,
    fetchingSelectQuestions: boolean,
    fetchingSelectCourses: boolean,
    fetchingMonitoria: boolean,
    fetchingQuestions: boolean,
    fetchingLikeQuestions: boolean,
    fetchingQuestion: boolean,
    fetchingPastMonitorias: boolean,
    fetchingPastMonitoria: boolean,
    selectedFilter: string,
    selectedDay: string,
    selectedHour: string,
    fetchingHistory: boolean,
    isToggleModal: boolean,
    isToggleModalSambatech: boolean,
    isToggleModalMaterial: boolean,
    isToggleNotify: boolean
  },
  data: {
    monitorias: Array<any>,
    monitoria: Array<any>,
    questions: Array<any>,
    courses: Array<any>,
    question: Array<any>,
    pastMonitorias: Array<any>,
    pastMonitoria: Array<any>
  }
};

const initialState = {
  ui: {
    isFetchingMonitorias: false,
    fetchingDeleteQuestions: false,
    fetchingUpdateQuestions: false,
    fetchingSelectQuestions: false,
    fetchingSelectCourses: false,
    fetchingMonitoria: true,
    fetchingQuestions: false,
    fetchingLikeQuestions: false,
    fetchingQuestion: false,
    fetchingPastMonitorias: false,
    fetchingTimeline: false,
    selectedFilter: "all",
    fetchingPastMonitoria: false,
    isToggleModal: false,
    isToggleModalSambatech: false,
    isToggleModalMaterial: false,
    isToggleNotify: false,
    fetchingHistory: false,
    selectedDay: moment()
      .utc()
      .format("MM/DD/YYYY"),
    selectedHour: moment()
      .utc(-3)
      .format("HH:mm")
  },
  data: {
    monitorias: [],
    monitoria: {},
    questions: [],
    courses: [],
    timeline: [],
    question: {
      course: "",
      week: "",
      reason: "",
      text: ""
    },
    pastMonitorias: {
      meta: {},
      data: []
    },
    pastMonitoria: []
  },
  chat: {
    messages: [],
    messageField: "",
    isUserRegistered: false,
    isChatConnected: false,
    isUserBlocked: false
  }
};

function monitoriaReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case "MONITORIA/UPDATE_FILTER": {
      const filterLens = R.lensPath(["ui", "selectedFilter"]);

      return R.set(filterLens, action.filter, state);
    }

    case "MONITORIA/TOGGLE_MODAL_DELETE_QUESTION": {
      const filterLens = R.lensPath(["ui", "isToggleModal"]);

      return R.set(filterLens, action.modal, state);
    }

    case "MONITORIA/TOGGLE_MODAL_MONITORIAS_MATERIAL": {
      const filterLens = R.lensPath(["ui", "isToggleModalMaterial"]);

      return R.set(filterLens, action.modal, state);
    }
    case "MONITORIA/TOGGLE_MODAL_TOKEN_SAMBATECH": {
      const filterLens = R.lensPath(["ui", "isToggleModalSambatech"]);

      return R.set(filterLens, action.modal, state);
    }

    case "MONITORIA/SET_HISTORY": {
      const fetchLens = R.lensPath(["ui", "fetchingHistory"]);
      return R.set(fetchLens, true, state);
    }
    case "MONITORIA/SET_HISTORY_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "fetchingHistory"]);

      return R.compose(R.set(fetchLens, false))(state);
    }
    case "MONITORIA/SET_HISTORY_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingHistory"]);

      return R.compose(R.set(fetchLens, false))(state);
    }

    case "MONITORIA/TOGGLE_NOTIFY": {
      const filterLens = R.lensPath(["ui", "isToggleNotify"]);
      return R.set(filterLens, action.modal, state);
    }

    case "MONITORIA/CLEAN_DETAILS": {
      const fetchLens = R.lensPath(["data", "pastMonitoria"]);
      return R.set(fetchLens, {}, state);
    }

    case "MONITORIA/MONITORIAS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingMonitorias"]);
      return R.set(fetchLens, true, state);
    }
    case "MONITORIA/MONITORIAS_SUCCESS": {
      const dataLens = R.lensPath(["data", "monitorias"]);
      const fetchLens = R.lensPath(["ui", "isFetchingMonitorias"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "MONITORIA/MONITORIAS_FAILURE": {
      const dataLens = R.lensPath(["data", "monitorias"]);
      const fetchLens = R.lensPath(["ui", "isFetchingMonitorias"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "MONITORIA/TIMELINE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingTimeline"]);
      return R.set(fetchLens, true, state);
    }
    case "MONITORIA/TIMELINE_SUCCESS": {
      const dataLens = R.lensPath(["data", "timeline"]);
      const fetchLens = R.lensPath(["ui", "fetchingTimeline"]);

      let filteredMonitorias = action.data.filter(it => it.events.length > 0);

      return R.compose(R.set(dataLens, filteredMonitorias), R.set(fetchLens, false))(state);
    }
    case "MONITORIA/TIMELINE_FAILURE": {
      const dataLens = R.lensPath(["data", "timeline"]);
      const fetchLens = R.lensPath(["ui", "fetchingTimeline"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "MONITORIA/MONITORIA_LIKE_DISLIKE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingPastMonitoria"]);
      return R.set(fetchLens, true, state);
    }
    case "MONITORIA/MONITORIA_LIKE_DISLIKE_SUCCESS": {
      const dataLens = R.lensPath(["data", "pastMonitoria"]);
      const fetchLens = R.lensPath(["ui", "fetchingPastMonitoria"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "MONITORIA/MONITORIA_LIKE_DISLIKE_FAILURE": {
      const dataLens = R.lensPath(["data", "pastMonitoria"]);
      const fetchLens = R.lensPath(["ui", "fetchingPastMonitoria"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "MONITORIA/QUESTION_LIKE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingLikeQuestions"]);
      return R.set(fetchLens, true, state);
    }
    case "MONITORIA/QUESTION_LIKE_SUCCESS": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingLikeQuestions"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "MONITORIA/QUESTION_LIKE_FAILURE": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingLikeQuestions"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "MONITORIA/QUESTION_DELETE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingDeleteQuestions"]);
      return R.set(fetchLens, true, state);
    }
    case "MONITORIA/QUESTION_DELETE_SUCCESS": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingDeleteQuestions"]);
      const monitoriaLens = R.lensPath(["data", "monitoria", "isQuestionAsked"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false), R.set(monitoriaLens, false))(state);
    }
    case "MONITORIA/QUESTION_DELETE_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingDeleteQuestions"]);

      return R.compose(R.set(fetchLens, false))(state);
    }
    case "MONITORIA/QUESTION_UPDATE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingUpdateQuestions"]);
      return R.set(fetchLens, true, state);
    }
    case "MONITORIA/QUESTION_UPDATE_SUCCESS": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingUpdateQuestions"]);
      const newQuestions = [...state.data.questions];

      const positionEdited = newQuestions.findIndex(question => question.id === action.data.id);
      newQuestions[positionEdited] = action.data;

      return R.compose(R.set(dataLens, newQuestions), R.set(fetchLens, false))(state);
    }
    case "MONITORIA/QUESTION_UPDATE_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingUpdateQuestions"]);

      return R.compose(R.set(fetchLens, false))(state);
    }

    case "MONITORIA/QUESTION_SELECT_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingSelectQuestions"]);
      return R.set(fetchLens, true, state);
    }
    case "MONITORIA/QUESTION_SELECT_SUCCESS": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingSelectQuestions"]);
      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "MONITORIA/QUESTION_SELECT_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingSelectQuestions"]);

      return R.compose(R.set(fetchLens, false))(state);
    }

    case "MONITORIA/COURSE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingSelectCourses"]);
      return R.set(fetchLens, true, state);
    }
    case "MONITORIA/COURSE_SELECT_SUCCESS": {
      const dataLens = R.lensPath(["data", "courses"]);
      const fetchLens = R.lensPath(["ui", "fetchingSelectCourses"]);
      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "MONITORIA/COURSE_SELECT_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingSelectCourses"]);

      return R.compose(R.set(fetchLens, false))(state);
    }

    case "MONITORIA/QUESTIONS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingQuestions"]);
      return R.set(fetchLens, true, state);
    }
    case "MONITORIA/QUESTIONS_SUCCESS": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingQuestions"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "MONITORIA/QUESTIONS_FAILURE": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingQuestions"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "MONITORIA/QUESTION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingQuestion"]);
      return R.set(fetchLens, true, state);
    }
    case "MONITORIA/QUESTION_SUCCESS": {
      const dataLens = R.lensPath(["data", "question"]);
      const monitoriaLens = R.lensPath(["data", "monitoria", "isQuestionAsked"]);
      const dataLensQuestions = R.lensPath(["data", "questions"]);

      const fetchLens = R.lensPath(["ui", "fetchingQuestion"]);
      const newQuestions = [...state.data.questions];
      newQuestions.push(action.data);

      return R.compose(
        R.set(dataLens, action.data),
        R.set(monitoriaLens, true),
        R.set(fetchLens, false),
        R.set(dataLensQuestions, newQuestions)
      )(state);
    }
    case "MONITORIA/QUESTION_FAILURE": {
      const dataLens = R.lensPath(["data", "question"]);
      const fetchLens = R.lensPath(["ui", "fetchingQuestion"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "MONITORIA/MONITORIA_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingMonitoria"]);
      return R.set(fetchLens, true, state);
    }
    case "MONITORIA/MONITORIA_SUCCESS": {
      const dataLens = R.lensPath(["data", "monitoria"]);
      const fetchLens = R.lensPath(["ui", "fetchingMonitoria"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "MONITORIA/MONITORIA_FAILURE": {
      const dataLens = R.lensPath(["data", "monitoria"]);
      const fetchLens = R.lensPath(["ui", "fetchingMonitoria"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }
    case "MONITORIA/PAST_MONITORIAS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingPastMonitorias"]);
      return R.set(fetchLens, true, state);
    }
    case "MONITORIA/PAST_MONITORIAS_SUCCESS": {
      const dataLens = R.lensPath(["data", "pastMonitorias"]);
      const fetchLens = R.lensPath(["ui", "fetchingPastMonitorias"]);

      let actualData = state.data.pastMonitorias;
      if (action.data.data) {
        actualData.meta = action.data.meta;
        if (action.data.meta.currentPage === 1) {
          actualData.data = [];
        }
        actualData.data = [...actualData.data, ...action.data.data];
      }

      return R.compose(R.set(dataLens, actualData), R.set(fetchLens, false))(state);
    }
    case "MONITORIA/PAST_MONITORIAS_FAILURE": {
      const dataLens = R.lensPath(["data", "pastMonitorias"]);
      const fetchLens = R.lensPath(["ui", "fetchingPastMonitorias"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }
    case "MONITORIA/PAST_MONITORIA_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingPastMonitoria"]);
      return R.set(fetchLens, true, state);
    }
    case "MONITORIA/PAST_MONITORIA_SUCCESS": {
      const dataLens = R.lensPath(["data", "pastMonitoria"]);
      const fetchLens = R.lensPath(["ui", "fetchingPastMonitoria"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }

    case "MONITORIA/PAST_MONITORIA_FAILURE": {
      const dataLens = R.lensPath(["data", "pastMonitoria"]);
      const fetchLens = R.lensPath(["ui", "fetchingPastMonitoria"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "MONITORIA/MONITORIA_SUBSCRIPTION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingMonitoriaSubscription"]);
      return R.set(fetchLens, true, state);
    }

    case "MONITORIA/MONITORIA_SUBSCRIPTION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "fetchingMonitoriaSubscription"]);
      const dataLens = R.lensPath(["data", "monitoria", "isSubscribed"]);

      return R.compose(R.set(dataLens, true), R.set(fetchLens, false))(state);
    }

    case "MONITORIA/MONITORIA_SUBSCRIPTION_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingMonitoriaSubscription"]);
      return R.set(fetchLens, false, state);
    }

    case "MONITORIA/PAST_MONITORIA_SUCCESS": {
      // const dataLens = R.lensPath(["data", "pastMonitoria"]);
      const fetchLens = R.lensPath(["ui", "fetchingMonitoriaSubscription"]);

      // return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
      return R.set(fetchLens, false, state);
    }

    case "MONITORIA/PAST_MONITORIA_FAILURE": {
      // const dataLens = R.lensPath(["data", "pastMonitoria"]);
      const fetchLens = R.lensPath(["ui", "fetchingMonitoriaSubscription"]);

      // return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
      return R.set(fetchLens, false, state);
    }

    case "MONITORIA/DAY_ACTIVE_CHANGED": {
      const filterLens = R.lensPath(["ui", "selectedDay"]);

      return R.set(filterLens, action.filter, state);
    }

    case "MONITORIA/HOUR_ACTIVE_CHANGED": {
      const filterLens = R.lensPath(["ui", "selectedHour"]);

      return R.set(filterLens, action.filter, state);
    }

    case "MONITORIA/SELECT_MONITORIA": {
      const filterLens = R.lensPath(["data", "monitoria"]);

      return R.set(filterLens, action.event, state);
    }

    case "MONITORIA/SELECT_MONITORIA_QUESTION": {
      const filterLens = R.lensPath(["data", "question"]);

      return R.set(filterLens, action.question, state);
    }

    case "MONITORIA/UPDATE_MESSAGE_HISTORY": {
      const copyState = { ...state };
      if (copyState.chat.messages.length) {
        let lastMsg = copyState.chat.messages.pop();

        if (lastMsg.userId === action.data.userId && lastMsg.messageType === action.data.messageType) {
          lastMsg = joinMessages(lastMsg, action.data);
          copyState.chat.messages = [...copyState.chat.messages, lastMsg];
        } else {
          copyState.chat.messages = [...copyState.chat.messages, lastMsg, action.data];
        }
        return { ...copyState };
      } else {
        copyState.chat.messages = [...copyState.chat.messages, action.data];
        return { ...copyState };
      }
    }
    case "MONITORIA/UPDATE_MESSAGE_FIELD": {
      const filterLens = R.lensPath(["chat", "messageField"]);

      return R.set(filterLens, action.data, state);
    }
    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}

export default monitoriaReducer;
