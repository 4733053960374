import { DeviceUUID } from "device-uuid";
import { http } from "../Api";
import config from "../config";

const URL = "https://us-central1-active-bolt-351516.cloudfunctions.net/page-views-assine";

export async function pageViewDispatch() {
  try {
    let urlSearch = new URLSearchParams((document.location.search || "").split("?").join(""));

    if (document.referrer && document.referrer.length > 0 && (!urlSearch.get("source") || !urlSearch.get("channel"))) {
      const _r = document.referrer.split("?")[1];
      urlSearch = new URLSearchParams((_r || "").split("?").join(""));
    }

    let body = {
      userId: GetGaUserCode(),
      url: window.location.href.split("?")[0],
      createdAt: formatDate(),
      source: urlSearch.get("source"),
      channel: urlSearch.get("channel"),
      origin: document.referrer || "",
      utmSource: urlSearch.get("utm_source"),
      utmMedium: urlSearch.get("utm_medium"),
      utmCampaign: urlSearch.get("utm_campaign"),
      utmContent: urlSearch.get("utm_content"),
      utmTerm: urlSearch.get("utm_term"),
      ...getUA()
    };

    const init = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body)
    };

    await fetch(URL, init);
  } catch (e) {
    console.log(e);
  }
}

function GetGaUserCode() {
  try {
    // const gclidPromise = await new Promise(resolve => {
    //   window.gtag("get", "G-YMS5GWV814", "client_id", resolve);
    // });
    //
    // if (!gclidPromise) {
    //   http("api/backend/v2/logger/", "POST", {
    //     buildNumber: config.buildNumber,
    //     message: "ERRO NA FUNÇÃO DO GA",
    //     category: "error ga",
    //     action: "error ga",
    //     cookie: gclidPromise
    //   }).catch(e => console.log(e));
    // }
    // return gclidPromise;

    let name = "_ga" + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c
          .substring(name.length, c.length)
          .split(".")
          .slice(-2)
          .join(".");
      }
    }
    http("api/backend/v2/logger/", "POST", {
      buildNumber: config.buildNumber,
      message: "SEND _GA ERROR",
      category: "error ga",
      action: "error ga",
      cookie: document.cookie
    }).catch(e => console.log(e));
    return null;
  } catch (e) {
    console.log(e.message);
    http("api/backend/v2/logger/", "POST", {
      buildNumber: config.buildNumber,
      message: "ERRO CATCH",
      category: "error ga",
      action: "error ga",
      label: e.message
    }).catch(e => console.log(e));
    return null;
  }
}

const getUA = () => {
  try {
    const du = new DeviceUUID().parse();
    return {
      version: du.version,
      os: du.os,
      browser: du.browser,
      platform: du.platform
    };
  } catch (e) {
    return {};
  }
};

function formatDate(_date = new Date()) {
  let date = new Date(_date);
  // date = new Date(date.getTime() - 3 * 3600 * 1000);
  return (
    [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join("-") +
    " " +
    [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(":")
  );
}

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}
