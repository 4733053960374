import * as R from "ramda";

const initialState = {
  ui: {
    isFetchingNews: false,
    isFetchingRankingSimulation: false,
    isFetchingIncidences: false,
    isFetchingMuralCards: false
  },
  data: {
    rankingSimulation: {},
    incidences: [],
    muralCards: []
  }
};

function feedReducer(state = initialState, action) {
  switch (action.type) {
    case "FEED/FEED_NEWS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingNews"]);
      return R.set(fetchLens, true, state);
    }
    case "FEED/FEED_NEWS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingNews"]);
      return R.set(fetchLens, false, state);
    }
    case "FEED/FEED_NEWS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingNews"]);
      const essaysLens = R.lensPath(["data", "feed"]);

      return R.compose(R.set(fetchLens, false), R.set(essaysLens, action.data))(state);
    }

    case "FEED/INCIDENCES_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingIncidences"]);

      return R.set(fetchLens, true, state);
    }

    case "FEED/INCIDENCES_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingIncidences"]);

      return R.compose(R.set(fetchLens, false))(state);
    }

    case "FEED/INCIDENCES_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingIncidences"]);

      const essaysLens = R.lensPath(["data", "incidences"]);
      return R.compose(R.set(fetchLens, false), R.set(essaysLens, action.data))(state);
    }

    case "FEED/RANKING_SIMULATION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingRankingSimulation"]);
      return R.set(fetchLens, true, state);
    }
    case "FEED/RANKING_SIMULATION_SUCCESS": {
      const dataLens = R.lensPath(["data", "rankingSimulation"]);
      const fetchLens = R.lensPath(["ui", "isFetchingRankingSimulation"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "FEED/RANKING_SIMULATION_FAILURE": {
      const dataLens = R.lensPath(["data", "rankingSimulation"]);
      const fetchLens = R.lensPath(["ui", "isFetchingRankingSimulation"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "FEED/MURAL_CARDS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingMuralCards"]);

      return R.set(fetchLens, true, state);
    }

    case "FEED/MURAL_CARDS_REQUEST_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingMuralCards"]);

      return R.compose(R.set(fetchLens, false))(state);
    }

    case "FEED/MURAL_CARDS_REQUEST_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingMuralCards"]);

      const essaysLens = R.lensPath(["data", "muralCards"]);
      return R.compose(R.set(fetchLens, false), R.set(essaysLens, action.data))(state);
    }

    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}

export default feedReducer;
