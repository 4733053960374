/**
 * Returns the definite article of a brazilian state name
 * eg. param "rs" returns "o" to be applied in sentences like:
 * "O estado d*O* Rio Grande do Sul..."
 * "*O* Rio Grande do Sul é..."
 *
 * In some cases, the article is not needed, like for "mg":
 * "O estado DE Minas Gerais..."
 * "Minas Gerais é..."
 * In this cases the return will be an empty string.
 *
 * Source: https://www.embrapa.br/documents/1355746/30180455/Lista+de+estados+brasileiros+e+seus+respectivos+artigos+definidos.pdf/30696a43-0f3a-dd19-204a-04274d83476b
 *
 * @param {string} uf state initials
 * @return {string} Returns "", "a" or "o".
 */
export const BrazilianStateArticle = uf => {
  switch (uf.toLowerCase()) {
    case "ac":
      return "o";
    case "al":
      return "";
    case "ap":
      return "o";
    case "am":
      return "o";
    case "ba":
      return "a";
    case "ce":
      return "o";
    case "df":
      return "o";
    case "es":
      return "o";
    case "go":
      return "";
    case "ma":
      return "o";
    case "mt":
      return "";
    case "ms":
      return "";
    case "mg":
      return "";
    case "pa":
      return "o";
    case "pb":
      return "a";
    case "pr":
      return "o";
    case "pe":
      return "";
    case "pi":
      return "o";
    case "rr":
      return "";
    case "ro":
      return "";
    case "rj":
      return "o";
    case "rn":
      return "o";
    case "rs":
      return "o";
    case "sc":
      return "";
    case "sp":
      return "";
    case "se":
      return "";
    case "to":
      return "o";
    default:
      return "";
  }
};
