/* @flow
 */
import * as R from "ramda";
import moment from "moment";
import type { Action } from "../../../types";

function joinMessages(previous, next) {
  previous.dateTime = next.dateTime;
  previous.message = `${previous.message}\n
                        ${next.message}`;
  if (previous.aggregate) {
    previous.aggregate = [...previous.aggregate, next.messageId];
  } else {
    previous.aggregate = [next.messageId];
  }
  return previous;
}

type State = {
  ui: {
    fetchingLives: boolean,
    fetchingDeleteQuestions: boolean,
    fetchingUpdateQuestions: boolean,
    fetchingSelectQuestions: boolean,
    fetchingLive: boolean,
    fetchingQuestions: boolean,
    fetchingQuestion: boolean,
    fetchingPastLives: boolean,
    fetchingPastLive: boolean,
    selectedFilter: string,
    selectedDay: string,
    selectedHour: string,
    fetchingHistory: boolean,
    isToggleModal: boolean,
    isToggleModalSambatech: boolean,
    isToggleModalMaterial: boolean,
    isToggleNotify: boolean
  },
  data: {
    lives: Array<any>,
    live: Array<any>,
    questions: Array<any>,
    question: Array<any>,
    pastlives: Array<any>,
    pastlive: Array<any>
  }
};

const initialState = {
  ui: {
    fetchingLives: false,
    fetchingDeleteQuestions: false,
    fetchingUpdateQuestions: false,
    fetchingSelectQuestions: false,
    fetchingLive: false,
    fetchingQuestions: false,
    fetchingQuestion: false,
    fetchingPastLives: false,
    fetchingTimeline: false,
    selectedFilter: "all",
    fetchingPastLive: false,
    isToggleModal: false,
    isToggleModalSambatech: false,
    isToggleModalMaterial: false,
    isToggleNotify: false,
    fetchingHistory: false,
    selectedDay: moment()
      .utc()
      .format("MM/DD/YYYY"),
    selectedHour: moment()
      .utc(-3)
      .format("HH:mm")
  },
  data: {
    lives: [],
    live: [],
    questions: [],
    timeline: [],
    question: [],
    pastlives: {
      meta: {},
      data: []
    },
    pastlive: []
  },
  chat: {
    messages: [],
    messageField: "",
    isUserRegistered: false,
    isChatConnected: false,
    isUserBlocked: false
  }
};

function homeReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case "LIVE/UPDATE_FILTER": {
      const filterLens = R.lensPath(["ui", "selectedFilter"]);

      return R.set(filterLens, action.filter, state);
    }

    case "LIVE/TOGGLE_MODAL_DELETE_QUESTION": {
      const filterLens = R.lensPath(["ui", "isToggleModal"]);

      return R.set(filterLens, action.modal, state);
    }

    case "LIVE/TOGGLE_MODAL_LIVES_MATERIAL": {
      const filterLens = R.lensPath(["ui", "isToggleModalMaterial"]);

      return R.set(filterLens, action.modal, state);
    }
    case "LIVE/TOGGLE_MODAL_TOKEN_SAMBATECH": {
      const filterLens = R.lensPath(["ui", "isToggleModalSambatech"]);

      return R.set(filterLens, action.modal, state);
    }

    case "LIVE/SET_HISTORY": {
      const fetchLens = R.lensPath(["ui", "fetchingHistory"]);
      return R.set(fetchLens, true, state);
    }
    case "LIVE/SET_HISTORY_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "fetchingHistory"]);

      return R.compose(R.set(fetchLens, false))(state);
    }
    case "LIVE/SET_HISTORY_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingHistory"]);

      return R.compose(R.set(fetchLens, false))(state);
    }

    case "LIVE/TOGGLE_NOTIFY": {
      const filterLens = R.lensPath(["ui", "isToggleNotify"]);
      return R.set(filterLens, action.modal, state);
    }

    case "LIVE/CLEAN_DETAILS": {
      const fetchLens = R.lensPath(["data", "pastlive"]);
      return R.set(fetchLens, {}, state);
    }

    case "LIVE/LIVES_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingLives"]);
      return R.set(fetchLens, true, state);
    }
    case "LIVE/LIVES_SUCCESS": {
      const dataLens = R.lensPath(["data", "lives"]);
      const fetchLens = R.lensPath(["ui", "fetchingLives"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "LIVE/LIVES_FAILURE": {
      const dataLens = R.lensPath(["data", "lives"]);
      const fetchLens = R.lensPath(["ui", "fetchingLives"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "LIVE/TIMELINE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingTimeline"]);
      return R.set(fetchLens, true, state);
    }
    case "LIVE/TIMELINE_SUCCESS": {
      const dataLens = R.lensPath(["data", "timeline"]);
      const fetchLens = R.lensPath(["ui", "fetchingTimeline"]);

      let filteredLives = action.data.filter(it => it.events.length > 0);

      return R.compose(R.set(dataLens, filteredLives), R.set(fetchLens, false))(state);
    }
    case "LIVE/TIMELINE_FAILURE": {
      const dataLens = R.lensPath(["data", "timeline"]);
      const fetchLens = R.lensPath(["ui", "fetchingTimeline"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "LIVE/LIVE_LIKE_DISLIKE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingPastlive"]);
      return R.set(fetchLens, true, state);
    }
    case "LIVE/LIVE_LIKE_DISLIKE_SUCCESS": {
      const dataLens = R.lensPath(["data", "pastlive"]);
      const fetchLens = R.lensPath(["ui", "fetchingPastlive"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "LIVE/LIVE_LIKE_DISLIKE_FAILURE": {
      const dataLens = R.lensPath(["data", "pastlive"]);
      const fetchLens = R.lensPath(["ui", "fetchingPastlive"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "LIVE/QUESTION_LIKE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingQuestions"]);
      return R.set(fetchLens, true, state);
    }
    case "LIVE/QUESTION_LIKE_SUCCESS": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingQuestions"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "LIVE/QUESTION_LIKE_FAILURE": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingQuestions"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "LIVE/QUESTION_DELETE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingDeleteQuestions"]);
      return R.set(fetchLens, true, state);
    }
    case "LIVE/QUESTION_DELETE_SUCCESS": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingDeleteQuestions"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "LIVE/QUESTION_DELETE_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingDeleteQuestions"]);

      return R.compose(R.set(fetchLens, false))(state);
    }
    case "LIVE/QUESTION_UPDATE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingUpdateQuestions"]);
      return R.set(fetchLens, true, state);
    }
    case "LIVE/QUESTION_UPDATE_SUCCESS": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingUpdateQuestions"]);
      const newQuestions = [...state.data.questions];

      const positionEdited = newQuestions.findIndex(question => question.id === action.data.id);
      newQuestions[positionEdited] = action.data;

      return R.compose(R.set(dataLens, newQuestions), R.set(fetchLens, false))(state);
    }
    case "LIVE/QUESTION_UPDATE_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingUpdateQuestions"]);

      return R.compose(R.set(fetchLens, false))(state);
    }

    case "LIVE/QUESTION_SELECT_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingSelectQuestions"]);
      return R.set(fetchLens, true, state);
    }
    case "LIVE/QUESTION_SELECT_SUCCESS": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingSelectQuestions"]);
      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "LIVE/QUESTION_SELECT_FAILURE": {
      const fetchLens = R.lensPath(["ui", "fetchingSelectQuestions"]);

      return R.compose(R.set(fetchLens, false))(state);
    }

    case "LIVE/QUESTIONS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingQuestions"]);
      return R.set(fetchLens, true, state);
    }
    case "LIVE/QUESTIONS_SUCCESS": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingQuestions"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "LIVE/QUESTIONS_FAILURE": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingQuestions"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "LIVE/QUESTION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingQuestion"]);
      return R.set(fetchLens, true, state);
    }
    case "LIVE/QUESTION_SUCCESS": {
      const dataLens = R.lensPath(["data", "question"]);
      const dataLensQuestions = R.lensPath(["data", "questions"]);

      const fetchLens = R.lensPath(["ui", "fetchingQuestion"]);
      const newQuestions = [...state.data.questions];
      newQuestions.push(action.data);
      return R.compose(
        R.set(dataLens, action.data),
        R.set(fetchLens, false),
        R.set(dataLensQuestions, newQuestions)
      )(state);
    }
    case "LIVE/QUESTION_FAILURE": {
      const dataLens = R.lensPath(["data", "question"]);
      const fetchLens = R.lensPath(["ui", "fetchingQuestion"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "LIVE/LIVE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingLive"]);
      return R.set(fetchLens, true, state);
    }
    case "LIVE/LIVE_SUCCESS": {
      const dataLens = R.lensPath(["data", "live"]);
      const fetchLens = R.lensPath(["ui", "fetchingLive"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "LIVE/LIVE_FAILURE": {
      const dataLens = R.lensPath(["data", "live"]);
      const fetchLens = R.lensPath(["ui", "fetchingLive"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }
    case "LIVE/PAST_LIVES_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingPastLives"]);
      return R.set(fetchLens, true, state);
    }
    case "LIVE/PAST_LIVES_SUCCESS": {
      const dataLens = R.lensPath(["data", "pastlives"]);
      const fetchLens = R.lensPath(["ui", "fetchingPastLives"]);

      let actualData = state.data.pastlives;
      if (action.data.data) {
        actualData.meta = action.data.meta;
        if (action.data.meta.currentPage === 1) {
          actualData.data = [];
        }
        actualData.data = [...actualData.data, ...action.data.data];
      }

      return R.compose(R.set(dataLens, actualData), R.set(fetchLens, false))(state);
    }
    case "LIVE/PAST_LIVES_FAILURE": {
      const dataLens = R.lensPath(["data", "pastlives"]);
      const fetchLens = R.lensPath(["ui", "fetchingPastLives"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }
    case "LIVE/PAST_LIVE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingPastLive"]);
      return R.set(fetchLens, true, state);
    }
    case "LIVE/PAST_LIVE_SUCCESS": {
      const dataLens = R.lensPath(["data", "pastlive"]);
      const fetchLens = R.lensPath(["ui", "fetchingPastLive"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }

    case "LIVE/PAST_LIVE_FAILURE": {
      const dataLens = R.lensPath(["data", "pastlive"]);
      const fetchLens = R.lensPath(["ui", "fetchingPastLive"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "LIVE/DAY_ACTIVE_CHANGED": {
      const filterLens = R.lensPath(["ui", "selectedDay"]);

      return R.set(filterLens, action.filter, state);
    }

    case "LIVE/HOUR_ACTIVE_CHANGED": {
      const filterLens = R.lensPath(["ui", "selectedHour"]);

      return R.set(filterLens, action.filter, state);
    }

    case "LIVE/UPDATE_MESSAGE_HISTORY": {
      const copyState = { ...state };
      if (copyState.chat.messages.length) {
        let lastMsg = copyState.chat.messages.pop();

        if (lastMsg.userId === action.data.userId && lastMsg.messageType === action.data.messageType) {
          lastMsg = joinMessages(lastMsg, action.data);
          copyState.chat.messages = [...copyState.chat.messages, lastMsg];
        } else {
          copyState.chat.messages = [...copyState.chat.messages, lastMsg, action.data];
        }
        return { ...copyState };
      } else {
        copyState.chat.messages = [...copyState.chat.messages, action.data];
        return { ...copyState };
      }
    }
    case "LIVE/UPDATE_MESSAGE_FIELD": {
      const filterLens = R.lensPath(["chat", "messageField"]);

      return R.set(filterLens, action.data, state);
    }
    case "CHAT/REGISTER_USER": {
      const filterLens = R.lensPath(["chat", "isUserRegistered"]);
      return R.set(filterLens, true, state);
    }
    case "CHAT/UNREGISTER_USER": {
      const filterLens = R.lensPath(["chat", "isUserRegistered"]);
      return R.set(filterLens, false, state);
    }
    case "CHAT/CONNECTED_USER": {
      const filterLens = R.lensPath(["chat", "isChatConnected"]);
      return R.set(filterLens, true, state);
    }
    case "CHAT/LOAD_CHAT_HISTORY": {
      const filterLens = R.lensPath(["chat", "messages"]);

      return R.set(filterLens, action.data.reverse(), state);
    }

    case "CHAT/BLOCKED_USER_CHAT": {
      const filterLens = R.lensPath(["chat", "isUserBlocked"]);

      return R.set(filterLens, true, state);
    }

    case "CHAT/RECEIVE_MESSAGE_DELETE": {
      const filterLens = R.lensPath(["chat", "messages"]);
      const newMessages = state.chat.messages.filter(it => it.messageId !== action.data.messageId);

      return R.set(filterLens, newMessages, state);
    }
    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}

export default homeReducer;
