/**
 * Returns array of area objects to use on filters.
 *
 * @param {boolean} includeDiversas When true the DIVERSAS option will be pushed into the filters array.
 * @param {boolean} excludeTodos When true the TODOS option will be removed from the filters array (Used in Comunidade).
 * @return {array} Array of area objects.
 */

export function GetAreaFilter(includeDiversas, excludeTodos) {
  const filters = [
    { id: "all", label: "TODOS", labelMobile: "TODOS" },
    { id: "biologia", label: "BIOLOGIA", labelMobile: "BIO" },
    { id: "fisica", label: "FÍSICA", labelMobile: "FIS" },
    { id: "quimica", label: "QUÍMICA", labelMobile: "QUI" },
    { id: "matematica", label: "MATEMÁTICA", labelMobile: "MAT" },
    { id: "historia", label: "HISTÓRIA", labelMobile: "HIST" },
    { id: "geografia", label: "GEOGRAFIA", labelMobile: "GEO" },
    { id: "filosofia", label: "FILOSOFIA", labelMobile: "FIL" },
    { id: "sociologia", label: "SOCIOLOGIA", labelMobile: "SOCIO" },
    { id: "literatura", label: "LITERATURA", labelMobile: "LITER" },
    { id: "portugues", label: "PORTUGUÊS", labelMobile: "POR" },
    { id: "redacao", label: "REDAÇÃO", labelMobile: "RED" },
    { id: "ingles", label: "INGLÊS", labelMobile: "ING" },
    { id: "espanhol", label: "ESPANHOL", labelMobile: "ESP" },
    { id: "interdisciplinar", label: "INTERDISCIPLINAR", labelMobile: "INTER" }
  ];

  if (includeDiversas) {
    filters.push({ id: "diversas", label: "DIVERSAS", labelMobile: "DIV" });
  }

  if (excludeTodos) {
    filters.shift();
  }

  return filters;
}
