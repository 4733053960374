const emptyCourseFilter = {
  course: "",
  university: ""
};

export const changeComparisonFilter = (filterName, filterValue, filter, changeFilter) => {
  const newState = {
    [filterName]: filterValue
  };

  if (filterValue === "others" || filterValue === "none") {
    newState.courseFilter = emptyCourseFilter;
  }
  const newFilter = { ...filter, ...newState };
  if (filterValue === "others" || filterValue === "course") {
    if (filter.dateFilter === "all") {
      newFilter.dateFilter = "year";
    }
  }
  changeFilter(newFilter);
};

export const DISCIPLINES = [
  { name: "Biologia", slug: "biologia", scope: "biology" },
  { name: "Física", slug: "fisica", scope: "physics" },
  { name: "Química", slug: "quimica", scope: "chemestry" },
  { name: "Matemática", slug: "matematica", scope: "mathematics" },
  { name: "História", slug: "historia", scope: "history" },
  { name: "Geografia", slug: "geografia", scope: "geography" },
  { name: "Filosofia", slug: "filosofia", scope: "philosophy" },
  { name: "Sociologia", slug: "sociologia", scope: "sociology" },
  { name: "Português", slug: "portugues", scope: "portuguese" },
  { name: "Literatura", slug: "literatura", scope: "literature" },
  { name: "Redação", slug: "redacao", scope: "essay" },
  { name: "Inglês", slug: "ingles", scope: "english" },
  { name: "Espanhol", slug: "espanhol", scope: "spanish" },
  { name: "Interdisciplinar", slug: "interdisciplinar", scope: "interdisciplinary" }
];

function getEntriesAreasName() {
  const _d = {};

  DISCIPLINES.map(discipline => {
    _d[discipline.slug] = discipline.name;
    return discipline;
  });
  return _d;
}

function getEntriesAreasScope() {
  const _d = {};

  DISCIPLINES.map(discipline => {
    _d[discipline.slug] = discipline.scope;
    return discipline;
  });
  return _d;
}

export const getArea = getEntriesAreasName();

export const getScope = getEntriesAreasScope();

export const getScopeFromSlug = slug => {
  if (slug !== "diversas") return DISCIPLINES.find(discipline => slug === discipline.slug).scope;
};

export const getAreaTitleFromScope = scope => {
  return DISCIPLINES.find(discipline => scope === discipline.scope).title;
};

export const getAreaFromSlug = slug => DISCIPLINES.find(discipline => discipline.slug === slug);

export const areaColors = {};
