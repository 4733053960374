const config = {
  pagarmeKey: process.env.REACT_APP_PAGARME_KEY,
  apiUrl: process.env.REACT_APP_API_URL,
  storageEndpoint: process.env.REACT_APP_STORAGE_ENDPOINT,
  chatURL: process.env.REACT_APP_CHAT_URL,
  baseURL: process.env.REACT_APP_BASE_URL,
  platformURL: process.env.REACT_APP_PLATFORM_URL,
  env: process.env.REACT_APP_ENV,
  buildNumber: process.env.REACT_APP_BUILD_NUMBER || 0,
  zendeskURL: process.env.REACT_APP_ZENDESK_URL,
  bucketName: process.env.REACT_APP_STORAGE_BUCKET_NAME,
  bucketRegion: process.env.REACT_APP_STORAGE_BUCKET_REGION,
  bucketPoolId: process.env.REACT_APP_STORAGE_POOL_ID,
  recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
  pixelId: process.env.REACT_APP_PIXEL_ID,
  conversionApiAccessToken: process.env.REACT_APP_CONVERSION_API_ACCESS_TOKEN,
  activeToken: process.env.REACT_APP_ACTIVE_TOKEN,
  zoomApkClientId: process.env.REACT_APP_ZOOM_APK_CLIENT_ID,
  tokenName: process.env.REACT_APP_COOKIE_NAME,
  cookieDomain: '.aprovatotal.com.br' //process.env.REACT_APP_COOKIE_DOMAIN
};

export default config;
