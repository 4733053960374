import * as R from "ramda";

const initialState = {
  ui: {
    isFetchingQuestionLike: false,
    isFetchingFavoritesRemove: false,
    isFetchingFavorites: false,
    isFetchingSubareas: false,
    selectedFilter: "all",
    selectedModule: "",
    selectedSubjects: [],
    lessonWatched: null,
    lessonNotWatched: null,
    materialNotSeen: null,
    materialSeen: null,
    wrongAnswer: null,
    unanswered: null,
    rightAnswer: null,
    questionOptions: null,
    trialOptions: null,
    simulatedNotDone: null,
    simulatedDone: null,
    podcastNotHearded: null,
    podcastHearded: null,
    mapNotSeen: null,
    mapSeen: null,
    liveMonitory: null,
    liveClass: null,
    liveReview: null,
    selectedQuestionsIsMyQuestions: null,
    selectedQuestionsAnswered: null,
    selectedQuestionsNotAnswered: null,
    selectedQuestionsVerified: null
  },
  data: {
    lessons: [],
    meta: {},
    live: [],
    apostila: [],
    exercise: [],
    podcast: [],
    mapamental: [],
    practicetest: [],
    communityquestion: [],
    subareas: []
  }
};

function favoritesReducer(state = initialState, action) {
  switch (action.type) {
    case "FAVORITES/UPDATE_FILTER": {
      const filterLens = R.lensPath(["ui", "selectedFilter"]);
      if (action.filter === "all" || action.filter === "diversas") {
        const selectedModule = R.lensPath(["ui", "selectedModule"]);
        const selectedSubjects = R.lensPath(["ui", "selectedSubjects"]);
        const lessonNotWatched = R.lensPath(["ui", ["lessonNotWatched"]]);
        const lessonWatched = R.lensPath(["ui", ["lessonWatched"]]);
        const materialSeen = R.lensPath(["ui", ["materialSeen"]]);
        const materialNotSeen = R.lensPath(["ui", ["materialNotSeen"]]);
        const wrongAnswer = R.lensPath(["ui", ["wrongAnswer"]]);
        const unanswered = R.lensPath(["ui", ["unanswered"]]);
        const rightAnswer = R.lensPath(["ui", ["rightAnswer"]]);
        const questionOptions = R.lensPath(["ui", ["questionOptions"]]);
        const trialOptions = R.lensPath(["ui", ["trialOptions"]]);
        const simulatedNotDone = R.lensPath(["ui", ["simulatedNotDone"]]);
        const simulatedDone = R.lensPath(["ui", ["simulatedDone"]]);
        const podcastNotHearded = R.lensPath(["ui", ["podcastNotHearded"]]);
        const podcastHearded = R.lensPath(["ui", ["podcastHearded"]]);
        const mapNotSeen = R.lensPath(["ui", ["mapNotSeen"]]);
        const mapSeen = R.lensPath(["ui", ["mapSeen"]]);
        const liveReview = R.lensPath(["ui", ["liveReview"]]);
        const liveClass = R.lensPath(["ui", ["liveClass"]]);
        const liveMonitory = R.lensPath(["ui", ["liveMonitory"]]);

        const selectedQuestionsIsMyQuestions = R.lensPath(["ui", ["selectedQuestionsIsMyQuestions"]]);
        const selectedQuestionsAnswered = R.lensPath(["ui", ["selectedQuestionsAnswered"]]);
        const selectedQuestionsNotAnswered = R.lensPath(["ui", ["selectedQuestionsNotAnswered"]]);
        const selectedQuestionsVerified = R.lensPath(["ui", ["selectedQuestionsVerified"]]);

        return R.compose(
          R.set(selectedQuestionsIsMyQuestions, null),
          R.set(selectedQuestionsAnswered, null),
          R.set(selectedQuestionsNotAnswered, null),
          R.set(selectedQuestionsVerified, null),
          R.set(liveReview, null),
          R.set(liveClass, null),
          R.set(liveMonitory, null),
          R.set(mapNotSeen, null),
          R.set(mapSeen, null),
          R.set(podcastHearded, null),
          R.set(podcastNotHearded, null),
          R.set(simulatedDone, null),
          R.set(simulatedNotDone, null),
          R.set(rightAnswer, null),
          R.set(questionOptions, null),
          R.set(trialOptions, null),
          R.set(unanswered, null),
          R.set(wrongAnswer, null),
          R.set(lessonNotWatched, null),
          R.set(lessonWatched, null),
          R.set(materialSeen, null),
          R.set(materialNotSeen, null),
          R.set(selectedModule, ""),
          R.set(selectedSubjects, []),
          R.set(filterLens, action.filter)
        )(state);
      } else {
        return R.compose(R.set(filterLens, action.filter))(state);
      }
    }

    case "FAVORITES/UPDATE_QUESTIONS_MY_QUESTIONS": {
      const filterLens = R.lensPath(["ui", "selectedQuestionsIsMyQuestions"]);

      return R.set(filterLens, action.filter, state);
    }

    case "FAVORITES/UPDATE_QUESTIONS_ANSWERED": {
      const filterLens = R.lensPath(["ui", "selectedQuestionsAnswered"]);

      return R.set(filterLens, action.filter, state);
    }

    case "FAVORITES/UPDATE_QUESTIONS_NOT_ANSWERED": {
      const filterLens = R.lensPath(["ui", "selectedQuestionsNotAnswered"]);

      return R.set(filterLens, action.filter, state);
    }

    case "FAVORITES/UPDATE_QUESTIONS_VERIFIED": {
      const filterLens = R.lensPath(["ui", "selectedQuestionsVerified"]);

      return R.set(filterLens, action.filter, state);
    }

    case "FAVORITES/UPDATE_MONITORY": {
      const filterLens = R.lensPath(["ui", "liveMonitory"]);

      return R.set(filterLens, action.filter, state);
    }
    case "FAVORITES/UPDATE_REVIEW": {
      const filterLens = R.lensPath(["ui", "liveReview"]);

      return R.set(filterLens, action.filter, state);
    }
    case "FAVORITES/UPDATE_CLASS": {
      const filterLens = R.lensPath(["ui", "liveClass"]);

      return R.set(filterLens, action.filter, state);
    }
    case "FAVORITES/UPDATE_PODCAST_NOT_HEARDED": {
      const filterLens = R.lensPath(["ui", "podcastNotHearded"]);

      return R.set(filterLens, action.filter, state);
    }
    case "FAVORITES/UPDATE_PODCAST_HEARDED": {
      const filterLens = R.lensPath(["ui", "podcastHearded"]);

      return R.set(filterLens, action.filter, state);
    }

    case "FAVORITES/UPDATE_MAP_NOT_SEEN": {
      const filterLens = R.lensPath(["ui", "mapNotSeen"]);

      return R.set(filterLens, action.filter, state);
    }
    case "FAVORITES/UPDATE_MAP_SEEN": {
      const filterLens = R.lensPath(["ui", "mapSeen"]);

      return R.set(filterLens, action.filter, state);
    }

    case "FAVORITES/UPDATE_EXERCISE_RIGHT_ANSWER": {
      const filterLens = R.lensPath(["ui", "rightAnswer"]);

      return R.set(filterLens, action.filter, state);
    }
    case "FAVORITES/UPDATE_EXERCISE_QUESTION_OPTIONS": {
      const filterLens = R.lensPath(["ui", "questionOptions"]);

      return R.set(filterLens, action.filter, state);
    }
    case "FAVORITES/UPDATE_EXERCISE_TRIAL_OPTIONS": {
      const filterLens = R.lensPath(["ui", "trialOptions"]);

      return R.set(filterLens, action.filter, state);
    }
    case "FAVORITES/UPDATE_EXERCISE_UNANSWERED": {
      const filterLens = R.lensPath(["ui", "unanswered"]);

      return R.set(filterLens, action.filter, state);
    }

    case "FAVORITES/UPDATE_SIMULATED_NOT_DONE": {
      const filterLens = R.lensPath(["ui", "simulatedNotDone"]);

      return R.set(filterLens, action.filter, state);
    }
    case "FAVORITES/UPDATE_SIMULATED_DONE": {
      const filterLens = R.lensPath(["ui", "simulatedDone"]);

      return R.set(filterLens, action.filter, state);
    }

    case "FAVORITES/UPDATE_EXERCISE_WRONG_ANSWER": {
      const filterLens = R.lensPath(["ui", "wrongAnswer"]);

      return R.set(filterLens, action.filter, state);
    }

    case "FAVORITES/UPDATE_MODULE": {
      const filterLens = R.lensPath(["ui", "selectedModule"]);

      return R.set(filterLens, action.filter, state);
    }
    case "FAVORITES/UPDATE_SUBJECTS": {
      const filterLens = R.lensPath(["ui", "selectedSubjects"]);

      return R.set(filterLens, action.filter, state);
    }
    case "FAVORITES/UPDATE_LESSON_NOT_WATCHED": {
      const filterLens = R.lensPath(["ui", "lessonNotWatched"]);

      return R.set(filterLens, action.filter, state);
    }
    case "FAVORITES/UPDATE_LESSON_WATCHED": {
      const filterLens = R.lensPath(["ui", "lessonWatched"]);

      return R.set(filterLens, action.filter, state);
    }
    case "FAVORITES/UPDATE_MATERIAL_NOT_WATCHED": {
      const filterLens = R.lensPath(["ui", "materialNotSeen"]);

      return R.set(filterLens, action.filter, state);
    }

    case "FAVORITES/UPDATE_MATERIAL_WATCHED": {
      const filterLens = R.lensPath(["ui", "materialSeen"]);

      return R.set(filterLens, action.filter, state);
    }
    case "FAVORITES/SUBAREAS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubareas"]);

      return R.set(fetchLens, true, state);
    }
    case "FAVORITES/SUBAREAS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubareas"]);
      const datalens = R.lensPath(["data", "subareas"]);

      return R.compose(R.set(fetchLens, false), R.set(datalens, action.data))(state);
    }
    case "FAVORITES/SUBAREAS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingSubareas"]);

      return R.set(fetchLens, false, state);
    }

    case "FAVORITES/USER_FAVORITES_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingFavorites"]);

      return R.set(fetchLens, true, state);
    }
    case "FAVORITES/USER_FAVORITES_REQUEST_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingFavorites"]);
      const datalens = R.lensPath(["data", action.category]);
      const dataMeta = R.lensPath(["data", "meta"]);
      return R.compose(
        R.set(fetchLens, false),
        R.set(datalens, action.data.data),
        R.set(dataMeta, action.data.meta)
      )(state);
    }
    case "FAVORITES/USER_FAVORITES_REQUEST_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingFavorites"]);

      return R.set(fetchLens, false, state);
    }

    case "FAVORITES/USER_FAVORITES_INSERT_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingFavoritesInsert"]);

      return R.set(fetchLens, true, state);
    }
    case "FAVORITES/USER_FAVORITES_INSERT_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingFavoritesInsert"]);

      return R.set(fetchLens, false, state);
    }
    case "FAVORITES/USER_FAVORITES_INSERT_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingFavoritesInsert"]);
      const dataLens = R.lensPath(["data", "favorite"]);

      return R.compose(R.set(fetchLens, false), R.set(dataLens, action.data))(state);
    }

    case "FAVORITES/USER_FAVORITES_REMOVE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingFavoritesRemove"]);

      return R.set(fetchLens, true, state);
    }
    case "FAVORITES/USER_FAVORITES_REMOVE_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingFavoritesRemove"]);

      return R.set(fetchLens, false, state);
    }
    case "FAVORITES/USER_FAVORITES_REMOVE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingFavoritesRemove"]);
      let category =
        action.data.category === "lesson"
          ? "lessons"
          : action.data.category === "apostila"
          ? "material"
          : action.data.category === "audio"
          ? "podcast"
          : action.data.category;
      const dataLens = R.lensPath(["data", category]);

      const newData =
        state.data[category] &&
        state.data[category].length > 0 &&
        state.data[category].filter(it => it.targetId.id !== action.data.targetId);

      return R.compose(R.set(fetchLens, false), R.set(dataLens, newData))(state);
    }

    //like
    case "FAVORITES/LIKE_QUESTION": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestionLike"]);

      return R.set(fetchLens, true, state);
    }

    case "FAVORITES/DISLIKE_QUESTION": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestionDislike"]);

      return R.set(fetchLens, true, state);
    }
    case "FAVORITES/LIKE_QUESTION_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestionLike"]);

      return R.set(fetchLens, false, state);
    }
    case "FAVORITES/DISLIKE_QUESTION_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestionDislike"]);

      return R.set(fetchLens, false, state);
    }
    case "FAVORITES/LIKE_QUESTION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestionLike"]);
      const dataLens = R.lensPath(["data", "communityquestion"]);

      const d = state.data.communityquestion.map(it => {
        if (it.targetId._id === Number(action.data.questionId)) {
          it.targetId.dislikesCount = action.data.dislikesCount;
          it.targetId.hasBeenDisliked = action.data.hasBeenDisliked;
          it.targetId.hasBeenLiked = action.data.hasBeenLiked;
        }
        return it;
      });

      return R.compose(R.set(fetchLens, false), R.set(dataLens, d))(state);
    }
    case "FAVORITES/DISLIKE_QUESTION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingQuestionDislike"]);
      const dataLens = R.lensPath(["data", "communityquestion"]);

      const d = state.data.communityquestion.map(it => {
        if (it.targetId._id === Number(action.data.questionId)) {
          it.targetId.dislikesCount = action.data.dislikesCount;
          it.targetId.hasBeenDisliked = action.data.hasBeenDisliked;
          it.targetId.hasBeenLiked = action.data.hasBeenLiked;
        }
        return it;
      });

      return R.compose(R.set(fetchLens, false), R.set(dataLens, d))(state);
    }

    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}

export default favoritesReducer;
