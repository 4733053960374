const URL = "https://clicks-fv7tv3cf4a-uc.a.run.app/";

export async function clickDispatch(search = "") {
  try {
    const timeout = (await GetGaUserCode()) ? 0 : 3000;

    setTimeout(async () => {
      let urlSearch = new URLSearchParams((search || "").split("?").join(""));

      if (
        document.referrer &&
        document.referrer.length > 0 &&
        (!urlSearch.get("source") || !urlSearch.get("channel"))
      ) {
        const _r = document.referrer.split("?")[1];
        urlSearch = new URLSearchParams((_r || "").split("?").join(""));
      }

      let body = {
        userId: await GetGaUserCode(),
        url: window.location.href.split("?")[0],
        createdAt: formatDate(),
        origin: document.referrer || "",
        channel: urlSearch.get("channel"),
        source: urlSearch.get("source"),
        utmSource: urlSearch.get("utm_source"),
        utmMedium: urlSearch.get("utm_medium"),
        utmCampaign: urlSearch.get("utm_campaign"),
        utmContent: urlSearch.get("utm_content"),
        utmTerm: urlSearch.get("utm_term"),
        page: urlSearch.get("click") || ""
      };

      const init = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
      };

      await fetch(URL, init);
    }, timeout);
  } catch (e) {
    console.log(e);
  }
}

async function GetGaUserCode() {
  try {
    let name = "_ga" + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c
          .substring(name.length, c.length)
          .split(".")
          .slice(-2)
          .join(".");
      }
    }

    return null;
  } catch (e) {
    return null;
  }
}

function formatDate(_date = new Date()) {
  let date = new Date(_date);
  return (
    [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join("-") +
    " " +
    [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(":")
  );
}

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}
