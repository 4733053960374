/* @flow
 */
import * as R from "ramda";
import type { Action } from "../../../types";

type State = {
  ui: {
    isFetchingPodcasts: boolean,
    isFetchingEventPodcasts: boolean
  },
  data: {
    podcasts: Array<Object>
  }
};

const initialState = {
  ui: {
    isFetchingPodcasts: false,
    isFetchingEventPodcasts: false
  },
  data: {
    podcasts: []
  }
};

//TODO testar essa logica de planos, warnings e erros
function podcastsReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case "PODCASTS/PODCASTS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingPodcasts"]);
      return R.set(fetchLens, true, state);
    }
    case "PODCASTS/PODCASTS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingPodcasts"]);
      return R.set(fetchLens, false, state);
    }
    case "PODCASTS/PODCASTS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingPodcasts"]);
      const podcastsLens = R.lensPath(["data", "podcasts"]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(podcastsLens, action.data)
      )(state);
    }

    case "PODCASTS/TRACKER_EVENTS_PODCAST_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingEventPodcasts"]);
      return R.set(fetchLens, true, state);
    }
    case "PODCASTS/TRACKER_EVENTS_PODCAST_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingEventPodcasts"]);
      return R.set(fetchLens, false, state);
    }
    case "PODCASTS/TRACKER_EVENTS_PODCAST_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingEventPodcasts"]);

      return R.compose(R.set(fetchLens, false))(state);
    }
    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}

export default podcastsReducer;
