import Cookies from 'js-cookie';
import config from "./config";

export const deleteToken = () => {
  localStorage.removeItem("bt:accessToken");
  const token_key =  "bt_token"; //config.tokenName || "bt_token";

  Cookies.remove(token_key, { path: '/', domain: extrairDominioPai() });
  Cookies.remove("bt_logged_in", { path: '/', domain: extrairDominioPai() });
};

export const setToken = (token, redirectToPlatformVersion, isB2B = false) => {
  localStorage.setItem("bt:accessToken", token);

  const token_key = "bt_token"; //config.tokenName || "bt_token";

  Cookies.set(token_key, token, { path: '/', domain: extrairDominioPai() });
  Cookies.set("bt_logged_in", "true", { path: "/", domain: extrairDominioPai() });

  // MANDA PRA PLATAFORMA NOVA QUEM NÃO É B2B
  // if(redirectToPlatformVersion && redirectToPlatformVersion === "v2" && !isB2B) {
  //   const PLATFORM_URL = config.env === 'production' ? 'https://app.aprovatotal.com.br/' : 'https://dev-app.aprovatotal.com.br/';
  //   window.location.href = PLATFORM_URL;
  // }
};

export const getToken = () => {
  const token_key = "bt_token"; //config.tokenName || "bt_token";
  const bt_token = Cookies.get(token_key);

  const storage_token = localStorage.getItem("bt:accessToken");

  return bt_token || storage_token;
};

function extrairDominioPai() {
  return config.cookieDomain;
}
