/**
 * Returns an area object
 *
 * @param {string} slug slug of an area.
 * @return {object} Returns area object.
 */

import jubiRenew from "../images/jubiRenew2.png";
import jubiExpires from "../images/jubiExpires.png";

export const freeTrialTexts = isFirstDay => {
  const textFirstDay = {
    titleOffer: "OFERTA RELÂMPAGO",
    offer: "ESTUDE TODAS AS DISCIPLINAS + REDAÇÃO PARA AS PROVAS",
    discount: "Desconto de R$ 30,00 para acesso à toda plataforma",
    fromPrice: "R$ 399,99",
    toPrice: "R$369,99",
    parceled: "R$30.83",
    button: "COMECE AGORA"
  };

  const textOthersDays = {
    titleOffer: "OFERTA RELÂMPAGO",
    offer: "ESTUDE TODAS AS DISCIPLINAS + REDAÇÃO PARA AS PROVAS",
    discount: "Desconto de R$ 20,00 para acesso à toda plataforma",
    fromPrice: "R$ 399,99",
    toPrice: " R$379,99",
    parceled: "R$31,67",
    button: "COMECE AGORA"
  };

  return isFirstDay ? textFirstDay : textOthersDays;
};

export const lockedContent = {
  title: "CONTEÚDO EXCLUSIVO",
  description:
    "Opa, esse conteúdo é exclusivo para alunos assinantes… <br /><br />Mas não fica triste, não! Você pode garantir acesso completo aos conteúdos e estudar com a Plataforma do Aprova clicando no botão abaixo ;)",
  button: "QUERO ACESSO COMPLETO"
  // descriptionButton: "Ou faça um cadastro e tenha acesso a conteúdos gratuitos por 7 dias!"
};

export const usedAllCreditsText = {
  header: "Seus créditos acabaram",
  img: `${jubiExpires}`,
  title: "Você acabou de usar seu último crédito para visualizar os conteúdos do Aprova...",
  text: "Quer continuar estudando com a Plataforma e garantir acesso a todos os conteúdos e funcionalidades?",
  textDiscount: "É só clicar no botão abaixo e escolher seu plano de assinatura! ;)",
  button: "QUERO ACESSO COMPLETO"
};
export const expiresTodayText = {
  header: "Acesso expira hoje",
  title: "HEY, HOJE É SEU ÚLTIMO DIA EXPERIMENTAR A PLATAFORMA",
  text: " Já navegou por tudo? Tá na hora de cair pra dentro e garantir acesso completo aos nossos conteúdos!",
  // textDiscount: "Aproveita o desconto especial que liberamos pra você!",
  img: `${jubiRenew}`,
  // value: "12x R$30.83",
  button: "QUERO ESTUDAR COM O APROVA"
};

export const expireText = {
  header: "Seu acesso expirou",
  img: `${jubiExpires}`,
  title: "SEU ACESSO AO EXPERIMENTE ACABOU",
  text: "Mas não fica triste, ainda dá tempo de garantir acesso completo ao Aprova!",
  // textDiscount: "Estude para o ENEM e Vestibulares com um desconto especial",
  // value: "12x de R$30.83",
  button: "QUERO ESTUDAR COM O APROVA"
};
