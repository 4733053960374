import * as R from "ramda";
import moment from "moment";

const initialState = {
  ui: {
    isFetchingNews: false,
    isFetchingCourses: false,
    isFetchingSchedule: false,
    isFetchingCronogram: false,
    isFetchingLatestLessons: false,
    selectedDay: moment().format("MM/DD/YYYY")
  },
  data: {
    news: [],
    courses: [],
    cronogram: [],
    latestLessons: [],
    selectedCronogram: [],
    alreadyHadFreeTrial: false
  }
};

function myAreaReducer(state = initialState, action) {
  switch (action.type) {
    // CURSOS
    // ----------------------
    case "MY_AREA/COURSES_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingCourses"]);
      return R.set(fetchLens, true, state);
    }
    case "MY_AREA/COURSES_SUCCESS": {
      const dataLens = R.lensPath(["data", "courses"]);
      const fetchLens = R.lensPath(["ui", "isFetchingCourses"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "MY_AREA/COURSES_FAILURE": {
      const dataLens = R.lensPath(["data", "courses"]);
      const fetchLens = R.lensPath(["ui", "isFetchingCourses"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }
    // NOVIDADES
    // ----------------------
    case "MY_AREA/NEWS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingNews"]);
      return R.set(fetchLens, true, state);
    }
    case "MY_AREA/NEWS_SUCCESS": {
      const dataLens = R.lensPath(["data", "news"]);
      const fetchLens = R.lensPath(["ui", "isFetchingNews"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "MY_AREA/NEWS_FAILURE": {
      const dataLens = R.lensPath(["data", "news"]);
      const fetchLens = R.lensPath(["ui", "isFetchingNews"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    // CRONOGRAMA
    // ----------------------
    case "MY_AREA/CRONOGRAM_SELECT_DAY": {
      const filterLens = R.lensPath(["data", "selectedCronogram"]);
      const date = {
        selectedDay: action.selectedDay,
        period: action.period
      };

      return R.set(filterLens, date, state);
    }
    case "MY_AREA/DAY_ACTIVE_CHANGED": {
      const filterLens = R.lensPath(["ui", "selectedDay"]);

      return R.set(filterLens, action.filter, state);
    }
    case "MY_AREA/CRONOGRAM_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingCronogram"]);
      return R.set(fetchLens, true, state);
    }
    case "MY_AREA/CRONOGRAM_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingCronogram"]);
      const cronogramLens = R.lensPath(["data", "cronogram"]);

      return R.compose(R.set(fetchLens, false), R.set(cronogramLens, action.data))(state);
    }
    case "MY_AREA/CRONOGRAM_FAILURE": {
      const cronogramLens = R.lensPath(["data", "cronogram"]);
      const fetchLens = R.lensPath(["ui", "isFetchingCronogram"]);

      return R.compose(R.set(cronogramLens, []), R.set(fetchLens, false))(state);
    }
    case "MY_AREA/CRONOGRAM_CREATE_NOTE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingCronogram"]);
      return R.set(fetchLens, true, state);
    }
    case "MY_AREA/CRONOGRAM_CREATE_NOTE_SUCCESS": {
      const cronogramLens = R.lensPath(["data", "cronogram"]);
      const fetchLens = R.lensPath(["ui", "fetchingCronogram"]);

      return R.compose(R.set(cronogramLens, action.data), R.set(fetchLens, false))(state);
    }
    case "MY_AREA/CRONOGRAM_CREATE_NOTE_FAILURE": {
      const cronogramLens = R.lensPath(["data", "cronogram"]);
      const fetchLens = R.lensPath(["ui", "fetchingCronogram"]);

      return R.compose(R.set(cronogramLens, []), R.set(fetchLens, false))(state);
    }

    // ADICIONADOS RECENTEMENTE
    // ----------------------
    case "MY_AREA/LATEST_LESSONS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingLatestLessons"]);
      return R.set(fetchLens, true, state);
    }
    case "MY_AREA/LATEST_LESSONS_SUCCESS": {
      const dataLens = R.lensPath(["data", "latestLessons"]);
      const fetchLens = R.lensPath(["ui", "isFetchingLatestLessons"]);
      const timestampLens = R.lensPath(["timestamps", "latestLessons"]);

      return R.compose(
        R.set(dataLens, action.data),
        R.set(fetchLens, false),
        R.set(timestampLens, new Date().valueOf())
      )(state);
    }
    case "MY_AREA/LATEST_LESSONS_FAILURE": {
      const dataLens = R.lensPath(["data", "latestLessons"]);
      const fetchLens = R.lensPath(["ui", "isFetchingLatestLessons"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    // EXPERIMENTE GRÁTIS
    // -----------------------------
    case "MY_AREA/REQUEST_UPDATE_USER": {
      const lens = R.lensPath(["ui", "fetchingUpdateUser"]);
      return R.set(lens, true, state);
    }
    case "MY_AREA/REQUEST_UPDATE_USER_FAILURE": {
      const lens = R.lensPath(["ui", "fetchingUpdateUser"]);
      return R.set(lens, false, state);
    }
    case "MY_AREA/REQUEST_UPDATE_USER_SUCCESS": {
      const lens = R.lensPath(["ui", "fetchingUpdateUser"]);
      const dataLens = R.lensPath(["data", "alreadyHadFreeTrial"]);

      return R.compose(R.set(lens, false), R.set(dataLens, true))(state);
    }

    // CALENDÁRIO
    // -----------------------------
    case "MY_AREA/SCHEDULE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSchedule"]);
      return R.set(fetchLens, true, state);
    }
    case "MY_AREA/SCHEDULE_SUCCESS": {
      const dataLens = R.lensPath(["data", "schedule"]);
      const fetchLens = R.lensPath(["ui", "isFetchingSchedule"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "MY_AREA/SCHEDULE_FAILURE": {
      const dataLens = R.lensPath(["data", "schedule"]);
      const fetchLens = R.lensPath(["ui", "isFetchingSchedule"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    default:
      return state;
  }
}

export default myAreaReducer;
