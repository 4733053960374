import * as R from "ramda";
import moment from "moment";

const initialState = {
  ui: {
    fetchingQuestions: false,
    fetchingCourses: false,
    fetchingOnboarding: false,
    fetchingNewOnboarding: false,
    fetchingStatistics: false,
    fetchingUpdateUser: false,
    fetchingExercisesStatistics: false,
    fetchingRanking: false,
    fetchingLatestLessons: false,
    fetchingSchedule: false,
    fetchingCommunity: false,
    selectedDay: moment().format("MM/DD/YYYY"),
    fetchingRankingSimulation: false,
    fetchingCronogram: false,
    isFetchingFreeTrialDashboard: false
  },
  data: {
    freeTrial: {},
    alreadyHadFreeTrial: false,
    community: { user: [], general: [] },
    questions: [],
    courses: [],
    statistics: {
      practicetestsSituation: {
        answered: 0,
        answeredCorrectly: 0
      },
      subareasSituation: {
        one: { title: "", slug: "" },
        two: { title: "", slug: "" }
      }
    },
    exercisesStatistics: {
      answered: 0,
      answeredCorrectly: 0,
      answeredWrong: 0
    },
    ranking: {
      user: {
        position: 0,
        score: 0
      },
      ranking: [],
      totalParticipants: 0
    },
    schedule: [],
    onboarding: [],
    newOnboarding: [],
    latestLessons: [],
    rankingSimulation: {},
    cronogram: [],
    selectedCronogram: []
  },

  timestamps: {
    courses: null,
    onboarding: null,
    practicetestsStatistics: null,
    exercisesStattistics: null,
    latestLessons: null,
    schedule: null,
    community: null
  }
};

function homeReducer(state = initialState, action) {
  switch (action.type) {
    case "HOME/COURSE_AD_EVENT_REQUEST": {
      const coursesLens = R.lensPath(["data", "courses"]);
      const newCourseList = state.data.courses.filter(it => it.id !== action.courseId);

      return R.compose(R.set(coursesLens, newCourseList))(state);
    }
    case "HOME/RANKING_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingRanking"]);
      return R.set(fetchLens, true, state);
    }
    case "HOME/RANKING_SUCCESS": {
      const dataLens = R.lensPath(["data", "ranking"]);
      const fetchLens = R.lensPath(["ui", "fetchingRanking"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "HOME/RANKING_FAILURE": {
      const dataLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingRanking"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }
    case "HOME/RANKING_SIMULATION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingRankingSimulation"]);
      return R.set(fetchLens, true, state);
    }
    case "HOME/RANKING_SIMULATION_SUCCESS": {
      const dataLens = R.lensPath(["data", "rankingSimulation"]);
      const fetchLens = R.lensPath(["ui", "fetchingRankingSimulation"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "HOME/RANKING_SIMULATION_FAILURE": {
      const dataLens = R.lensPath(["data", "rankingSimulation"]);
      const fetchLens = R.lensPath(["ui", "fetchingRankingSimulation"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "HOME/REQUEST_UPDATE_USER": {
      const lens = R.lensPath(["ui", "fetchingUpdateUser"]);
      return R.set(lens, true, state);
    }
    case "HOME/REQUEST_UPDATE_USER_FAILURE": {
      const lens = R.lensPath(["ui", "fetchingUpdateUser"]);
      return R.set(lens, false, state);
    }
    case "HOME/REQUEST_UPDATE_USER_SUCCESS": {
      const lens = R.lensPath(["ui", "fetchingUpdateUser"]);
      const dataLens = R.lensPath(["data", "alreadyHadFreeTrial"]);

      return R.compose(R.set(lens, false), R.set(dataLens, true))(state);
    }

    case "HOME/FREETRIAL_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingFreeTrialDashboard"]);
      return R.set(fetchLens, true, state);
    }
    case "HOME/FREETRIAL_REQUEST_SUCCESS": {
      const dataLens = R.lensPath(["data", "freeTrial"]);
      const fetchLens = R.lensPath(["ui", "isFetchingFreeTrialDashboard"]);

      return R.compose(R.set(dataLens, action.data), R.set(fetchLens, false))(state);
    }
    case "HOME/FREETRIAL_REQUEST_FAILURE": {
      const dataLens = R.lensPath(["data", "freeTrial"]);
      const fetchLens = R.lensPath(["ui", "isFetchingFreeTrialDashboard"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }

    case "HOME/COMMUNITY_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingCommunity"]);
      return R.set(fetchLens, true, state);
    }
    case "HOME/COMMUNITY_SUCCESS": {
      const dataLens = R.lensPath(["data", "community"]);
      const fetchLens = R.lensPath(["ui", "fetchingCommunity"]);
      const timestampLens = R.lensPath(["timestamps", "community"]);

      return R.compose(
        R.set(dataLens, action.data),
        R.set(fetchLens, false),
        R.set(timestampLens, new Date().valueOf())
      )(state);
    }
    case "HOME/COMMUNITY_FAILURE": {
      const dataLens = R.lensPath(["data", "community"]);
      const fetchLens = R.lensPath(["ui", "fetchingCommunity"]);

      return R.compose(R.set(dataLens, { user: [], general: [] }), R.set(fetchLens, false))(state);
    }

    case "HOME/QUESTIONS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingQuestions"]);
      return R.set(fetchLens, true, state);
    }
    case "HOME/QUESTIONS_SUCCESS": {
      const questionsLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingQuestions"]);

      return R.compose(R.set(questionsLens, action.data), R.set(fetchLens, false))(state);
    }
    case "HOME/QUESTIONS_FAILURE": {
      const questionsLens = R.lensPath(["data", "questions"]);
      const fetchLens = R.lensPath(["ui", "fetchingQuestions"]);

      return R.compose(R.set(questionsLens, []), R.set(fetchLens, false))(state);
    }

    //AQUI
    case "HOME/COURSES_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingCourses"]);
      return R.set(fetchLens, true, state);
    }
    case "HOME/COURSES_SUCCESS": {
      const coursesLens = R.lensPath(["data", "courses"]);
      const fetchLens = R.lensPath(["ui", "fetchingCourses"]);
      const timestampLens = R.lensPath(["timestamps", "courses"]);

      return R.compose(
        R.set(coursesLens, action.data),
        R.set(fetchLens, false),
        R.set(timestampLens, new Date().valueOf())
      )(state);
    }
    case "HOME/COURSES_FAILURE": {
      const coursesLens = R.lensPath(["data", "courses"]);
      const fetchLens = R.lensPath(["ui", "fetchingCourses"]);

      return R.compose(R.set(coursesLens, []), R.set(fetchLens, false))(state);
    }

    case "HOME/STATISTICS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingStatistics"]);
      return R.set(fetchLens, true, state);
    }
    case "HOME/STATISTICS_SUCCESS": {
      const statisticsLens = R.lensPath(["data", "statistics"]);
      const fetchLens = R.lensPath(["ui", "fetchingStatistics"]);
      const timestampLens = R.lensPath(["timestamps", "practicetestsStatistics"]);

      return R.compose(
        R.set(statisticsLens, action.data),
        R.set(fetchLens, false),
        R.set(timestampLens, new Date().valueOf())
      )(state);
    }
    case "HOME/STATISTICS_FAILURE": {
      const statisticsLens = R.lensPath(["data", "statistics"]);
      const fetchLens = R.lensPath(["ui", "fetchingStatistics"]);

      return R.compose(R.set(statisticsLens, []), R.set(fetchLens, false))(state);
    }

    case "HOME/STATISTICS_EXERCISES_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingExercisesStatistics"]);
      return R.set(fetchLens, true, state);
    }
    case "HOME/STATISTICS_EXERCISES_SUCCESS": {
      const statisticsLens = R.lensPath(["data", "exercisesStatistics"]);
      const fetchLens = R.lensPath(["ui", "fetchingExercisesStatistics"]);

      return R.compose(R.set(statisticsLens, action.data), R.set(fetchLens, false))(state);
    }
    case "HOME/STATISTICS_EXERCISES_FAILURE": {
      const statisticsLens = R.lensPath(["data", "exercisesStatistics"]);
      const fetchLens = R.lensPath(["ui", "fetchingExercisesStatistics"]);

      return R.compose(R.set(statisticsLens, []), R.set(fetchLens, false))(state);
    }

    case "HOME/UNENROLLCOURSES_SUCCESS": {
      const coursesLens = R.lensPath(["data", "courses"]);

      return R.set(coursesLens, action.data, state);
    }

    case "HOME/SCHEDULE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingSchedule"]);
      return R.set(fetchLens, true, state);
    }
    case "HOME/SCHEDULE_SUCCESS": {
      const scheduleLens = R.lensPath(["data", "schedule"]);
      const fetchLens = R.lensPath(["ui", "fetchingSchedule"]);
      const timestampLens = R.lensPath(["timestamps", "schedule"]);

      return R.compose(
        R.set(scheduleLens, action.data),
        R.set(fetchLens, false),
        R.set(timestampLens, new Date().valueOf())
      )(state);
    }
    case "HOME/SCHEDULE_FAILURE": {
      const scheduleLens = R.lensPath(["data", "schedule"]);
      const fetchLens = R.lensPath(["ui", "fetchingSchedule"]);

      return R.compose(R.set(scheduleLens, []), R.set(fetchLens, false))(state);
    }

    case "HOME/REQUEST_VERIFY_HISTORY": {
      const fetchLens = R.lensPath(["ui", "fetchingOnboarding"]);
      return R.set(fetchLens, true, state);
    }
    case "HOME/REQUEST_VERIFY_HISTORY_SUCCESS": {
      const scheduleLens = R.lensPath(["data", "onboarding"]);
      const fetchLens = R.lensPath(["ui", "fetchingOnboarding"]);

      return R.compose(R.set(scheduleLens, action.data), R.set(fetchLens, true))(state);
    }
    case "HOME/REQUEST_VERIFY_HISTORY_FAILURE": {
      const scheduleLens = R.lensPath(["data", "onboarding"]);
      const fetchLens = R.lensPath(["ui", "fetchingOnboarding"]);

      return R.compose(R.set(scheduleLens, []), R.set(fetchLens, false))(state);
    }

    case "HOME/REQUEST_VERIFY_ONBOARDING": {
      const fetchLens = R.lensPath(["ui", "fetchingNewOnboarding"]);
      return R.set(fetchLens, true, state);
    }
    case "HOME/REQUEST_VERIFY_ONBOARDING_SUCCESS": {
      const scheduleLens = R.lensPath(["data", "newOnboarding"]);
      const fetchLens = R.lensPath(["ui", "fetchingNewOnboarding"]);
      const timestampLens = R.lensPath(["timestamps", "onboarding"]);

      return R.compose(
        R.set(scheduleLens, action.data),
        R.set(fetchLens, true),
        R.set(timestampLens, new Date().valueOf())
      )(state);
    }
    case "HOME/REQUEST_VERIFY_ONBOARDING_FAILURE": {
      const scheduleLens = R.lensPath(["data", "newOnboarding"]);
      const fetchLens = R.lensPath(["ui", "fetchingNewOnboarding"]);

      return R.compose(R.set(scheduleLens, []), R.set(fetchLens, false))(state);
    }

    case "HOME/DAY_ACTIVE_CHANGED": {
      const filterLens = R.lensPath(["ui", "selectedDay"]);

      return R.set(filterLens, action.filter, state);
    }

    case "HOME/LATEST_LESSONS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingLatestLessons"]);
      return R.set(fetchLens, true, state);
    }
    case "HOME/LATEST_LESSONS_SUCCESS": {
      const dataLens = R.lensPath(["data", "latestLessons"]);
      const fetchLens = R.lensPath(["ui", "fetchingLatestLessons"]);
      const timestampLens = R.lensPath(["timestamps", "latestLessons"]);

      return R.compose(
        R.set(dataLens, action.data),
        R.set(fetchLens, false),
        R.set(timestampLens, new Date().valueOf())
      )(state);
    }
    case "HOME/LATEST_LESSONS_FAILURE": {
      const dataLens = R.lensPath(["data", "latestLessons"]);
      const fetchLens = R.lensPath(["ui", "fetchingLatestLessons"]);

      return R.compose(R.set(dataLens, []), R.set(fetchLens, false))(state);
    }
    case "HOME/CRONOGRAM_SELECT_DAY": {
      const filterLens = R.lensPath(["data", "selectedCronogram"]);
      const date = {
        selectedDay: action.selectedDay,
        period: action.period
      };

      return R.set(filterLens, date, state);
    }
    case "HOME/CRONOGRAM_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingCronogram"]);
      return R.set(fetchLens, true, state);
    }
    case "HOME/CRONOGRAM_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "fetchingCronogram"]);
      const cronogramLens = R.lensPath(["data", "cronogram"]);
      // const timestampLens = R.lensPath(["timestamps", "Cronogram"]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(cronogramLens, action.data)
        // R.set(timestampLens, new Date().valueOf())
      )(state);
    }
    case "HOME/CRONOGRAM_FAILURE": {
      const cronogramLens = R.lensPath(["data", "cronogram"]);
      const fetchLens = R.lensPath(["ui", "fetchingCronogram"]);

      return R.compose(R.set(cronogramLens, []), R.set(fetchLens, false))(state);
    }
    case "HOME/CRONOGRAM_CREATE_NOTE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "fetchingCronogram"]);
      return R.set(fetchLens, true, state);
    }
    case "HOME/CRONOGRAM_CREATE_NOTE_SUCCESS": {
      const cronogramLens = R.lensPath(["data", "cronogram"]);
      const fetchLens = R.lensPath(["ui", "fetchingCronogram"]);
      // const timestampLens = R.lensPath(["timestamps", "cronogram"]);

      return R.compose(
        R.set(cronogramLens, action.data),
        R.set(fetchLens, false)
        // R.set(timestampLens, new Date().valueOf())
      )(state);
    }
    case "HOME/CRONOGRAM_CREATE_NOTE_FAILURE": {
      const cronogramLens = R.lensPath(["data", "Cronogram"]);
      const fetchLens = R.lensPath(["ui", "fetchingCronogram"]);

      return R.compose(R.set(cronogramLens, []), R.set(fetchLens, false))(state);
    }
    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}

export default homeReducer;
