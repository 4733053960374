import * as R from "ramda";

const initialState = {
  ui: {
    isToggleModal: false,
    selectedFilter: "all",
    isFetchingExercise: false,
    isFetchingExercises: false,
    isFetchingQuestions: false,
    isFetchingQuestionsByOrigin: false,
    isFetchingLikeOriginQuestion: false
  },
  data: {
    exercises: [],
    exercise: {},
    totalExercises: null,
    printExercises: []
  }
};

function exercisesReducer(state = initialState, action) {
  switch (action.type) {
    case "EXERCISES/PRINT_EXERCISES_LIST_RESPONSE_SUCCESS": {
      const printExercises = R.lensPath(["data", "printExercises"]);

      return R.compose(R.set(printExercises, action.exercises))(state);
    }
    case "EXERCISES/CLEAR_USER_ANSWER": {
      const userAnswerLens = R.lensPath(["data", "exercise", action.slug, "userAnswer"]);
      const isAnsweredLens = R.lensPath(["data", "exercise", action.slug, "isAnswered"]);

      return R.compose(R.set(userAnswerLens, []), R.set(isAnsweredLens, false))(state);
    }
    case "EXERCISES/SEND_EXERCISE_REQUEST": {
      const exerciseLens = R.lensPath(["data", "exercise", action.slug, "isAnswered"]);

      return R.set(exerciseLens, true, state);
    }
    case "EXERCISES/UPDATE_USER_ANSWER": {
      const exerciseLens = R.lensPath(["data", "exercise", action.exerciseSlug, "userAnswer"]);

      return R.set(exerciseLens, action.data, state);
    }
    case "EXERCISES/UPDATE_FILTER": {
      const filterLens = R.lensPath(["ui", "selectedFilter"]);

      return R.set(filterLens, action.filter, state);
    }

    case "EXERCISES/TOGGLE_MODAL_EXERCISE": {
      const filterLens = R.lensPath(["ui", "isToggleModal"]);

      return R.set(filterLens, action.modal, state);
    }

    case "EXERCISES/INTERNAL_EXERCISES_REQUEST":
    case "EXERCISES/EXERCISES_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingExercises"]);

      const page = action.params.page || 1;
      const isFirstPage = page === 1;

      return R.compose(R.set(fetchLens, isFirstPage))(state);
    }
    case "EXERCISES/INTERNAL_EXERCISES_ERROR":
    case "EXERCISES/EXERCISES_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingExercises"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "EXERCISES/INTERNAL_EXERCISES_SUCCESS":
    case "EXERCISES/EXERCISES_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingExercises"]);
      const exercisesLens = R.lensPath(["data", "exercises"]);
      const totalExercisesLens = R.lensPath(["data", "totalExercises"]);

      let newExercises = action.data.exercises;
      if (action.page > 1) {
        newExercises = [...state.data.exercises.exercises, ...newExercises];
      }

      const replacement = {
        exercises: newExercises,
        subarea: action.data.subarea
      };

      return R.compose(
        R.set(fetchLens, false),
        R.set(exercisesLens, replacement),
        R.set(totalExercisesLens, action.data.totalExercises)
      )(state);
    }

    case "EXERCISES/INTERNAL_REGIONAL_EXERCISES_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingExercises"]);
      return R.compose(R.set(fetchLens))(state);
    }
    case "EXERCISES/INTERNAL_REGIONAL_EXERCISES_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingExercises"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "EXERCISES/INTERNAL_REGIONAL_EXERCISES_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingExercises"]);
      const exercisesLens = R.lensPath(["data", "exercises"]);
      const totalExercisesLens = R.lensPath(["data", "totalExercises"]);

      let newExercises = action.data.data;

      const replacement = {
        exercises: newExercises,
        subarea: ""
      };

      return R.compose(
        R.set(fetchLens, false),
        R.set(exercisesLens, replacement),
        R.set(totalExercisesLens, action.data.length || 0)
      )(state);
    }

    case "EXERCISES/EXERCISE_DETAILS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingExercise"]);
      return R.set(fetchLens, true, state);
    }
    case "EXERCISES/EXERCISE_DETAILS_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingExercise"]);
      return R.set(fetchLens, false, state);
    }
    case "EXERCISES/EXERCISE_DETAILS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingExercise"]);
      const exerciseSlugLens = R.lensPath(["data", "exercise", action.data.slug]);

      return R.compose(R.set(fetchLens, false), R.set(exerciseSlugLens, action.data))(state);
    }
    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}

export default exercisesReducer;
