/* @flow */
import * as R from "ramda";

const initialState = {
  ui: {
    isFetchingModule: false,
    isFetchingModules: false,
    isFetchingModuleSummary: false,
    isFetchingSelectedContent: false
  },
  data: {
    modules: [],
    module: {},
    selectedContent: {}
  }
};

function coursesReducer(state = initialState, action) {
  switch (action.type) {
    case "MILZAO/LOAD_REQUEST": {
      const fetchingLens = R.lensPath(["ui", "isFetchingModuleSummary"]);

      return R.set(fetchingLens, true, state);
    }
    case "MILZAO/LOAD_RESPONSE_SUCCESS": {
      const summaryLens = R.lensPath(["data", "module", action.module]);
      const fetchingLens = R.lensPath(["ui", "isFetchingModuleSummary"]);
      return R.compose(R.set(summaryLens, action.data), R.set(fetchingLens, false))(state);
    }
    case "MILZAO/LOAD_RESPONSE_FAILURE": {
      const fetchingLens = R.lensPath(["ui", "isFetchingModuleSummary"]);
      const summaryLens = R.lensPath(["data", "module", action.module]);
      return R.compose(
        R.set(summaryLens, { title: action.error.module, ...action.error }),
        R.set(fetchingLens, false)
      )(state);
    }
    case "MILZAO/MODULES_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingModules"]);

      return R.set(fetchLens, true, state);
    }
    case "MILZAO/MODULES_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingModules"]);

      return R.compose(R.set(fetchLens, false))(state);
    }
    case "MILZAO/MODULES_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingModules"]);
      const lens = R.lensPath(["data", "modules"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }

    case "MILZAO/MODULE_REQUEST": {
      const fetchingLens = R.lensPath(["ui", "isFetchingModuleSummary"]);

      return R.set(fetchingLens, true, state);
    }
    case "MILZAO/MODULE_RESPONSE_FAILURE": {
      const fetchingLens = R.lensPath(["ui", "isFetchingModuleSummary"]);
      const summaryLens = R.lensPath(["data", "module", action.module]);
      return R.compose(
        R.set(summaryLens, { title: action.error.module, ...action.error }),
        R.set(fetchingLens, false)
      )(state);
    }
    case "MILZAO/MODULE_RESPONSE_SUCCESS": {
      const summaryLens = R.lensPath(["data", "module", action.module]);
      const fetchingLens = R.lensPath(["ui", "isFetchingModuleSummary"]);

      return R.compose(R.set(summaryLens, action.data), R.set(fetchingLens, false))(state);
    }
    case "MILZAO/LESSON_REQUEST": {
      const fetchingLens = R.lensPath(["ui", "isFetchingSelectedContent"]);

      return R.set(fetchingLens, true, state);
    }
    case "MILZAO/LESSON_RESPONSE_FAILURE": {
      const fetchingLens = R.lensPath(["ui", "isFetchingSelectedContent"]);
      const dataLens = R.lensPath(["data", "selectedContent"]);
      return R.compose(R.set(dataLens, {}), R.set(fetchingLens, false))(state);
    }
    case "MILZAO/LESSON_RESPONSE_SUCCESS": {
      const summaryLens = R.lensPath(["data", "selectedContent"]);
      const fetchingLens = R.lensPath(["ui", "isFetchingSelectedContent"]);
      return R.compose(R.set(summaryLens, action.data), R.set(fetchingLens, false))(state);
    }
    case "MILZAO/MATERIAL_REQUEST": {
      const fetchingLens = R.lensPath(["ui", "isFetchingSelectedContent"]);

      return R.set(fetchingLens, true, state);
    }
    case "MILZAO/MATERIAL_RESPONSE_FAILURE": {
      const fetchingLens = R.lensPath(["ui", "isFetchingSelectedContent"]);
      const dataLens = R.lensPath(["data", "selectedContent"]);
      return R.compose(R.set(dataLens, {}), R.set(fetchingLens, false))(state);
    }
    case "MILZAO/MATERIAL_RESPONSE_SUCCESS": {
      const summaryLens = R.lensPath(["data", "selectedContent"]);
      const fetchingLens = R.lensPath(["ui", "isFetchingSelectedContent"]);
      return R.compose(R.set(summaryLens, action.data), R.set(fetchingLens, false))(state);
    }
    case "MILZAO/FINISH_MILZAO_REQUEST": {
      const fetchingLens = R.lensPath(["ui", "isFetchingModule"]);
      return R.set(fetchingLens, true, state);
    }
    case "MILZAO/FINISH_MILZAO_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingModule"]);

      return R.set(fetchLens, false, state);
    }
    case "MILZAO/FINISH_MILZAO_SUCCESS": {
      const summaryLens = R.lensPath(["data", "modules"]);
      const fetchLens = R.lensPath(["ui", "isFetchingModule"]);

      const modules = action.module;
      const indexContent = modules.content.findIndex(it => it._id === action.materialId);
      modules.content[indexContent].view = true;

      return R.compose(R.set(summaryLens, modules), R.set(fetchLens, false))(state);
    }

    case "APP/CLEAN_STATE": {
      return initialState;
    }

    default:
      return state;
  }
}

export default coursesReducer;
