import type { Action } from "../../../types";
import * as R from "ramda";

type State = {
  ui: {
    isFetchingSimulation: boolean
  },
  data: {
    simulation: Object
  }
};

const initialState = {
  ui: {
    showSimulationInstructions: false,
    isFetchingSimulation: false,
    isFetchingSimulationLastFeedback: false,
    isFetchingLastSimulation: false,
    isFetchingLastAllSimulation: false,
    isFetchingSimulationAverage: false,
    isFetchingSimulationStart: false,
    isFetchingSimulationOpen: false,
    isFetchingSimulationFinish: false,
    isFetchingSimulationSetAnswer: false,
    isFetchingSimulationRanking: false,
    isFetchingSimulationCompleteRanking: false,
    isFetchingSimulationFeedback: false,
    isFetchingLastOneSimulation: false,
    isFetchingServerTime: false,
    // Gerador de Simulados
    isFetchingFilters: false,
    isCreatingSimulation: false,
    isFetchingEssayTheme: false,
    isFetchingEssay: false,
    isFetchingSimulationsList: false,
    isFetchingSimulationDetails: false,
    isSubmittingSimulationAnswers: false,
    isSubscriptionSimulation: false,
    isFetchingEssayFeedback: false,
    isFetchingInitDayTwo: false,
  },
  data: {
    serverTime: "",
    simulation: {},
    essayFeedback: {},
    simulationSpotlight: {},
    history: {},
    ranking: {},
    completeRanking: [],
    feedback: {},
    lastFeedback: {},
    lastSimulations: [],
    lastAllSimulations: {},
    lastSimulation: {},
    cachedLastSimulations: null,
    cachedLastAllSimulations: null,
    // Gerador de Simulados
    filters: {},
    userCourse: {},
    simulations: [],
    essayTheme: {},
    createdSimulation: {},
    essayByUser: {},
    responses: {}
  }
};

export function simulationReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case "SIMULATION/SIMULATION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulation"]);

      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulation"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/SIMULATION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulation"]);
      const lens = R.lensPath(["data", "simulation"]);
      const serverTimeLens = R.lensPath(["data", "serverTime"]);
      const spotlightLens = R.lensPath(["data", "simulationSpotlight"]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(lens, action.data),
        R.set(serverTimeLens, action.data.time),
        R.set(spotlightLens, action.data)
      )(state);
    }

    case "SIMULATION/SIMULATION_AVERAGE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationAverage"]);

      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_AVERAGE_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationAverage"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/SIMULATION_AVERAGE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationAverage"]);
      const lens = R.lensPath(["data", "simulation", "average"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }

    case "SIMULATION/SIMULATION_START_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationStart"]);

      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_START_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationStart"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/SIMULATION_START_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationStart"]);
      const simulation = R.lensPath(["data", "simulation"]);
      const history = R.lensPath(["data", "history"]);

      let newHistory = action.data.history || {};
      let newSimulation = action.data.simulated || {};

      return R.compose(R.set(fetchLens, false), R.set(history, newHistory), R.set(simulation, newSimulation))(state);
    }

    case "SIMULATION/SIMULATION_SET_ANSWER_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationSetAnswer"]);

      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_SET_ANSWER_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationSetAnswer"]);

      return R.compose(R.set(fetchLens, false))(state);
    }
    case "SIMULATION/SIMULATION_SET_ANSWER_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationSetAnswer"]);
      const serverTimeLens = R.lensPath(["data", "serverTime"]);
      const history = R.lensPath(["data", "history"]);

      let newHistory = action.data;
      if (!newHistory.user) {
        newHistory = state.data.history;
      }
      return R.compose(
        R.set(fetchLens, false),
        R.set(serverTimeLens, action.data.time),
        R.set(history, newHistory)
      )(state);
    }

    case "SIMULATION/SIMULATION_OPEN_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationOpen"]);

      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_OPEN_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationOpen"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/SIMULATION_OPEN_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationOpen"]);
      const simulation = R.lensPath(["data", "simulation"]);
      const history = R.lensPath(["data", "history"]);

      let newHistory = action.data.history || {};
      let newSimulation = action.data.simulated || {};

      return R.compose(R.set(fetchLens, false), R.set(history, newHistory), R.set(simulation, newSimulation))(state);
    }

    case "SIMULATION/SIMULATION_FINISH_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationFinish"]);

      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_FINISH_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationFinish"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/SIMULATION_FINISH_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationFinish"]);
      const simulation = R.lensPath(["data", "simulation"]);
      const history = R.lensPath(["data", "history"]);

      let newHistory = action.data || {};
      let newSimulation = state.data.simulation;
      newSimulation.isFinished = true;
      newSimulation.questions = [];

      return R.compose(R.set(fetchLens, false), R.set(history, newHistory), R.set(simulation, newSimulation))(state);
    }

    case "SIMULATION/SIMULATION_RANKING_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationRanking"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_RANKING_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationRanking"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/SIMULATION_RANKING_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationRanking"]);
      const simulation = R.lensPath(["data", "ranking"]);

      return R.compose(R.set(fetchLens, false), R.set(simulation, action.data))(state);
    }

    case "SIMULATION/SIMULATION_FEEDBACK_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationFeedback"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_FEEDBACK_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationFeedback"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/SIMULATION_FEEDBACK_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationFeedback"]);
      const feedback = R.lensPath(["data", "feedback"]);
      const simulation = R.lensPath(["data", "simulation"]);

      let simulationData = state.data.simulation;
      simulationData.isFinished = true;
      simulationData.isStarted = true;

      return R.compose(R.set(fetchLens, false), R.set(feedback, action.data), R.set(simulation, simulationData))(state);
    }
    case "SIMULATION/SIMULATION_LAST_FEEDBACK_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationLastFeedback"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_LAST_FEEDBACK_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationLastFeedback"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/SIMULATION_LAST_FEEDBACK_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationLastFeedback"]);
      const feedback = R.lensPath(["data", "lastFeedback"]);

      return R.compose(R.set(fetchLens, false), R.set(feedback, action.data))(state);
    }

    case "SIMULATION/SIMULATION_COMPLETE_RANKING_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationCompleteRanking"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_COMPLETE_RANKING_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationCompleteRanking"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/SIMULATION_COMPLETE_RANKING_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationCompleteRanking"]);
      const simulation = R.lensPath(["data", "completeRanking"]);

      return R.compose(R.set(fetchLens, false), R.set(simulation, action.data))(state);
    }

    case "SIMULATION/SIMULATION_LAST_ALL_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingLastAllSimulation"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_LAST_ALLFAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingLastAllSimulation"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/SIMULATION_LAST_ALL_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingLastAllSimulation"]);
      const simulation = R.lensPath(["data", "lastAllSimulations"]);
      const cachedLastAllSimulations = R.lensPath(["data", "cachedLastAllSimulations"]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(simulation, action.data),
        R.set(cachedLastAllSimulations, action.data)
      )(state);
    }

    case "SIMULATION/SIMULATION_LAST_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingLastSimulation"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_LAST_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingLastSimulation"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/SIMULATION_LAST_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingLastSimulation"]);
      const simulation = R.lensPath(["data", "lastSimulations"]);
      const cachedLastSimulations = R.lensPath(["data", "cachedLastSimulations"]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(simulation, action.data),
        R.set(cachedLastSimulations, action.data)
      )(state);
    }

    case "SIMULATION/ONE_SIMULATION_LAST_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingLastOneSimulation"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/ONE_SIMULATION_LAST_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingLastOneSimulation"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/ONE_SIMULATION_LAST_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingLastOneSimulation"]);
      const simulation = R.lensPath(["data", "lastSimulation"]);

      return R.compose(R.set(fetchLens, false), R.set(simulation, action.data))(state);
    }
    case "SIMULATION/UPDATE_SERVER_TIME_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingServerTime"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/UPDATE_SERVER_TIME_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingServerTime"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "SIMULATION/UPDATE_SERVER_TIME_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingServerTime"]);
      const lens = R.lensPath(["data", "serverTime"]);

      let serverTime = action.data;
      const before = new Date();
      const after = new Date();
      const diff = after - before;
      const definitiveServerTime = (serverTime += diff);

      return R.compose(R.set(fetchLens, false), R.set(lens, definitiveServerTime))(state);
    }
    case "SIMULATION/SET_SERVER_TIME": {
      const lens = R.lensPath(["data", "serverTime"]);
      return R.set(lens, action.data, state);
    }
    case "SIMULATION/TOGGLE_SIMULATION_INSTRUCTIONS": {
      const lens = R.lensPath(["ui", "showSimulationInstructions"]);
      const newState = !state.ui.showSimulationInstructions;

      return R.set(lens, newState, state);
    }
    case "SIMULATION/FILTERS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingFilters"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/FILTERS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingFilters"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "SIMULATION/FILTERS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingFilters"]);
      const lens = R.lensPath(["data", "filters"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }
    case "SIMULATION/USER_COURSE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingFilters"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/USER_COURSE_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingFilters"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "SIMULATION/USER_COURSE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingFilters"]);
      const lens = R.lensPath(["data", "filters"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }
    case "SIMULATION/ESSAY_THEME_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingEssayTheme"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/ESSAY_THEME_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingEssayTheme"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "SIMULATION/ESSAY_THEME_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingEssayTheme"]);
      const lens = R.lensPath(["data", "essayTheme"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }
    case "SIMULATION/SAVE_ESSAY_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSaveEssays"]);
      const fetchLensSend = R.lensPath(["ui", "isFetchingSendEssays"]);
      return R.compose(R.set(fetchLens, true), R.set(fetchLensSend, true))(state);
    }
    case "SIMULATION/SAVE_ESSAY_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingSaveEssays"]);
      const fetchLensSend = R.lensPath(["ui", "isFetchingSendEssays"]);
      return R.compose(R.set(fetchLens, false), R.set(fetchLensSend, false))(state);
    }
    case "SIMULATION/SAVE_ESSAY_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSaveEssays"]);
      const fetchLensSend = R.lensPath(["ui", "isFetchingSendEssays"]);
      const essaysLens = R.lensPath(["data", "essayByUser"]);
      return R.compose(R.set(fetchLens, false), R.set(fetchLensSend, false), R.set(essaysLens, action.data))(state);
    }

    case "SIMULATION/ESSAYS_USER_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingEssay"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/ESSAYS_USER_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingEssay"]);
      const essaysLens = R.lensPath(["data", "essayByUser"]);
      return R.compose(R.set(fetchLens, false), R.set(essaysLens, action.data))(state);
    }
    case "SIMULATION/ESSAYS_USER_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingEssay"]);
      const essaysLens = R.lensPath(["data", "essayByUser"]);

      return R.compose(R.set(fetchLens, false), R.set(essaysLens, action.data))(state);
    }

    case "SIMULATION/CREATE_QUESTION": {
      const fetchLens = R.lensPath(["ui", "isCreatingSimulation"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/CREATE_QUESTION_ERROR": {
      const fetchLens = R.lensPath(["ui", "isCreatingSimulation"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "SIMULATION/CREATE_QUESTION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isCreatingSimulation"]);
      const lens = R.lensPath(["data", "simulation"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }
    case "SIMULATION/SIMULATIONS_LIST_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationsList"]);

      return R.set(fetchLens, true, state);
    }

    case "SIMULATION/SUBSCRIPTION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isSubscriptionSimulation"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SUBSCRIPTION_ERROR": {
      const fetchLens = R.lensPath(["ui", "isSubscriptionSimulation"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "SIMULATION/SUBSCRIPTION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isSubscriptionSimulation"]);
      const lens = R.lensPath(["data", "simulation"]);

      const simulation = {
        ...state.data.simulation,
        hasMatricula: true
      }

      return R.compose(R.set(fetchLens, false), R.set(lens, simulation))(state);
    }

    // redação feedback
    case "SIMULATION/ESSAY_FEEDBACK_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingEssayFeedback"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/ESSAY_FEEDBACK_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingEssayFeedback"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "SIMULATION/ESSAY_FEEDBACK_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingEssayFeedback"]);
      const lens = R.lensPath(["data", "essayFeedback"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }
    // redação feedback

    // init dia 2
    case "SIMULATION/INIT_DAY_2_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationStart"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/INIT_DAY_2_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationStart"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "SIMULATION/INIT_DAY_2_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationStart"]);
      const lens = R.lensPath(["data", "simulation"]);

      const newData = {
        ...state.data.simulation,
        isStartedSecondDay: true
      }

      return R.compose(R.set(fetchLens, false), R.set(lens, newData))(state);
    }
    // init dia 2

    case "SIMULATION/SET_RESPONSES": {
      const lens = R.lensPath(["data", "responses"]);

      return R.compose(R.set(lens, action.data))(state);
    }

    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}
