import * as R from "ramda";

const initialState = {
  ui: {
    isFetchingMyEssays: false,
    isFetchingFeedback: false,
    isFetchingSendEssays: false,
    isFetchingSaveEssays: false,
    isFetchingMyInformations: false,
    isFetchingThemes: false,
    isFetchingThemeDetails: false,
    isFetchingEssay: false,
    isFetchingRanking: false,
    isUploading: false,
    isFetchingCorrectionReview: false,
    isFetchingFeedbackReview: false,
    isFetchingCreateEssays: false,
    isFetchingSendEmailReviewEssay: false,
    isFetchingDashboardEssayData: false,
    isFetchingWeeklyTopTen: false,
    isFetchingFeedbackEssayData: false,
    isFetchingWeeklyThemeData: false,
    isFetchingEveryoneMedia: false,
    isFetchingToCorrectionEssays: false
  },
  data: {
    myEssays: [],
    textEssay: {},
    myInformations: {},
    allThemes: [],
    weekThemes: [],
    feedback: {},
    themeDetails: {},
    essayByUser: {},
    ranking: {
      enem: {},
      vestibular: {}
    },
    uploadEssay: {},
    cachedThemes: {},
    cachedRanking: {},
    dashboardEssayData: {},
    weeklyTopTen: {},
    feedbackEssayData: {},
    weeklyTheme: {},
    everyoneMedia: {},
    toCorrectionEssays: 0
  }
};

function essaysReducer(state = initialState, action) {
  switch (action.type) {
    case "ESSAYS/MY_ESSAYS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingMyEssays"]);
      return R.set(fetchLens, true, state);
    }
    case "ESSAYS/MY_ESSAYS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingMyEssays"]);
      return R.set(fetchLens, false, state);
    }
    case "ESSAYS/MY_ESSAYS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingMyEssays"]);
      const essaysLens = R.lensPath(["data", "myEssays"]);
      return R.compose(R.set(fetchLens, false), R.set(essaysLens, action.data))(state);
    }

    case "ESSAYS/GET_WEEKLY_THEME_DATA_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingWeeklyThemeData"]);
      return R.set(fetchLens, true, state);
    }
    case "ESSAYS/GET_WEEKLY_THEME_DATA_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingWeeklyThemeData"]);
      return R.set(fetchLens, false, state);
    }
    case "ESSAYS/GET_WEEKLY_THEME_DATA_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingWeeklyThemeData"]);
      const essaysLens = R.lensPath(["data", "weeklyTheme"]);
      return R.compose(R.set(fetchLens, false), R.set(essaysLens, action.data))(state);
    }

    case "ESSAYS/FEEDBACK_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingFeedback"]);
      return R.set(fetchLens, true, state);
    }
    case "ESSAYS/FEEDBACK_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingFeedback"]);
      return R.set(fetchLens, false, state);
    }
    case "ESSAYS/FEEDBACK_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingFeedback"]);
      const feedbackLens = R.lensPath(["data", "feedback"]);
      return R.compose(R.set(fetchLens, false), R.set(feedbackLens, action.data))(state);
    }

    case "ESSAYS/MY_INFORMATIONS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingMyInformations"]);
      return R.set(fetchLens, true, state);
    }
    case "ESSAYS/MY_INFORMATIONS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingMyInformations"]);
      return R.set(fetchLens, false, state);
    }
    case "ESSAYS/MY_INFORMATIONS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingMyInformations"]);
      const creditsLens = R.lensPath(["data", "myInformations"]);
      return R.compose(R.set(fetchLens, false), R.set(creditsLens, action.data))(state);
    }

    //Redações para correção
    case "ESSAYS/TO_CORRECTION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingToCorrectionEssays"]);
      return R.set(fetchLens, true, state);
    }
    case "ESSAYS/TO_CORRECTION_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingToCorrectionEssays"]);
      return R.set(fetchLens, false, state);
    }
    case "ESSAYS/TO_CORRECTION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingToCorrectionEssays"]);
      const creditsLens = R.lensPath(["data", "toCorrectionEssays"]);
      return R.compose(R.set(fetchLens, false), R.set(creditsLens, action.data))(state);
    }

    case "ESSAYS/ESSAYS_THEMES_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingThemes"]);
      return R.set(fetchLens, true, state);
    }
    case "ESSAYS/ESSAYS_THEMES_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingThemes"]);
      return R.set(fetchLens, false, state);
    }
    case "ESSAYS/ESSAYS_THEMES_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingThemes"]);
      const essaysLens = R.lensPath(["data", "allThemes"]);
      const themeWeekLens = R.lensPath(["data", "weekThemes"]);
      const cachedThemesLens = R.lensPath(["data", "cachedThemes", action.filter]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(essaysLens, action.data.allthemes),
        R.set(themeWeekLens, action.data.weektheme),
        R.set(cachedThemesLens, action.data)
      )(state);
    }

    case "ESSAYS/SEND_ESSAYS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSendEssays"]);
      return R.set(fetchLens, true, state);
    }
    case "ESSAYS/SEND_ESSAYS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingSendEssays"]);
      return R.set(fetchLens, false, state);
    }
    case "ESSAYS/SEND_ESSAYS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSendEssays"]);
      const essaysLens = R.lensPath(["data", "textEssay"]);

      return R.compose(R.set(fetchLens, false), R.set(essaysLens, action.data.textEssay))(state);
    }

    case "ESSAYS/SAVE_ESSAYS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSaveEssays"]);
      const fetchLensSend = R.lensPath(["ui", "isFetchingSendEssays"]);
      return R.compose(R.set(fetchLens, true), R.set(fetchLensSend, true))(state);
    }
    case "ESSAYS/SAVE_ESSAYS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingSaveEssays"]);
      const fetchLensSend = R.lensPath(["ui", "isFetchingSendEssays"]);
      return R.compose(R.set(fetchLens, false), R.set(fetchLensSend, false))(state);
    }
    case "ESSAYS/SAVE_ESSAYS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSaveEssays"]);
      const fetchLensSend = R.lensPath(["ui", "isFetchingSendEssays"]);
      const essaysLens = R.lensPath(["data", "essayByUser"]);

      if (action.isSetNewData) {
        return R.compose(R.set(fetchLens, false), R.set(fetchLensSend, false), R.set(essaysLens, action.data))(state);
      }

      return R.compose(R.set(fetchLens, false), R.set(fetchLensSend, false))(state);
    }

    case "ESSAYS/THEME_DETAILS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingThemeDetails"]);
      return R.set(fetchLens, true, state);
    }
    case "ESSAYS/THEME_DETAILS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingThemeDetails"]);
      return R.set(fetchLens, false, state);
    }
    case "ESSAYS/THEME_DETAILS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingThemeDetails"]);
      const themeLens = R.lensPath(["data", "themeDetails"]);
      return R.compose(R.set(fetchLens, false), R.set(themeLens, action.data))(state);
    }
    case "ESSAYS/SIMULATOR_ESSAYS_USER_REQUEST":
    case "ESSAYS/ESSAYS_USER_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingEssay"]);
      return R.set(fetchLens, true, state);
    }
    case "ESSAYS/SIMULATOR_ESSAYS_USER_ERROR":
    case "ESSAYS/ESSAYS_USER_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingEssay"]);
      const essaysLens = R.lensPath(["data", "essayByUser"]);
      return R.compose(R.set(fetchLens, false), R.set(essaysLens, action.data))(state);
    }
    case "ESSAYS/RESET_FILTER_ITEM": {
      state.data.essayByUser = initialState.data.essayByUser;
      return state;
    }
    case "ESSAYS/SIMULATOR_ESSAYS_USER_SUCCESS":
    case "ESSAYS/ESSAYS_USER_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingEssay"]);
      const essaysLens = R.lensPath(["data", "essayByUser"]);

      return R.compose(R.set(fetchLens, false), R.set(essaysLens, action.data))(state);
    }

    case "ESSAYS/RANKING_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingRanking"]);
      return R.set(fetchLens, true, state);
    }
    case "ESSAYS/RANKING_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingRanking"]);
      return R.set(fetchLens, false, state);
    }
    case "ESSAYS/RANKING_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingRanking"]);
      const rankingLens = R.lensPath(["data", "ranking"]);
      const cachedRankingLens = R.lensPath(["data", "cachedRanking"]);
      return R.compose(
        R.set(fetchLens, false),
        R.set(rankingLens, action.data),
        R.set(cachedRankingLens, action.data)
      )(state);
    }

    case "UPLOAD/UPLOAD_FILE_ESSAY": {
      const fetchLens = R.lensPath(["ui", "isUploading"]);
      return R.set(fetchLens, true, state);
    }
    case "UPLOAD/UPLOAD_FILE_ESSAY_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isUploading"]);
      return R.compose(R.set(fetchLens, false))(state);
    }
    case "UPLOAD/UPLOAD_FILE_ESSAY_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isUploading"]);
      const lens = R.lensPath(["data", "uploadEssay"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }

    case "ESSAYS/CORRECTION_REVIEW_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingCorrectionReview"]);
      return R.set(fetchLens, true, state);
    }
    case "ESSAYS/CORRECTION_REVIEW_REQUEST_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingCorrectionReview"]);
      return R.set(fetchLens, false, state);
    }
    case "ESSAYS/CORRECTION_REVIEW_REQUEST_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingCorrectionReview"]);
      const lens = R.lensPath(["data", "feedback", "revisionAt"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, true))(state);
    }

    case "ESSAYS/FEEDBACK_REVIEW_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingFeedbackReview"]);
      return R.set(fetchLens, true, state);
    }
    case "ESSAYS/FEEDBACK_REVIEW_REQUEST_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingFeedbackReview"]);
      return R.set(fetchLens, false, state);
    }
    case "ESSAYS/FEEDBACK_REVIEW_REQUEST_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingFeedbackReview"]);
      const lens = R.lensPath(["data", "feedback", "revisionAt"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, true))(state);
    }

    case "ESSAYS/SEND_EMAIL_REVIEW_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSendEmailReviewEssay"]);
      return R.set(fetchLens, true, state);
    }
    case "ESSAYS/SEND_EMAIL_REVIEW_REQUEST_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingSendEmailReviewEssay"]);
      return R.set(fetchLens, false, state);
    }
    case "ESSAYS/SEND_EMAIL_REVIEW_REQUEST_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSendEmailReviewEssay"]);
      const lens = R.lensPath(["data", "feedback", "sentEmail"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, true))(state);
    }

    case "ESSAYS/CREATE_ESSAYS_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingCreateEssays"]);
      return R.set(fetchLens, true, state);
    }
    case "ESSAYS/CREATE_ESSAYS_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingCreateEssays"]);
      return R.set(fetchLens, false, state);
    }
    case "ESSAYS/CREATE_ESSAYS_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingCreateEssays"]);
      const essaysLens = R.lensPath(["data", "essayByUser"]);
      return R.compose(R.set(fetchLens, false), R.set(essaysLens, action.data))(state);
    }
    case "ESSAYS/GET_DASHBOARD_ESSAY_DATA": {
      const fetchLens = R.lensPath(["ui", "isFetchingDashboardEssayData"]);
      return R.set(fetchLens, true, state);
    }
    case "ESSAYS/GET_DASHBOARD_ESSAY_DATA_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingDashboardEssayData"]);
      return R.set(fetchLens, false, state);
    }
    case "ESSAYS/GET_DASHBOARD_ESSAY_DATA_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingDashboardEssayData"]);
      const essaysLens = R.lensPath(["data", "dashboardEssayData"]);
      return R.compose(R.set(fetchLens, false), R.set(essaysLens, action.data))(state);
    }
    case "ESSAYS/GET_WEEKLY_TOP_TEN": {
      const fetchLens = R.lensPath(["ui", "isFetchingWeeklyTopTen"]);
      return R.set(fetchLens, true, state);
    }
    case "ESSAYS/GET_WEEKLY_TOP_TEN_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingWeeklyTopTen"]);
      return R.set(fetchLens, false, state);
    }
    case "ESSAYS/GET_WEEKLY_TOP_TEN_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingWeeklyTopTen"]);
      const essaysLens = R.lensPath(["data", "weeklyTopTen"]);
      return R.compose(R.set(fetchLens, false), R.set(essaysLens, action.data))(state);
    }
    case "ESSAYS/GET_FEEDBACK_ESSAY_DATA": {
      const fetchLens = R.lensPath(["ui", "isFetchingFeedbackEssayData"]);
      return R.set(fetchLens, true, state);
    }
    case "ESSAYS/GET_FEEDBACK_ESSAY_DATA_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingFeedbackEssayData"]);
      return R.set(fetchLens, false, state);
    }
    case "ESSAYS/GET_FEEDBACK_ESSAY_DATA_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingFeedbackEssayData"]);
      const essaysLens = R.lensPath(["data", "feedbackEssayData"]);
      return R.compose(R.set(fetchLens, false), R.set(essaysLens, action.data))(state);
    }
    case "ESSAYS/GET_EVERYONE_MEDIA_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingEveryoneMedia"]);
      return R.set(fetchLens, true, state);
    }
    case "ESSAYS/GET_EVERYONE_MEDIA_ERROR": {
      const fetchLens = R.lensPath(["ui", "isFetchingEveryoneMedia"]);
      return R.set(fetchLens, false, state);
    }
    case "ESSAYS/GET_EVERYONE_MEDIA_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingEveryoneMedia"]);
      const essaysLens = R.lensPath(["data", "everyoneMedia"]);
      return R.compose(R.set(fetchLens, false), R.set(essaysLens, action.data))(state);
    }

    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}

export default essaysReducer;
