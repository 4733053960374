/* @flow */
import * as R from "ramda";
import type { Action } from "../../../types/index";

const initialState = {
  ui: {
    isFetchingInstitutions: false,
    isFetchingInstitutionsCourses: false
  },
  data: {
    institutions: [], // Universidades cadastradas
    institutionsCourses: [] // Cursos disponibilizados pelas universidades
  }
};

function sharedDataReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case "SHARED/REQUEST_INSTITUTIONS": {
      const lens = R.lensPath(["ui", "isFetchingInstitutions"]);
      return R.set(lens, true, state);
    }
    case "SHARED/REQUEST_INSTITUTIONS_SUCCESS": {
      const lens = R.lensPath(["ui", "isFetchingInstitutions"]);
      const lensData = R.lensPath(["data", "institutions"]);
      return R.compose(
        R.set(lens, false),
        R.set(lensData, action.data)
      )(state);
    }
    case "SHARED/REQUEST_INSTITUTIONS_FAILURE": {
      const lens = R.lensPath(["ui", "isFetchingInstitutions"]);
      return R.set(lens, false, state);
    }

    case "SHARED/REQUEST_INSTITUTIONS_COURSES": {
      const lens = R.lensPath(["ui", "isFetchingInstitutionsCourses"]);
      return R.set(lens, true, state);
    }
    case "SHARED/REQUEST_INSTITUTIONS_COURSES_SUCCESS": {
      const lens = R.lensPath(["ui", "isFetchingInstitutionsCourses"]);
      const lensData = R.lensPath(["data", "institutionsCourses"]);
      return R.compose(
        R.set(lens, false),
        R.set(lensData, action.data)
      )(state);
    }
    case "SHARED/REQUEST_INSTITUTIONS_COURSES_FAILURE": {
      const lens = R.lensPath(["ui", "isFetchingInstitutionsCourses"]);
      return R.set(lens, false, state);
    }

    default:
      return state;
  }
}

export default sharedDataReducer;
